import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import i18n from "../../config/configI18n";

import { FormGroup, FormControl } from "@mui/material";
import { AccountTree } from "@mui/icons-material";
import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import TestBanner from "../../components/banners/TestBanner";

import { useSession } from "../../hooks/useSession";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import AgroBusinessPartition from "../../models/agrobusiness/AgroBusinessPartition";
import { Column } from "../../models/Generic";

import { SnackbarInterface } from "../../constants/interfaces";
import { FormMode } from "../../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";

const agroBusinessAccountColumns: Column[] = [
  { id: "name", label: i18n.t("partitions.column.name"), align: "left" },
];

const ExploitationPartitionsScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    agroBusinessPartitions,
    selectedABPartition,
    changeSelectedABPartition,
  } = useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
  }, [location.pathname]);

  const validateForm = (values: AgroBusinessPartition) => {
    const errors: any = {};

    if (!values.name) errors.name = i18n.t("formErrors.requiredField");

    return errors;
  };

  const handleSubmit = (values: AgroBusinessPartition) => {
    switch (formMode) {
      case FormMode.CREATE:
        agroBusinessPartitionCreateMutation.mutate(values);
        break;
      case FormMode.EDIT:
        agroBusinessPartitionEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new AgroBusinessPartition(),
    validate: validateForm,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("partitions.drawerTitleCreate")
      : i18n.t("partitions.drawerTitleEdit");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
  }, [location.pathname]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = () => {
    if (formMode === FormMode.CREATE) clearForm();
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const agroBusinessPartitionCreateMutation = useCrud<AgroBusinessPartition>({
    key: "postAgroBusinessPartition",
    values: formik.values,
    onSuccess: (data: AgroBusinessPartition) => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      if (!selectedABPartition) changeSelectedABPartition(data);
      closeDialogAndUnselectedRows();
    },
    onError: setSnackbarMsg,
  });

  const agroBusinessPartitionEditMutation = useCrud<AgroBusinessPartition>({
    key: "putAgroBusinessPartition",
    values: formik.values,
    onSuccess: (data: AgroBusinessPartition) => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      // Update selected ABP if selected
      if (selectedABPartition?.id === data?.id) changeSelectedABPartition(data);
      closeDialogAndUnselectedRows();
    },
    onError: setSnackbarMsg,
  });

  const agroBusinessPartitionDeleteMutation = useCrud({
    key: "deleteAgroBusinessPartitions",
    values: agroBusinessPartitions?.filter((agp: AgroBusinessPartition) =>
      selectedRowIds.includes(agp?.id || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer();
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    const selectedABP = agroBusinessPartitions.find((abp) => abp.id === id);
    if (selectedABP) {
      formik.setValues(selectedABP);
      formik.setStatus(FormMode.EDIT);
      setFormMode(FormMode.EDIT);
      openDrawer();
    }
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (errorMsg: string) => {
    setSnackbarMsg({ severity: "error", message: errorMsg });
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    agroBusinessPartitionDeleteMutation.mutate(selectedRowIds);
  };

  return (
    <ScreenContentLayout>
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("partitions.backAlertTitle")}
        contentText={i18n.t("partitions.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("partitions.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("partitions.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={agroBusinessPartitionDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("partitions.tableTitlev2")}
        headerText={i18n.t("partitions.headerText")}
        colums={agroBusinessAccountColumns}
        rows={agroBusinessPartitions}
        selectedRows={selectedRowIds}
        emptyTableIcon={
          <AccountTree sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={i18n.t("partitions.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("partitions.emptyTableSubtitlev2")}
        emptyTableBtnText={i18n.t("partitions.emptyTableBtnText")}
        addBtnVariant="contained"
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          agroBusinessPartitionCreateMutation.isLoading ||
          agroBusinessPartitionEditMutation.isLoading
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
      >
        <PartitionForm
          formik={formik}
          forceExpanded={expandCards}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationPartitionsScreen;

interface PartitionFormProps {
  formik: FormikProps<AgroBusinessPartition>;
  forceExpanded?: boolean;
  onError?: (errorMsg: string) => void;
}
const PartitionForm = (props: PartitionFormProps) => {
  const { formik, forceExpanded } = props;

  return (
    <BrioCard
      title={i18n.t("partitions.form.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="name"
            label={i18n.t("partitions.form.name")}
            required
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
