import MeasurementUnit from "../../catalogue/MeasurementUnit";
import FertilizerType from "../../catalogue/FertilizerType";
import RegisteredFertilizerProduct from "../../vademecumFertiliz/RegisteredFertilizerProduct";
import FertilizerProductMacronutrient from "../../fertiliz/FertilizerProductMacronutrient";
import FertilizerProductMicronutrient from "../../fertiliz/FertilizerProductMicronutrient";
import FertilizerProductHeavyMetal from "../../fertiliz/FertilizerProductHeavyMetal";

class FBFertilizerProduct {
  id?: number;
  registerId?: string;
  name?: string; //*
  owner?: string;
  type?: FertilizerType; //*
  measurementUnit?: MeasurementUnit; //*
  macronutrients?: FertilizerProductMacronutrient[];
  micronutrients?: FertilizerProductMicronutrient[];
  heavyMetals?: FertilizerProductHeavyMetal[];
  registeredFertilizerProduct?: RegisteredFertilizerProduct;
  enabledDate?: string;
  disabledDate?: string;

  constructor(
    id?: number,
    registerId?: string,
    name?: string,
    owner?: string,
    type?: FertilizerType,
    measurementUnit?: MeasurementUnit,
    macronutrients?: FertilizerProductMacronutrient[],
    micronutrients?: FertilizerProductMicronutrient[],
    heavyMetals?: FertilizerProductHeavyMetal[],
    registeredFertilizerProduct?: RegisteredFertilizerProduct,
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.registerId = registerId;
    this.name = name;
    this.owner = owner;
    this.type = type;
    this.measurementUnit = measurementUnit;
    this.macronutrients = macronutrients;
    this.micronutrients = micronutrients;
    this.heavyMetals = heavyMetals;
    this.registeredFertilizerProduct = registeredFertilizerProduct;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.registerId = obj?.registerId;
    this.name = obj?.name;
    this.owner = obj?.owner;
    this.type = new FertilizerType().mapToClass(obj?.type);
    this.measurementUnit = new MeasurementUnit().mapToClass(
      obj?.measurementUnit
    );
    this.macronutrients = obj?.macronutrients?.map(
      (macronutrient: FertilizerProductMacronutrient) =>
        new FertilizerProductMacronutrient().mapToClass(macronutrient)
    );
    this.micronutrients = obj?.micronutrients?.map(
      (micronutrient: FertilizerProductMicronutrient) =>
        new FertilizerProductMicronutrient().mapToClass(micronutrient)
    );
    this.heavyMetals = obj?.heavyMetals?.map(
      (heavyMetal: FertilizerProductHeavyMetal) =>
        new FertilizerProductHeavyMetal().mapToClass(heavyMetal)
    );
    this.registeredFertilizerProduct =
      new RegisteredFertilizerProduct().mapToClass(
        obj?.registeredFertilizerProduct
      );
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.type = this.type?.id
      ? this.type.mapToApiModel()
      : this.registeredFertilizerProduct?.fertilizerType;
    obj.measurementUnit = this.measurementUnit?.mapToApiModel();
    obj.macronutrients = this.macronutrients?.map((macronutrient) =>
      macronutrient.mapToApiModel()
    );
    obj.micronutrients = this.micronutrients?.map((micronutrient) =>
      micronutrient.mapToApiModel()
    );
    obj.heavyMetals = this.heavyMetals?.map((heavyMetal) =>
      heavyMetal.mapToApiModel()
    );
    obj.registeredFertilizerProduct =
      this.registeredFertilizerProduct?.mapToApiModel();
    return obj;
  }
}

export default FBFertilizerProduct;
