import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import i18n from "../../config/configI18n";
import moment from "moment";

import { FormGroup, FormControl, FormLabel } from "@mui/material";
import { Search, Opacity } from "@mui/icons-material";
import { DateTimePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";
import TestBanner from "../../components/banners/TestBanner";
import SectionTutorial from "../../components/elements/SectionTutorial";
import AlertEmptyList from "../../components/AlertEmptyList";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import DialogCropIrrigationAreaSector from "../../components/dialogs/DialogCropIrrigationAreaSector";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";
import SiexUploadStatusBanner from "../../components/banners/SiexUploadStatusBanner";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import { Column } from "../../models/Generic";
import CropIrrigation, {
  IRRIGATION_MEASUREMENT_UNIT_LIST,
} from "../../models/crop/CropIrrigation";
import Crop from "../../models/crop/Crop";
import IrrigationSystemType from "../../models/catalogue/IrrigationSystemType";
import IrrigationWaterSourceType from "../../models/catalogue/IrrigationWaterSourceType";
import IrrigationGoodPractice from "../../models/catalogue/IrrigationGoodPractice";
import CropIrrigationAreaSector from "../../models/crop/CropIrrigationAreaSector";
import Document from "../../models/files/Document";
import SiexRequestItem from "../../models/siex/SiexRequestItem";

import {
  addItemToListIfNotExistsByField,
  updateItemOfList,
  deleteItemsOfListByField,
} from "../../helpers/utils";

import {
  SnackbarInterface,
  SectionTutorialInterface,
} from "../../constants/interfaces";
import { FBEntitiesTypes, FormMode } from "../../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";
import { PROTECTED_ROUTES } from "../../routes/routeNames";
import { ENERGY_TYPES } from "../../providers/YupProvider";

const calculateTotalIrrigatedArea = (
  irrigatedSectors?: CropIrrigationAreaSector[]
) =>
  irrigatedSectors?.reduce(
    (acc: number, irrigatedSector: CropIrrigationAreaSector) =>
      acc + (irrigatedSector?.irrigatedArea || 0),
    0
  ) || 0;

const ExploitationIrrigationScreen = () => {
  const tutorialSteps: SectionTutorialInterface[] = [
    {
      label: i18n.t("irrigation.tutorial.createCrops.label"),
      description: i18n.t("irrigation.tutorial.createCrops.description"),
      nextBtnText: i18n.t("irrigation.tutorial.createCrops.nextBtnText"),
    },
    {
      label: i18n.t("irrigation.tutorial.createSectors.label"),
      description: i18n.t("irrigation.tutorial.createSectors.description"),
      nextBtnText: i18n.t("irrigation.tutorial.createSectors.nextBtnText"),
    },
  ];

  const CropIrrigationValidatorSchema = Yup.object().shape({
    crop: Yup.object().required(),
    startTime: Yup.string().required(),
    endTime: Yup.string().required(),
    irrigationDose: Yup.number().required(),
    irrigationDoseUnit: Yup.object().required(),
    irrigationSystem: Yup.object().required(),
  });

  const irrigationColumns: Column[] = [
    {
      id: "dateFormatted",
      label: i18n.t("irrigation.column.dateFormatted"),
      numeric: false,
    },
    {
      id: "timeRange",
      label: i18n.t("irrigation.column.timeRange"),
      numeric: false,
    },
    {
      id: "irrigationDose",
      label: i18n.t("irrigation.column.irrigationDose"),
      numeric: true,
      valueLabel: "unit",
      unitFieldName: "irrigationDoseUnit.name",
    },
    {
      id: "fertigationApplied",
      label: i18n.t("irrigation.column.fertigationApplied"),
      numeric: false,
      valueLabel: "boolean",
    },
    {
      id: "fertilizerTanks",
      label: i18n.t("irrigation.column.fertilizerTanks"),
      numeric: true,
      valueLabel: "array",
      arrayFieldName: "fertilizerTank.name",
    },
    {
      id: "crop.productNameWithVarieties",
      label: i18n.t("irrigation.column.crop"),
      numeric: false,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [isOpenAttachSectorsAlertDialog, setIsOpenAttachSectorsAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [isLoadingTutorialData, setIsLoadingTutorialData] =
    useState<boolean>(true);
  const [isTutorialFinished, setIsTutorialFinished] = useState<boolean>(true);
  const [tutorialStep, setTutorialStep] = useState<number>(0);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const { data: cropsCount, isFetching: isFetchingCrops } = useFetch<number>({
    queryKey: ["cropsCount", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError: setSnackbarMsg,
  });

  const { data: sectorsCount, isFetching: isFetchingSectors } =
    useFetch<number>({
      queryKey: [
        "sectorsCount",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      enabled: !!selectedABAccount?.id,
    });

  const { data: cropIrrigations, isFetching } = useFetch<CropIrrigation[]>({
    queryKey: [
      "cropIrrigations",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
  });

  const selected = useMemo(
    () =>
      formMode === FormMode.EDIT && selectedRowIds.length === 1
        ? cropIrrigations?.find(
            (ci: CropIrrigation) => ci.id === selectedRowIds[0]
          )
        : undefined,
    [selectedRowIds, formMode, cropIrrigations]
  );

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  useEffect(() => {
    setIsLoadingTutorialData(isFetchingCrops || isFetchingSectors);
    return () => {
      setIsLoadingTutorialData(false);
    };
  }, [isFetchingCrops, isFetchingSectors]);

  useEffect(() => {
    if (!isLoadingTutorialData && !isFetching) {
      let step = 0;
      tutorialSteps[0].completed = (cropsCount || 0) > 0;
      tutorialSteps[1].completed = (sectorsCount || 0) > 0;

      if (!tutorialSteps[0].completed) step = 0;
      else if (!tutorialSteps[1].completed) step = 1;
      setIsTutorialFinished(
        (tutorialSteps[0].completed && tutorialSteps[1].completed) || false
      );
      setTutorialStep(step);
    }
  }, [isLoadingTutorialData, isFetching]);

  const handleSubmit = (values: CropIrrigation) => {
    switch (formMode) {
      case FormMode.CREATE:
        if (
          values.crop?.cropSectors &&
          values.crop.cropSectors.length > 0 &&
          (!values.irrigatedSectors || values.irrigatedSectors.length === 0)
        )
          openAttachSectorsAlertDialog();
        else cropIrrigationCreateMutation.mutate(values);
        break;
      case FormMode.EDIT:
        cropIrrigationEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new CropIrrigation(),
    validationSchema: CropIrrigationValidatorSchema,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("irrigation.drawerTitleCreate")
      : i18n.t("irrigation.drawerTitleEdit");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Open drawer if is redirected from error siex register
  useEffect(() => {
    if (
      siexRegister &&
      siexRegister.entityClassName === FBEntitiesTypes.CROP_IRRIGATION &&
      siexRegister.entityClassId
    ) {
      setSelectedRowIds([siexRegister.entityClassId]);
      openDrawer(FormMode.EDIT);
    }
  }, [siexRegister]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`, { state: { siexRegister } });
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);
  const openAttachSectorsAlertDialog = () =>
    setIsOpenAttachSectorsAlertDialog(true);
  const cancelAttachSectorsAlertDialog = () =>
    setIsOpenAttachSectorsAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const cropIrrigationCreateMutation = useCrud<CropIrrigation>({
    key: "postCropIrrigation",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const cropIrrigationEditMutation = useCrud<CropIrrigation>({
    key: "putCropIrrigation",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const cropIrrigationDeleteMutation = useCrud({
    key: "deleteCropIrrigations",
    values: cropIrrigations?.filter((ci: CropIrrigation) =>
      selectedRowIds.includes(ci?.id || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    cropIrrigationDeleteMutation.mutate(selectedRowIds);
  };

  const handleConfirmAttachSectorsAlertDialog = () => {
    cancelAttachSectorsAlertDialog();
    cropIrrigationCreateMutation.mutate({});
  };

  const handleCancelAttachSectorsAlertDialog = () => {
    cancelAttachSectorsAlertDialog();
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  const handleClickTutorialGo = () => {
    switch (tutorialStep) {
      case 0:
        return navigate(`/${PROTECTED_ROUTES.EXPLOITATION_CROPS}`);
      case 1:
        return navigate(`/${PROTECTED_ROUTES.EXPLOITATION_SECTORS}`);
      default:
        break;
    }
  };

  const handleClickTutorialContinue = () => {
    if (tutorialStep < tutorialSteps.length - 1)
      setTutorialStep(tutorialStep + 1);
    else setIsTutorialFinished(true);
  };

  const handleClickTutorialBack = () => {
    if (tutorialStep > 0) setTutorialStep(tutorialStep - 1);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isLoadingTutorialData || isFetching} />
      <TestBanner />
      {!isTutorialFinished && (
        <SectionTutorial
          steps={tutorialSteps}
          activeStep={tutorialStep}
          onGo={handleClickTutorialGo}
          onNext={handleClickTutorialContinue}
          onBack={handleClickTutorialBack}
        />
      )}
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("irrigation.backAlertTitle")}
        contentText={i18n.t("irrigation.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("irrigation.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("irrigation.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={cropIrrigationDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <FormAlertDialog
        id="attachSectorsAlertDialog"
        title={i18n.t("irrigation.attachSectorsAlertDialogTitle")}
        contentText={i18n.t("irrigation.attachSectorsAlertDialogContentText")}
        open={isOpenAttachSectorsAlertDialog}
        formAction={formMode}
        confirmBtnText={i18n.t("words.continue")}
        cancelBtnText={i18n.t("words.back")}
        onCancel={handleCancelAttachSectorsAlertDialog}
        onConfirm={handleConfirmAttachSectorsAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("irrigation.tableTitle")}
        headerText={i18n.t("irrigation.headerText")}
        colums={irrigationColumns}
        rows={cropIrrigations}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Opacity sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("irrigation.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("irrigation.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("irrigation.emptyTableBtnText")}
        addBtnVariant="contained"
        siexRows
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          cropIrrigationCreateMutation.isLoading ||
          cropIrrigationEditMutation.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <IrrigationForm
          formik={formik}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          selectedEditRow={selected}
          isSiexActive={isSiexActive}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationIrrigationScreen;

interface CropIrrigationFormProps {
  formik: FormikProps<CropIrrigation>;
  isSiexActive: boolean;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: CropIrrigation;
  onCloseFilesForm?: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const IrrigationForm = (props: CropIrrigationFormProps) => {
  const {
    formik,
    isSiexActive,
    selectedEditRow,
    isOpenFilesForm = false,
    onCloseFilesForm,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const queryClient = useQueryClient();
  const location = useLocation();

  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const { isFetching: isUpdating } = useFetch<CropIrrigation>({
    queryKey: ["cropIrrigation", selectedEditRow?.id],
    selected: selectedEditRow,
    onSuccess: (data: CropIrrigation) => {
      formik.setValues(data);
      // Update in array without refetch
      queryClient.setQueryData<CropIrrigation[]>(
        ["cropIrrigations", selectedABPartition?.id, selectedABAccount?.id],
        (oldData) => updateItemOfList(oldData, data, "id")
      );
    },
    enabled: !!selectedEditRow?.id,
    onError,
  });

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      {isSiexActive && selectedEditRow && (
        <SiexUploadStatusBanner
          entityClassId={selectedEditRow.id || siexRegister.entityClassId}
          status={selectedEditRow.siexStatus}
          timestamp={selectedEditRow.siexSentOn}
          errorSiexRegister={siexRegister}
        />
      )}
      <CropForm {...props} />
      {/*       <FertilizerTanksForm {...props} /> */}
      <IrrigatedSectorsForm {...props} />
      <WaterSourcesForm {...props} />
      <IrrigationGoodPracticeForm {...props} />
      <EnergyTypeForm {...props} />
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("irrigation.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
    </>
  );
};

const CropForm = (props: CropIrrigationFormProps) => {
  const { formik, forceExpanded, selectedEditRow, onError } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const { data: crops, isFetching: isFetchingCrops } = useFetch<Crop[]>({
    queryKey: ["crops", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError,
  });

  const { data: irrigationTypes, isFetching: isFetchingIrrigationTypes } =
    useFetch<IrrigationSystemType[]>({
      queryKey: ["irrigationSystemTypes", selectedABAccount?.context?.id],
      enabled: !!formik.values.crop?.id,
      onError,
    });

  const handleChangeCrop = (crop: Crop) => {
    formik.setFieldValue("crop", crop);
    if (crop?.irrigationType)
      formik.setFieldValue("irrigationSystem", crop.irrigationType);
    // Reset irrigated sector when crop changes
    if (!selectedEditRow) formik.setFieldValue("irrigatedSectors", []);
  };

  const handleClickNewOption = () =>
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_CROPS}`);

  const handleChangeStartTime = (date: moment.Moment | null) => {
    const dateStr = date?.format("YYYY-MM-DD HH:mm:ss");
    formik.setFieldValue("startTime", dateStr);
    formik.setFieldValue("endTime", dateStr);
  };

  return (
    <BrioCard
      title={i18n.t("irrigation.cropForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      {!isFetchingCrops && crops?.length === 0 && (
        <AlertEmptyList onClick={handleClickNewOption}>
          {i18n.t("irrigation.cropForm.emptyList")}
        </AlertEmptyList>
      )}
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="crop"
            label={i18n.t("irrigation.cropForm.crop")}
            placeholder={i18n.t("irrigation.cropForm.cropPlaceholder")}
            helperText={i18n.t("irrigation.cropForm.cropHelperText")}
            options={crops || []}
            optionLabelFieldName="productNameWithVarieties"
            required
            startIcon={Search}
            loading={isFetchingCrops}
            addNewOption
            onChange={handleChangeCrop}
            onClickNewOption={handleClickNewOption}
            disabled={formik.status === FormMode.EDIT}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <DateTimePicker
            className="form-input"
            format="DD/MM/YYYY HH:mm"
            label={i18n.t("irrigation.datesForm.startTime")}
            ampm={false}
            value={
              formik.values?.startTime
                ? moment(formik.values.startTime, "YYYY-MM-DD HH:mm:ss")
                : null
            }
            onChange={handleChangeStartTime}
          />
          {formik.touched.startTime && formik.errors.startTime && (
            <FormLabel className="form-label">
              {i18n.t("formErrors.requiredField")}
            </FormLabel>
          )}
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <DateTimePicker
            className="form-input"
            format="DD/MM/YYYY HH:mm"
            label={i18n.t("irrigation.datesForm.endTime")}
            ampm={false}
            value={
              formik.values?.endTime
                ? moment(formik.values.endTime, "YYYY-MM-DD HH:mm:ss")
                : null
            }
            onChange={(date) =>
              formik.setFieldValue(
                "endTime",
                date?.format("YYYY-MM-DD HH:mm:ss")
              )
            }
          />
          {formik.touched.endTime && formik.errors.endTime && (
            <FormLabel className="form-label">
              {i18n.t("formErrors.requiredField")}
            </FormLabel>
          )}
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="irrigationSystem"
            label={i18n.t("irrigation.cropForm.irrigationSystem")}
            options={irrigationTypes || []}
            optionLabelFieldName="name"
            isLoading={isFetchingIrrigationTypes}
            required
            disabled={!formik.values.crop}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            className="form-input-row-70"
            formik={formik}
            name="irrigationDose"
            type="number"
            label={i18n.t("irrigation.cropForm.irrigationDose")}
            required
          />

          <FormikAutocomplete
            className="form-input-row-30"
            formik={formik}
            name="irrigationDoseUnit"
            label={i18n.t("irrigation.cropForm.irrigationDoseUnit")}
            options={IRRIGATION_MEASUREMENT_UNIT_LIST}
            optionLabelFieldName="name"
            required
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="irrigationArea"
            type="number"
            label={i18n.t("irrigation.cropForm.irrigationArea")}
            valueUnit="ha"
            helperText={i18n.t("irrigation.cropForm.irrigationAreaHelperText")}
          />
        </FormControl>
        {/*  Removed on v2.3.0
       <FormControl className="form-control">
          <FormikListItemSwitch
            formik={formik}
            name="fertigationApplied"
            primaryText={i18n.t("irrigation.cropForm.fertigationApplied")}
            secondaryText={i18n.t(
              "irrigation.cropForm.fertigationAppliedSubtitle"
            )}
          />
        </FormControl> */}
      </FormGroup>
    </BrioCard>
  );
};

const IrrigatedSectorsForm = (props: CropIrrigationFormProps) => {
  const cropTreatedSectorColumns: Column[] = [
    {
      id: "sector.name",
      label: i18n.t("irrigation.sectorsForm.column.name"),
      numeric: false,
    },
    {
      id: "irrigatedArea",
      label: i18n.t("irrigation.sectorsForm.column.irrigatedArea"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "ha",
    },
  ];

  const { formik, onError } = props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [
    selectedCropIrrigationAreaSector,
    setSelectedCropIrrigationAreaSector,
  ] = useState<CropIrrigationAreaSector | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedCropIrrigationAreaSector(null);
  };

  const handleClickAdd = () => {
    if (!formik.values.crop && onError) {
      onError({
        severity: "info",
        message: i18n.t("formErrors.selectOneOption"),
      } as SnackbarInterface);
    } else {
      setFormMode(FormMode.CREATE);
      openDialog();
    }
  };

  // Only dialog to create or edit treated sector
  const handleConfirmDialog = (selected: CropIrrigationAreaSector) => {
    const updatedIrrigatedSectors =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.irrigatedSectors,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.irrigatedSectors, selected, "idx");
    formik.setFieldValue("irrigatedSectors", updatedIrrigatedSectors);
    formik.setFieldValue(
      "irrigationArea",
      calculateTotalIrrigatedArea(updatedIrrigatedSectors)
    );
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.irrigatedSectors?.find(
      (is: CropIrrigationAreaSector) => is.idx === idx
    );
    if (selected) {
      setSelectedCropIrrigationAreaSector(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    const updatedIrrigatedSectors = deleteItemsOfListByField(
      formik.values.irrigatedSectors,
      idxs,
      "idx"
    );
    formik.setFieldValue("irrigatedSectors", updatedIrrigatedSectors);
    formik.setFieldValue(
      "irrigationArea",
      calculateTotalIrrigatedArea(updatedIrrigatedSectors)
    );
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        idx="idx"
        title={i18n.t("irrigation.sectorsForm.tableTitle")}
        headerText={i18n.t("irrigation.sectorsForm.headerText")}
        btnText={i18n.t("irrigation.sectorsForm.tableBtnText")}
        colums={cropTreatedSectorColumns}
        rows={formik.values.irrigatedSectors}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        optional
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClikEdit}
        onClickDelete={handleClickDelete}
      />
      <DialogCropIrrigationAreaSector
        open={isOpenDialog}
        crop={formik.values.crop}
        selected={selectedCropIrrigationAreaSector}
        irrigatedSectors={formik.values.irrigatedSectors}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};

/**
 * Removed on v2.3.0
 */
/* const FertilizerTanksForm = (props: CropIrrigationFormProps) => {
  const cropIrrigationFertilizerTankColumns: Column[] = [
    {
      id: "fertilizerTank.name",
      label: i18n.t("irrigation.fertilizerTankForm.column.name"),
      numeric: false,
    },
    {
      id: "quantity",
      label: i18n.t("irrigation.fertilizerTankForm.column.quantity"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "l",
    },
  ];

  const { formik, onError } = props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedCropFertilizerTank, setSelectedCropFertilizerTank] =
    useState<CropIrrigationFertilizerTank | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedCropFertilizerTank(null);
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };

  // Only dialog to create or edit crop irrigation fertilizer tank
  const handleConfirmDialog = (selected: CropIrrigationFertilizerTank) => {
    const updatedFertilizerTanks =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.fertilizerTanks,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.fertilizerTanks, selected, "idx");
    formik.setFieldValue("fertilizerTanks", updatedFertilizerTanks);
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.fertilizerTanks?.find(
      (cft: CropIrrigationFertilizerTank) => cft.idx === idx
    );
    if (selected) {
      setSelectedCropFertilizerTank(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    formik.setFieldValue(
      "fertilizerTanks",
      deleteItemsOfListByField(formik.values.fertilizerTanks, idxs, "idx")
    );
    closeDialog();
  };

  return (
    <Collapse in={formik.values.fertigationApplied}>
      <div className="grow-container">
        <TableBrioCard
          idx="idx"
          title={i18n.t("irrigation.fertilizerTankForm.tableTitle")}
          headerText={i18n.t("irrigation.fertilizerTankForm.headerText")}
          btnText={i18n.t("irrigation.fertilizerTankForm.tableBtnText")}
          colums={cropIrrigationFertilizerTankColumns}
          rows={formik.values.fertilizerTanks || []}
          selectedRows={selectedRowIds}
          emptyTableCard={false}
          optional
          onChangeSelectedRows={setSelectedRowIds}
          onClickAdd={handleClickAdd}
          onClickEdit={handleClikEdit}
          onClickDelete={handleClickDelete}
        />
        <DialogCropIrrigationFertilizerTank
          open={isOpenDialog}
          selected={selectedCropFertilizerTank}
          cropFertilizerTanks={formik.values.fertilizerTanks}
          onConfirm={handleConfirmDialog}
          onClose={closeDialog}
          onError={onError}
        />
      </div>
    </Collapse>
  );
}; */

const WaterSourcesForm = (props: CropIrrigationFormProps) => {
  const { formik, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: irrigationWaterSourceTypes, isFetching } = useFetch<
    IrrigationWaterSourceType[]
  >({
    queryKey: ["irrigationWaterSourceTypes", selectedABAccount?.context?.id],
    enabled: !!formik.values.crop?.id,
    onError,
  });

  return (
    <BrioCard title={i18n.t("irrigation.waterSourcesForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            multiple
            formik={formik}
            name="waterSourceTypes"
            label={i18n.t("irrigation.waterSourcesForm.waterSourceTypes")}
            optionLabelFieldName="name"
            options={irrigationWaterSourceTypes || []}
            isLoading={isFetching}
            disabled={!formik.values.crop}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const IrrigationGoodPracticeForm = (props: CropIrrigationFormProps) => {
  const { formik, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: irrigationGoodPractices, isFetching: isFetchingIGPS } =
    useFetch<IrrigationGoodPractice[]>({
      queryKey: ["irrigationGoodPractices", selectedABAccount?.context?.id],
      enabled: !!formik.values.crop?.id,
      onError,
    });

  return (
    <BrioCard title={i18n.t("irrigation.goodPracticesForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            multiple
            formik={formik}
            name="goodPractices"
            label={i18n.t("irrigation.goodPracticesForm.goodPractices")}
            optionLabelFieldName="description"
            options={irrigationGoodPractices || []}
            isLoading={isFetchingIGPS}
            disabled={!formik.values.crop}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const EnergyTypeForm = (props: CropIrrigationFormProps) => {
  const { formik } = props;

  return (
    <BrioCard title={i18n.t("irrigation.energyTypeForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="eType"
            label={i18n.t("irrigation.energyTypeForm.eType")}
            optionLabelFieldName="name"
            options={ENERGY_TYPES}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
