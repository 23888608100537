import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "../../config/configI18n";

import { Grade, Visibility } from "@mui/icons-material";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import TestBanner from "../../components/banners/TestBanner";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";

import { Column } from "../../models/Generic";
import PersonMessage from "../../models/messages/PersonMessage";

import { SnackbarInterface } from "../../constants/interfaces";
import {
  EMPTY_TABLE_ICON_SIZE,
  REFRESH_OFFERS_INTERVAL,
} from "../../constants/constants";
import { TextField } from "@mui/material";
import { updateItemOfList } from "../../helpers/utils";

const ExploitationOffersScreen = () => {
  const offersColumns: Column[] = [
    {
      id: "messageText",
      label: i18n.t("offers.column.messageText"),
      numeric: false,
      valueLabel: "isNew",
      isNewFieldName: "isUnread",
    },
    {
      id: "dateFormatted",
      label: i18n.t("offers.column.dateFormatted"),
      numeric: false,
    },
    {
      id: "expirationDateFormatted",
      label: i18n.t("offers.column.expirationDateFormatted"),
      numeric: false,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount } = useSession();
  const queryClient = useQueryClient();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  const { data: offers, isFetching } = useFetch<PersonMessage[]>({
    queryKey: ["offers", selectedABAccount?.id],
    refetchInterval: REFRESH_OFFERS_INTERVAL,
    enabled: !!selectedABAccount,
    onError: setSnackbarMsg,
  });

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
  }, [location.pathname]);

  const openDrawer = () => {
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    setSelectedRowIds([]);
    cancelConfirmDeleteAlertDialog();
  };

  const offersDeleteMutation = useCrud({
    key: "deleteOffers",
    values: offers?.filter((d: PersonMessage) =>
      selectedRowIds.includes(d?.id || 0)
    ),
    onSuccess: () => {
      // Update list
      queryClient.refetchQueries({
        queryKey: ["offers"],
        type: "active",
      });
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    openDrawer();
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      openConfirmDeleteAlertDialog();
    }
  };

  const handleConfirmDeleteAlertDialog = () => {
    offersDeleteMutation.mutate(selectedRowIds);
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("offers.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("offers.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        isLoading={offersDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("offers.tableTitle")}
        headerText={i18n.t("offers.headerText")}
        colums={offersColumns}
        rows={offers || []}
        selectedRows={selectedRowIds}
        defaultOrderFieldName="timestamp"
        emptyTableIcon={<Grade sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("offers.emptyTableTitle")}
        isLoading={isFetching}
        addBtnVariant="contained"
        hideBtn
        editIcon={<Visibility />}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={() => openDrawer()}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={i18n.t("offers.drawerTitle")}
        titleBtn={i18n.t("offers.drawerTitleBtn")}
        isOpen={isOpenDrawer}
        onClose={() => closeDrawer()}
        onConfirm={() => closeDrawer()}
      >
        <OfferForm
          selectedEditRow={
            selectedRowIds.length === 1
              ? offers?.find((a: PersonMessage) => a.id === selectedRowIds[0])
              : undefined
          }
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationOffersScreen;

interface OfferFormProps {
  selectedEditRow?: PersonMessage;
  onError?: (snackBarError: SnackbarInterface) => void;
}

const OfferForm = (props: OfferFormProps) => {
  const { selectedEditRow, onError } = props;

  const { selectedABAccount } = useSession();
  const queryClient = useQueryClient();

  const [personMessage, setPersonMessage] = useState<PersonMessage | undefined>(
    undefined
  );

  const setOfferAsReadMutation = useCrud({
    key: "setOfferAsRead",
    values: personMessage,
    onSuccess: (data: PersonMessage) => {
      queryClient.setQueryData<PersonMessage[]>(
        ["offers", selectedABAccount?.id],
        (oldData) => updateItemOfList(oldData, data, "id")
      );
      queryClient.refetchQueries({
        queryKey: ["unreadOffers"],
        type: "active",
      });
    },
  });

  const onGetOfferByIdSuccess = (data: PersonMessage) => {
    setPersonMessage(data);
    if (!data.messageRead) setOfferAsReadMutation.mutate(data);
  };

  const { isFetching: isUpdating } = useFetch<PersonMessage>({
    queryKey: ["offerById", selectedEditRow?.id],
    selected: selectedEditRow,
    enabled: !!selectedEditRow?.id,
    onSuccess: onGetOfferByIdSuccess,
    onError,
  });

  return (
    <div className="offer">
      <div className="container">
        <LoadingWithDelay isVisible={isUpdating} />
        <div className="messageContent">
          <p>{personMessage?.messageText || ""}</p>
          <span className="messageDate">
            {personMessage?.dateFormatted || ""}
          </span>
        </div>
        {personMessage?.expirationDateFormatted && (
          <TextField
            className="form-input"
            label={i18n.t("offers.offerForm.expirationDatetime")}
            value={personMessage.expirationDateFormatted}
            disabled
          />
        )}
      </div>
    </div>
  );
};
