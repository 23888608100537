import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import i18n from "../config/configI18n";

import {
  Autocomplete,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { ArrowBack, QuestionMark, Search } from "@mui/icons-material";

import ScreenContentLayout from "../components/ScreenContentLayout";
import TestBanner from "../components/banners/TestBanner";
import AlertSnackbar from "../components/elements/AlertSnackbar";
import LoadingWithDelay from "../components/elements/LoadingWithDelay";
import EmptyTable from "../components/EmptyTable";

import RegisteredFertilizerProduct from "../models/vademecumFertiliz/RegisteredFertilizerProduct";
import { Column } from "../models/Generic";

import useFetch from "../hooks/useFetch";
import { useSession } from "../hooks/useSession";

import { getFieldValueByColumnNotation } from "../helpers/utils";
import {
  SearchVadeFertilizProductInterface,
  SnackbarInterface,
} from "../constants/interfaces";

import { SEARCH_LIKE_TIMEOUT_DELAY_MS } from "../constants/constants";

const VademecumFertilizScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [selectedProduct, setSelectedProduct] =
    useState<RegisteredFertilizerProduct | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsDrawerOpen(location.pathname.includes("/edit"));
  }, [location.pathname]);

  const openDrawer = () => {
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setTimeout(() => {
      setSelectedProduct(null);
      navigate(-1);
    }, 500);
  };

  const handleClickProduct = (product: RegisteredFertilizerProduct | null) => {
    setSelectedProduct(product);
    openDrawer();
  };

  return (
    <ScreenContentLayout>
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <VademecumTable onClick={handleClickProduct} onError={setSnackbarMsg} />
      <VadeProductView
        isOpen={isDrawerOpen}
        selected={selectedProduct}
        onClose={closeDrawer}
        onError={setSnackbarMsg}
      />
    </ScreenContentLayout>
  );
};

export default VademecumFertilizScreen;

interface VademecumTableProps {
  onClick?: (product: RegisteredFertilizerProduct | null) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const VademecumTable = (props: VademecumTableProps) => {
  const { onClick, onError } = props;

  const columns: Column[] = [
    {
      id: "name",
      label: i18n.t("vademecum.fertiliz.columns.name"),
      numeric: false,
    },
    {
      id: "fertilizerType.name",
      label: i18n.t("vademecum.fertiliz.columns.type"),
      numeric: false,
    },
    {
      id: "manufacturer",
      label: i18n.t("vademecum.fertiliz.columns.manufacturer"),
      numeric: false,
    },
  ];

  const { selectedABAccount } = useSession();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [productSearchWord, setProductSearchWord] = useState<string>("");
  const [productType, setProductType] = useState<string>("");
  const [manufacturer, setManufacturer] = useState<string>("");

  const { data: products, isFetching: isFetchingProducts } = useFetch<
    RegisteredFertilizerProduct[]
  >({
    queryKey: [
      "vadeFertilizProducts",
      selectedABAccount?.context?.id,
      productSearchWord,
      productType,
      manufacturer,
    ],
    selected: {
      productSearchWord,
      productType,
      manufacturer,
    } as SearchVadeFertilizProductInterface,
    enabled:
      !!selectedABAccount?.context?.id &&
      (productSearchWord.length > 0 ||
        productType.length > 0 ||
        manufacturer.length > 0),
    onError,
  });

  const { data: productTypes, isFetching: isFetchingTypes } = useFetch<
    string[]
  >({
    queryKey: ["vadeFertilizerProductTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount?.context?.id,
    onError,
  });

  const { data: manufacturers, isFetching: isFetchingManufacturers } = useFetch<
    string[]
  >({
    queryKey: ["vadeFertilizerManufacturers", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount?.context?.id,
    onError,
  });

  const notFilters: boolean = useMemo(
    () =>
      productSearchWord.length === 0 &&
      productType.length === 0 &&
      manufacturer.length === 0,
    [productSearchWord, productType, manufacturer]
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows: RegisteredFertilizerProduct[] = useMemo(
    () =>
      products
        ? products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : [],
    [products, page, rowsPerPage]
  );

  const handleClickRow = (product: RegisteredFertilizerProduct) => {
    onClick && onClick(product);
  };

  return (
    <div className="vademecum-fertiliz-table">
      <LoadingWithDelay isVisible={isFetchingProducts} />
      <div className="header">
        <h1>{i18n.t("vademecum.fertiliz.title")}</h1>
      </div>
      <div className="content">
        <div className="content-table-filters">
          <VadeProductFilter onChangeSearchWord={setProductSearchWord} />
          <VadeProductTypeFilter
            types={productTypes || []}
            isLoading={isFetchingTypes}
            onChange={setProductType}
          />
          <VadeManufacturerFilter
            manufacturers={manufacturers || []}
            isLoading={isFetchingManufacturers}
            onChange={setManufacturer}
          />
        </div>
        {visibleRows.length === 0 ? (
          <EmptyTable
            icon={<QuestionMark fontSize="large" />}
            title={
              notFilters
                ? i18n.t("vademecum.fertiliz.notFiltersTitle")
                : i18n.t("vademecum.fertiliz.notResultsTitle")
            }
          />
        ) : (
          <TableContainer className="table-container">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="table-cell-head"
                      align={
                        column.align || (column.numeric ? "right" : "left")
                      }
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {visibleRows.map((row) => (
                  <TableRow
                    key={row.id}
                    className="table-row"
                    onClick={() => handleClickRow(row)}
                  >
                    {columns.map((column) => (
                      <TableRowItem key={column.id} row={row} column={column} />
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              className="table-pagination"
              component="div"
              count={products?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={i18n.t("words.rowsByPage")}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} / ${count}`
              }
              rowsPerPageOptions={[5, 10, 25]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </div>
    </div>
  );
};

interface TableRowItemProps {
  row: RegisteredFertilizerProduct;
  column: Column;
}
const TableRowItem = (props: TableRowItemProps) => {
  const { row, column } = props;

  const value = getFieldValueByColumnNotation(
    column.id,
    row,
    column.valueLabel,
    column.arrayFieldName,
    column.unitFieldName,
    column.unitValue,
    column.id2
  );
  const align = column.align || (column.numeric ? "right" : "left");

  return (
    <TableCell className="table-cell" align={align}>
      {value}
    </TableCell>
  );
};

interface ProductsFilterProps {
  onChangeSearchWord?: (searchWord: string) => void;
}
let productsSearchWordTimeout: NodeJS.Timeout | null;

const VadeProductFilter = (props: ProductsFilterProps) => {
  const { onChangeSearchWord } = props;

  const handleChangeProductSearchWord = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (productsSearchWordTimeout) {
      clearTimeout(productsSearchWordTimeout);
      productsSearchWordTimeout = null;
    }
    productsSearchWordTimeout = setTimeout(() => {
      const cleanSearchWord = value.trim();
      const searchWordEncoded = encodeURIComponent(cleanSearchWord);
      onChangeSearchWord && onChangeSearchWord(searchWordEncoded);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  return (
    <div className="table-filter">
      <TextField
        size="small"
        className="filter-text-field"
        label={i18n.t("vademecum.fertiliz.productsFilterLabel")}
        placeholder={i18n.t("vademecum.fertiliz.productsFilterPlaceholder")}
        InputProps={{ startAdornment: <Search /> }}
        onChange={handleChangeProductSearchWord}
      />
    </div>
  );
};

interface VadeProductTypeFilterProps {
  types: string[];
  isLoading?: boolean;
  onChange?: (type: string) => void;
}
const VadeProductTypeFilter = (props: VadeProductTypeFilterProps) => {
  const { types, isLoading, onChange } = props;

  const handleOnChange = (event: any, value: string | null) =>
    onChange && onChange(value || "");

  return (
    <div className="table-filter">
      <Autocomplete
        id="types-filter"
        className="filter-text-field"
        options={types}
        noOptionsText={i18n.t("formErrors.notFoundResults")}
        loading={isLoading}
        onChange={handleOnChange}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={i18n.t("vademecum.fertiliz.productTypesFilterLabel")}
            placeholder={i18n.t(
              "vademecum.fertiliz.productTypesFilterPlaceholder"
            )}
            InputProps={{ ...params.InputProps, startAdornment: <Search /> }}
          />
        )}
      />
    </div>
  );
};

interface VadeManufacturerFilterProps {
  manufacturers: string[];
  isLoading?: boolean;
  onChange?: (manufacturer: string) => void;
}
const VadeManufacturerFilter = (props: VadeManufacturerFilterProps) => {
  const { manufacturers, isLoading, onChange } = props;

  const handleOnChange = (event: any, value: string | null) =>
    onChange && onChange(value || "");

  return (
    <div className="table-filter">
      <Autocomplete
        id="types-filter"
        className="filter-text-field"
        options={manufacturers || []}
        noOptionsText={i18n.t("formErrors.notFoundResults")}
        loading={isLoading}
        onChange={handleOnChange}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={i18n.t("vademecum.fertiliz.manufacturerFilterLabel")}
            placeholder={i18n.t(
              "vademecum.fertiliz.manufacturerFilterPlaceholder"
            )}
            InputProps={{ ...params.InputProps, startAdornment: <Search /> }}
          />
        )}
      />
    </div>
  );
};

interface VadeProductViewProps {
  isOpen: boolean;
  selected: RegisteredFertilizerProduct | null;
  onClose: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const VadeProductView = (props: VadeProductViewProps) => {
  const { isOpen, selected, onClose, onError } = props;

  const [product, setProduct] = useState<RegisteredFertilizerProduct | null>(
    null
  );

  const { isFetching: isUpdating } = useFetch<RegisteredFertilizerProduct>({
    queryKey: ["registeredFertilizProductById", selected?.id],
    selected,
    onSuccess: setProduct,
    enabled: !!selected?.id,
    onError,
  });

  if (!selected) return null;

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      className="vademecum-fertiliz-drawer"
      PaperProps={{ className: "vademecum-fertiliz-drawer" }}
    >
      <div className="container">
        <div className="header">
          <IconButton onClick={onClose}>
            <ArrowBack fontSize="large" color="action" />
          </IconButton>
          <div className="header-titles">
            <h1>{product?.name}</h1>
          </div>
        </div>

        {isUpdating ? (
          <LoadingWithDelay isVisible />
        ) : (
          <div className="content">
            <ResumeSection product={product} />
            <CompositionSection product={product} />
            <HeavyMetalsSection product={product} />
            <InhibitorsSection product={product} />
            <OthersSection product={product} />
          </div>
        )}
      </div>
    </Drawer>
  );
};

interface SectionProps {
  product: RegisteredFertilizerProduct | null;
}

export const ResumeSection = (props: SectionProps) => {
  const { product } = props;
  return (
    <React.Fragment>
      <div className="section-header">
        <h3>{i18n.t("vademecum.fertiliz.productView.resumeSectionTitle")}</h3>
      </div>
      <div className="content-row">
        {product?.name && (
          <div className="content-row-item">
            <h4>{i18n.t("vademecum.fertiliz.productView.nameLabel")}</h4>
            <p>{product?.name}</p>
          </div>
        )}
        {product?.shortName && (
          <div className="content-row-item">
            <h4>{i18n.t("vademecum.fertiliz.productView.shortNameLabel")}</h4>
            <p>{product?.shortName}</p>
          </div>
        )}
        {product?.manufacturer && (
          <div className="content-row-item">
            <h4>
              {i18n.t("vademecum.fertiliz.productView.manufacturerNameLabel")}
            </h4>
            <p>{product?.manufacturer}</p>
          </div>
        )}
        {product?.code && (
          <div className="content-row-item">
            <h4>{i18n.t("vademecum.fertiliz.productView.codeLabel")}</h4>
            <p>{product?.code}</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export const CompositionSection = (props: SectionProps) => {
  const { product } = props;

  const isPrimaryNutrientsVisible =
    (product?.nPercentage || 0) > 0 ||
    (product?.pPercentage || 0) > 0 ||
    (product?.pPercentage || 0) > 0 ||
    (product?.kPercentage || 0) > 0;
  const isNitrogenTypesVisible =
    (product?.nOrganicPercentage || 0) > 0 ||
    (product?.nNitricPercentage || 0) > 0 ||
    (product?.nAmmoniacalPercentage || 0) > 0 ||
    (product?.nUreicPercentage || 0) > 0;
  const isPTypesVisible =
    (product?.p2o5Percentage || 0) > 0 ||
    (product?.p2o5WaterSolublePercentage || 0) > 0 ||
    (product?.p2o5AmmoniumCitrateSolublePercentage || 0) > 0;
  const isKTypesVisible =
    (product?.k2oPercentage || 0) > 0 ||
    (product?.k2oWaterSolublePercentage || 0) > 0;
  const isOtherInorganicsVisible =
    (product?.caoPercentage || 0) > 0 ||
    (product?.mgoPercentage || 0) > 0 ||
    (product?.soPercentage || 0) > 0;

  if (
    !isPrimaryNutrientsVisible &&
    !isNitrogenTypesVisible &&
    !isPTypesVisible &&
    !isKTypesVisible &&
    !isOtherInorganicsVisible
  )
    return null;

  return (
    <React.Fragment>
      <div className="section-header">
        <h3>
          {i18n.t("vademecum.fertiliz.productView.compositionSectionTitle")}
        </h3>
      </div>
      {isPrimaryNutrientsVisible && (
        <div className="content-row-item">
          <h4>
            {i18n.t(
              "vademecum.fertiliz.productView.primaryNutrientsSectionTitle"
            )}
          </h4>
          <table>
            <thead>
              <tr>
                <th>
                  {i18n.t("vademecum.fertiliz.productView.nutrientColumnLabel")}
                </th>
                <th>
                  {i18n.t(
                    "vademecum.fertiliz.productView.percentageColumnValueLabel"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {(product?.nPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t(
                      "vademecum.fertiliz.productView.nitrogenTotalPercentagePercentage"
                    )}
                  </td>
                  <td>{product?.nPercentage}</td>
                </tr>
              )}
              {(product?.pPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.pPercentage")}
                  </td>
                  <td>{product?.pPercentage}</td>
                </tr>
              )}
              {(product?.kPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.kPercentage")}
                  </td>
                  <td>{product?.kPercentage}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {isNitrogenTypesVisible && (
        <div className="content-row-item">
          <h4>{i18n.t("vademecum.fertiliz.productView.nitrogenTypesTitle")}</h4>
          <table>
            <thead>
              <tr>
                <th>
                  {i18n.t("vademecum.fertiliz.productView.nitrogenColumnLabel")}
                </th>
                <th>
                  {i18n.t(
                    "vademecum.fertiliz.productView.percentageColumnValueLabel"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {(product?.nOrganicPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t(
                      "vademecum.fertiliz.productView.nOrganicPercentage"
                    )}
                  </td>
                  <td>{product?.nOrganicPercentage}</td>
                </tr>
              )}
              {(product?.nNitricPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.nNitricPercentage")}
                  </td>
                  <td>{product?.nNitricPercentage}</td>
                </tr>
              )}
              {(product?.nAmmoniacalPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t(
                      "vademecum.fertiliz.productView.nAmmoniacalPercentage"
                    )}
                  </td>
                  <td>{product?.nAmmoniacalPercentage}</td>
                </tr>
              )}
              {(product?.nUreicPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.nUreicPercentage")}
                  </td>
                  <td>{product?.nUreicPercentage}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {isPTypesVisible && (
        <div className="content-row-item">
          <h4>{i18n.t("vademecum.fertiliz.productView.pTypesTitle")}</h4>
          <table>
            <thead>
              <tr>
                <th>
                  {i18n.t("vademecum.fertiliz.productView.pTypesColumnLabel")}
                </th>
                <th>
                  {i18n.t(
                    "vademecum.fertiliz.productView.percentageColumnValueLabel"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {(product?.p2o5Percentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.p2o5Percentage")}
                  </td>
                  <td>{product?.p2o5Percentage}</td>
                </tr>
              )}
              {(product?.p2o5WaterSolublePercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t(
                      "vademecum.fertiliz.productView.p2o5WaterSolublePercentage"
                    )}
                  </td>
                  <td>{product?.p2o5WaterSolublePercentage}</td>
                </tr>
              )}
              {(product?.p2o5AmmoniumCitrateSolublePercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t(
                      "vademecum.fertiliz.productView.p2o5AmmoniumCitrateSolublePercentage"
                    )}
                  </td>
                  <td>{product?.p2o5AmmoniumCitrateSolublePercentage}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {isKTypesVisible && (
        <div className="content-row-item">
          <h4>{i18n.t("vademecum.fertiliz.productView.kTypesTitle")}</h4>
          <table>
            <thead>
              <tr>
                <th>
                  {i18n.t("vademecum.fertiliz.productView.kTypesColumnLabel")}
                </th>
                <th>
                  {i18n.t(
                    "vademecum.fertiliz.productView.percentageColumnValueLabel"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {(product?.k2oPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.k2oPercentage")}
                  </td>
                  <td>{product?.k2oPercentage}</td>
                </tr>
              )}
              {(product?.k2oWaterSolublePercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t(
                      "vademecum.fertiliz.productView.k2oWaterSolublePercentage"
                    )}
                  </td>
                  <td>{product?.k2oWaterSolublePercentage}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {isOtherInorganicsVisible && (
        <div className="content-row-item">
          <h4>
            {i18n.t("vademecum.fertiliz.productView.othersInorganicTypesTitle")}
          </h4>
          <table>
            <thead>
              <tr>
                <th>
                  {i18n.t(
                    "vademecum.fertiliz.productView.otherInorganicTypesColumnLabel"
                  )}
                </th>
                <th>
                  {i18n.t(
                    "vademecum.fertiliz.productView.percentageColumnValueLabel"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {(product?.caoPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.caoPercentage")}
                  </td>
                  <td>{product?.caoPercentage}</td>
                </tr>
              )}
              {(product?.mgoPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.mgoPercentage")}
                  </td>
                  <td>{product?.mgoPercentage}</td>
                </tr>
              )}
              {(product?.soPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.soPercentage")}
                  </td>
                  <td>{product?.soPercentage}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  );
};

export const HeavyMetalsSection = (props: SectionProps) => {
  const { product } = props;

  const isHeavyMetalsAVisible =
    (product?.bPercentage || 0) > 0 ||
    (product?.coPercentage || 0) > 0 ||
    (product?.mnPercentage || 0) > 0 ||
    (product?.moPercentage || 0) > 0 ||
    (product?.fePercentage || 0) > 0 ||
    (product?.znPercentage || 0) > 0;
  const isHeavyMetalsBVisible =
    (product?.cdPercentage || 0) > 0 ||
    (product?.cuPercentage || 0) > 0 ||
    (product?.pbPercentage || 0) > 0 ||
    (product?.niPercentage || 0) > 0 ||
    (product?.hgPercentage || 0) > 0 ||
    (product?.crPercentage || 0) > 0;

  if (!isHeavyMetalsAVisible && !isHeavyMetalsBVisible) return null;

  return (
    <React.Fragment>
      <div className="section-header">
        <h3>
          {i18n.t("vademecum.fertiliz.productView.heavyMetalsSectionTitle")}
        </h3>
      </div>
      {isHeavyMetalsAVisible && (
        <div className="content-row-item">
          <h4>{i18n.t("vademecum.fertiliz.productView.heavyMetalsATitle")}</h4>
          <table>
            <thead>
              <tr>
                <th>
                  {i18n.t(
                    "vademecum.fertiliz.productView.heavyMetalsAColumnLabel"
                  )}
                </th>
                <th>
                  {i18n.t(
                    "vademecum.fertiliz.productView.percentageColumnValueLabel"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {(product?.bPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.bPercentage")}
                  </td>
                  <td>{product?.bPercentage}</td>
                </tr>
              )}
              {(product?.coPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.coPercentage")}
                  </td>
                  <td>{product?.coPercentage}</td>
                </tr>
              )}
              {(product?.mnPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.mnPercentage")}
                  </td>
                  <td>{product?.mnPercentage}</td>
                </tr>
              )}
              {(product?.moPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.moPercentage")}
                  </td>
                  <td>{product?.moPercentage}</td>
                </tr>
              )}
              {(product?.fePercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.fePercentage")}
                  </td>
                  <td>{product?.fePercentage}</td>
                </tr>
              )}
              {(product?.znPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.znPercentage")}
                  </td>
                  <td>{product?.znPercentage}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      {isHeavyMetalsBVisible && (
        <div className="content-row-item">
          <h4>{i18n.t("vademecum.fertiliz.productView.heavyMetalsBTitle")}</h4>
          <table>
            <thead>
              <tr>
                <th>
                  {i18n.t(
                    "vademecum.fertiliz.productView.heavyMetalsBColumnLabel"
                  )}
                </th>
                <th>
                  {i18n.t(
                    "vademecum.fertiliz.productView.percentageColumnValueLabel"
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {(product?.cdPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.cdPercentage")}
                  </td>
                  <td>{product?.cdPercentage}</td>
                </tr>
              )}
              {(product?.cuPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.cuPercentage")}
                  </td>
                  <td>{product?.cuPercentage}</td>
                </tr>
              )}
              {(product?.pbPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.pbPercentage")}
                  </td>
                  <td>{product?.pbPercentage}</td>
                </tr>
              )}
              {(product?.niPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.niPercentage")}
                  </td>
                  <td>{product?.niPercentage}</td>
                </tr>
              )}
              {(product?.hgPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.hgPercentage")}
                  </td>
                  <td>{product?.hgPercentage}</td>
                </tr>
              )}
              {(product?.crPercentage || 0) > 0 && (
                <tr>
                  <td>
                    {i18n.t("vademecum.fertiliz.productView.crPercentage")}
                  </td>
                  <td>{product?.crPercentage}</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </React.Fragment>
  );
};

export const InhibitorsSection = (props: SectionProps) => {
  const { product } = props;

  return (
    <React.Fragment>
      <div className="section-header">
        <h3>
          {i18n.t("vademecum.fertiliz.productView.inhibitorsSectionTitle")}
        </h3>
      </div>
      <div className="content-row-item">
        <table>
          <thead>
            <tr>
              <th>
                {i18n.t("vademecum.fertiliz.productView.inhibitorsColumnLabel")}
              </th>
              <th>
                {i18n.t("vademecum.fertiliz.productView.columnValueLabel")}
              </th>
            </tr>
          </thead>
          <tbody>
            {product?.inhibitorName && (
              <tr>
                <td>
                  {i18n.t("vademecum.fertiliz.productView.inhibitorName")}
                </td>
                <td>{product.inhibitorName}</td>
              </tr>
            )}
            {product?.nitrificationInhibitor !== undefined && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.nitrificationInhibitor"
                  )}
                </td>
                <td>
                  {product.nitrificationInhibitor
                    ? i18n.t("words.yes")
                    : i18n.t("words.no")}
                </td>
              </tr>
            )}
            {product?.ureaseInhibitor !== undefined && (
              <tr>
                <td>
                  {i18n.t("vademecum.fertiliz.productView.ureaseInhibitor")}
                </td>
                <td>
                  {product.ureaseInhibitor
                    ? i18n.t("words.yes")
                    : i18n.t("words.no")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export const OthersSection = (props: SectionProps) => {
  const { product } = props;

  if (
    (product?.cOrganicPercentage || 0) <= 0 &&
    (product?.organicMateriaPercentage || 0) <= 0 &&
    !product?.aggregationState &&
    product?.cover === undefined
  )
    return null;

  return (
    <React.Fragment>
      <div className="section-header">
        <h3>{i18n.t("vademecum.fertiliz.productView.othersSectionTitle")}</h3>
      </div>
      <div className="content-row-item">
        <table>
          <thead>
            <tr>
              <th>
                {i18n.t("vademecum.fertiliz.productView.othersColumnLabel")}
              </th>
              <th>
                {i18n.t("vademecum.fertiliz.productView.columnValueLabel")}
              </th>
            </tr>
          </thead>
          <tbody>
            {(product?.cOrganicPercentage || 0) > 0 && (
              <tr>
                <td>
                  {i18n.t("vademecum.fertiliz.productView.cOrganicPercentage")}
                </td>
                <td>{product?.cOrganicPercentage}</td>
              </tr>
            )}
            {(product?.organicMateriaPercentage || 0) > 0 && (
              <tr>
                <td>
                  {i18n.t(
                    "vademecum.fertiliz.productView.organicMateriaPercentage"
                  )}
                </td>
                <td>{product?.organicMateriaPercentage}</td>
              </tr>
            )}
            {product?.aggregationState && (
              <tr>
                <td>
                  {i18n.t("vademecum.fertiliz.productView.aggregationState")}
                </td>
                <td>{product.aggregationState}</td>
              </tr>
            )}
            {product?.cover !== undefined && (
              <tr>
                <td>{i18n.t("vademecum.fertiliz.productView.cover")}</td>
                <td>
                  {product.cover ? i18n.t("words.yes") : i18n.t("words.no")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};
