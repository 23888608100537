import moment from "moment";
import i18n from "../config/configI18n";
/**
 * Get object field value given dot string notation
 * @param field: field name with notation
 * @param obj: object
 * @returns: value of the field
 */
export const getFieldValue = (obj: any, field?: string) => {
  return field?.split(".")?.reduce((o: any, i: string) => o?.[i], obj);
};

/**
 * first parameter: field name with notation
 * second parameter: object
 * return: value of the field
 * example: getFieldValue("a.b.c", {a: {b: {c: 1}}}) => 1
 */
export const getFieldValueByColumnNotation = (
  field: string,
  obj: any,
  valueLabel?: string,
  arrayFieldName?: string,
  unitFieldName?: string,
  unitValue?: string,
  field2?: string
): any => {
  switch (valueLabel) {
    // If value label is count, return the field array length
    case "count":
      return obj?.[field]?.length ?? 0;
    case "boolean":
      return obj?.[field] ? i18n.t("words.yes") : i18n.t("words.no");
    case "array": {
      const array = obj?.[field];
      if (!array) return "";
      if (array.length === 1 && arrayFieldName)
        return getFieldValue(array[0], arrayFieldName) || "";
      else return obj?.[field]?.length ?? 0;
    }
    case "unit":
      return obj?.[field] && unitFieldName
        ? `${obj[field]} ${getFieldValue(obj, unitFieldName) || ""}`
        : "";
    case "unitValue":
      return obj?.[field] && unitValue
        ? `${obj[field]} ${unitValue || ""}`
        : "";
    case "or":
      return getFieldValue(obj, field) || getFieldValue(obj, field2);
    case "isNew":
    default:
      return getFieldValue(obj, field);
  }
};

/**
 * Find an object of an array by a field
 * @param array: array of objects
 * @param field: field to search
 * @param value: value to search
 */
export const findObjectByField = (array: any[], field: string, value: any) => {
  return array.find((obj) => obj[field] === value);
};

/**
 * Group an array of objects by a field
 * @param array: array of objects
 * @param field: field to group
 * @returns: object with the groups
 */
export const groupBy = (array: any[], field: string) => {
  return array.reduce((acc, obj) => {
    const key = getFieldValueByColumnNotation(field, obj);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

/**
 * Check if a object field is in array list
 * @param obj: object
 * @param field: field to check
 * @param array: array to check
 * @returns: boolean
 */
export const isFieldInArray = (obj: any, field: string, array: any[]) => {
  const value = getFieldValueByColumnNotation(field, obj);
  return array.includes(value);
};

/**
 * Check if a object field of an array is in array list
 * @param array: array of objects
 * @param field: field to check
 * @param arrayToCheck: array to check
 * @returns: boolean
 */
export const isFieldInArrayInArray = (
  array: any[],
  field: string,
  arrayToCheck: any[]
) => {
  return array.some((obj) => isFieldInArray(obj, field, arrayToCheck));
};

/**
 * Add item to a list
 * @param list: list to add
 * @param item: item to add
 * @returns: new list
 */
export const addItemToList = (list: any[] | undefined, item: any) => {
  return [...(list || []), item];
};

/**
 * Add item to a list if not exits by a field
 * @param list: list to add
 * @param item: item to add
 * @param field: field to check
 * @returns: new list
 */
export const addItemToListIfNotExistsByField = (
  list: any[] | undefined,
  item: any,
  field: string
) => {
  if (!list) return [item];
  const exists = list.find(
    (obj) =>
      getFieldValueByColumnNotation(field, obj) ===
      getFieldValueByColumnNotation(field, item)
  );
  if (exists) return list;
  return [...list, item];
};

/**
 * Add items to a list if not exits by a field
 * @param list: list to add
 * @param items: items to add
 * @param field: field to check
 * @returns: new list
 */
export const addItemsToListIfNotExistsByField = (
  list: any[] | undefined,
  items: any[],
  field: string
) => {
  if (!list) return items;
  const newItems = items.filter(
    (item) =>
      !list.find(
        (obj) =>
          getFieldValueByColumnNotation(field, obj) ===
          getFieldValueByColumnNotation(field, item)
      )
  );
  return [...list, ...newItems];
};

/**
 * Update item of a list
 * @param list: list to update
 * @param item: item to update
 * @param field: field to update
 * @returns: new list
 */
export const updateItemOfList = (
  list: any[] | undefined,
  item: any,
  field: string
) => {
  return list?.map((obj) => {
    if (
      getFieldValueByColumnNotation(field, obj) ===
      getFieldValueByColumnNotation(field, item)
    ) {
      return item;
    }
    return obj;
  });
};

/**
 * Delete item of a list
 * @param list: list to delete
 * @param item: item to delete
 * @param field: field to delete
 * @returns: new list
 */
export const deleteItemOfList = (
  list: any[] | undefined,
  item: any,
  field: string
) => {
  return list?.filter(
    (obj) =>
      getFieldValueByColumnNotation(field, obj) !==
      getFieldValueByColumnNotation(field, item)
  );
};

/**
 * Delete items of a list
 * @param list: list to delete
 * @param items: items to delete
 * @param field: field of reference
 * @returns: new list
 */
export const deleteItemsOfList = (
  list: any[] | undefined,
  items: any[],
  field: string
) => {
  return list?.filter(
    (listItem) => !items.find((item) => item[field] === listItem[field])
  );
};

/**
 * Delete list items, where items is an array of list field values
 */
export const deleteItemsOfListByField = (
  list: any[] | undefined,
  items: any[],
  field: string
) => {
  return list?.filter(
    (obj) => !items.includes(getFieldValueByColumnNotation(field, obj))
  );
};

/**
 * Given two array, add or update items of the first array with the second array by a field
 * @param list: list to update
 * @param items: items to update
 * @param field: field to update
 * @returns: new list
 * Example: [{id: 1, name: "a"}, {id: 2, name: "b"}] + [{id: 1, name: "c"}] => [{id: 1, name: "c"}, {id: 2, name: "b"}]
 */
export const addOrUpdateItemsOfListByField = (
  list: any[] | undefined,
  items: any[],
  field: string
) => {
  const newList = [...(list || [])];
  items.forEach((item) => {
    const idx = newList.findIndex(
      (obj) =>
        getFieldValueByColumnNotation(field, obj) ===
        getFieldValueByColumnNotation(field, item)
    );
    if (idx === -1) newList.push(item);
    else newList[idx] = item;
  });
  return newList;
};

/**
 * If a field value is duplicated in a list, remove all items with that value
 * Example: [{id: 1, name: "a"}, {id: 2, name: "b"}, {id: 3, name: "a"}] => [{id: 2, name: "b"}]
 */
export const removeDuplicatedItemsOfListByField = (
  list: any[] | undefined,
  field: string
) => {
  const values = list?.map((obj) => getFieldValueByColumnNotation(field, obj));
  const duplicatedValues = values?.filter(
    (value, index) => values.indexOf(value) !== index
  );
  return deleteItemsOfListByField(list, duplicatedValues || [], field);
};

/**
 * Given a string, return the string with the first letter in uppercase
 */
export const capitalize = (str?: string) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/**
 * Find the max value of a field in a list or 0 if the list is empty
 * @param list: list to search
 * @param field: field to search
 * @returns: max value
 * Example: [{id: 1, value: 10}, {id: 2, value: 20}, {id: 3, value: 5}] => 20
 */
export const getMaxValueOfList = (list: any[] | undefined, field: string) => {
  return list?.reduce((acc, obj) => {
    const value = getFieldValueByColumnNotation(field, obj);
    return value > acc ? value : acc;
  }, 0);
};

export const includedInRoutes = (
  inputString: string,
  routes: any
): string | null => {
  const routeKeys = Object.keys(routes);

  for (const key of routeKeys) {
    if (inputString.includes(routes[key])) {
      return routes[key];
    }
  }
  return null;
};

/**
 * Date converter
 * Converts a date to a string with the format "DD MMM" if the year is the current year, otherwise "DD MMM YY"
 */
export const dateConverter = ({
  date,
  dateFormat = "YYYY-MM-DD",
}: {
  date: string | undefined;
  dateFormat?: string;
}): string => {
  if (!date) return "";

  const dateMoment = moment(date, dateFormat);
  if (moment().year() !== dateMoment.year())
    return dateMoment.format("DD MMM YYYY");
  else return dateMoment.format("DD MMM");
};

/**
 * Get API response data if the body exits or an empty object
 */
export const getResponseData = async (response: Response) => {
  try {
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return undefined;
  }
};

/**
 * Find string in other string replacing accents and strange characters
 * @param str1: string 1
 * @param str2: string 2
 * @returns: boolean
 */
export const stringMatching = (str1: string, str2: string): boolean => {
  const strNormalized_1 = str1
    .normalize("NFD")
    .toLowerCase()
    .replace(/[\u0300-\u036f]/g, "")
    .trim();
  const strNormalized_2 = str2
    .normalize("NFD")
    .toLowerCase()
    .replace(/[\u0300-\u036f]/g, "")
    .trim();
  return (
    strNormalized_1.includes(strNormalized_2) ||
    strNormalized_2.includes(strNormalized_1)
  );
};

/**
 * Find substring in a string replacing accents and strange characters
 * @param searchWord: string to search
 * @param str: substring to search
 * @returns: boolean
 */
export const findWord = (
  str: string | undefined,
  searchWord: string
): boolean => {
  if (!str) return false;

  const strNormalized = str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim();
  return strNormalized.toLowerCase().includes(searchWord.trim().toLowerCase());
};

export function uniqueById(objects: any[]): any[] {
  const uniqueObjects: Map<number, any> = new Map();

  for (const obj of objects) {
    uniqueObjects.set(obj.id, obj);
  }

  const uniqueArray: any[] = Array.from(uniqueObjects.values());
  return uniqueArray;
}

/**
 * Get query client key from route path
 */
export const getQueryClientKey = (path: string): string => {
  const pathArray = path.split("/");
  const currentPath = pathArray[pathArray.length - 1];
  switch (currentPath) {
    case "data":
      return "agrobusinessById";
    case "leadership":
      return "leaderList";
    case "water-sources":
      return "waterSources";
    default:
      return currentPath;
  }
};
