import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import i18n from "../../config/configI18n";

import { Collapse, FormControl, FormGroup, FormLabel } from "@mui/material";
import { TrackChanges, Search } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import FormikListItemSwitch from "../../components/elements/FormikListItemSwitch";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";
import TestBanner from "../../components/banners/TestBanner";
import PartitionForm from "../../components/forms/PartitionForm";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";
import AlertEmptyList from "../../components/AlertEmptyList";
import DialogFertilizationPlanSector from "../../components/dialogs/DialogFertilizationPlanSector";
import SiexUploadStatusBanner from "../../components/banners/SiexUploadStatusBanner";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import FertilizationPlan from "../../models/fertiliz/FertilizationPlan";
import FertilizationPlanSector from "../../models/fertiliz/FertilizationPlanSector";
import CropProduct from "../../models/catalogue/CropProduct";
import AgroBusinessPhytosanitaryInvolvedEntity from "../../models/agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import SiexRequestItem from "../../models/siex/SiexRequestItem";
import { Column } from "../../models/Generic";
import Document from "../../models/files/Document";

import {
  addItemToListIfNotExistsByField,
  deleteItemsOfListByField,
  updateItemOfList,
} from "../../helpers/utils";

import { SnackbarInterface } from "../../constants/interfaces";
import { FBEntitiesTypes, FormMode } from "../../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";
import { PROTECTED_ROUTES } from "../../routes/routeNames";

const ExploitationFertilizationPlansScreen = () => {
  const facilityColumns: Column[] = [
    { id: "name", label: i18n.t("fertiPlans.column.name"), numeric: false },
    {
      id: "nitrogenNeed",
      label: i18n.t("fertiPlans.column.nitrogenNeed"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "kg/ha",
    },
    {
      id: "phosphorusNeed",
      label: i18n.t("fertiPlans.column.phosphorusNeed"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "kg/ha",
    },
    {
      id: "potassiumNeed",
      label: i18n.t("fertiPlans.column.potassiumNeed"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "kg/ha",
    },
    {
      id: "advisor",
      label: i18n.t("fertiPlans.column.advisor"),
      numeric: false,
      valueLabel: "boolean",
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const { data: fertilizationPlans, isFetching } = useFetch<
    FertilizationPlan[]
  >({
    queryKey: [
      "fertilizationPlans",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
  });

  const selected = useMemo(
    () =>
      formMode === FormMode.EDIT && selectedRowIds.length === 1
        ? fertilizationPlans?.find(
            (fp: FertilizationPlan) => fp.id === selectedRowIds[0]
          )
        : undefined,
    [selectedRowIds, formMode, fertilizationPlans]
  );

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const validateForm = (values: FertilizationPlan) => {
    const errors: any = {};
    if (!values.name) errors.name = i18n.t("formErrors.requiredField");
    if (
      isSiexActive &&
      (!values.fertilizedSectors || values.fertilizedSectors.length === 0)
    )
      errors.fertilizedSectors = i18n.t("formErrors.requiredField");
    if (isSiexActive && !values.generationDate)
      errors.generationDate = i18n.t("formErrors.requiredField");
    return errors;
  };

  const handleSubmit = (values: FertilizationPlan) => {
    switch (formMode) {
      case FormMode.CREATE:
        fertilizationPlanCreateMutation.mutate(values);
        break;
      case FormMode.EDIT:
        fertilizationPlanEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new FertilizationPlan(),
    validate: validateForm,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("fertiPlans.drawerTitleCreate")
      : i18n.t("fertiPlans.drawerTitleEdit");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Open drawer if is redirected from error siex register
  useEffect(() => {
    if (
      siexRegister &&
      siexRegister.entityClassName === FBEntitiesTypes.FERTILIZATION_PLAN &&
      siexRegister.entityClassId
    ) {
      setSelectedRowIds([siexRegister.entityClassId]);
      openDrawer(FormMode.EDIT);
    }
  }, [siexRegister]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`, { state: { siexRegister } });
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const fertilizationPlanCreateMutation = useCrud<FertilizationPlan>({
    key: "postFertilizationPlan",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const fertilizationPlanEditMutation = useCrud<FertilizationPlan>({
    key: "putFertilizationPlan",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const fertilizationPlanDeleteMutation = useCrud({
    key: "deleteFertilizationPlans",
    values: fertilizationPlans?.filter((f: FertilizationPlan) =>
      selectedRowIds.includes(f?.id || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: errors.name
          ? i18n.t("formErrors.requiredFields")
          : i18n.t("formErrors.addAtLeastOneSector"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    fertilizationPlanDeleteMutation.mutate(selectedRowIds);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("fertiPlans.backAlertTitle")}
        contentText={i18n.t("fertiPlans.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("fertiPlans.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("fertiPlans.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={fertilizationPlanDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("fertiPlans.tableTitle")}
        headerText={i18n.t("fertiPlans.headerText")}
        colums={facilityColumns}
        rows={fertilizationPlans}
        selectedRows={selectedRowIds}
        emptyTableIcon={
          <TrackChanges sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={i18n.t("fertiPlans.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("fertiPlans.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("fertiPlans.emptyTableBtnText")}
        addBtnVariant="contained"
        siexRows
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          fertilizationPlanCreateMutation.isLoading ||
          fertilizationPlanEditMutation.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <FertilizationPlanForm
          formik={formik}
          isSiexActive={isSiexActive}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          selectedEditRow={selected}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationFertilizationPlansScreen;

interface FertilizationPlanFormProps {
  formik: FormikProps<FertilizationPlan>;
  isSiexActive: boolean;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: FertilizationPlan;
  onCloseFilesForm?: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}

const FertilizationPlanForm = (props: FertilizationPlanFormProps) => {
  const {
    formik,
    isSiexActive,
    selectedEditRow,
    isOpenFilesForm = false,
    onCloseFilesForm,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition, agroBusinessPartitions } =
    useSession();
  const queryClient = useQueryClient();
  const location = useLocation();

  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const { isFetching: isUpdating } = useFetch<FertilizationPlan>({
    queryKey: ["fertilizationPlan", selectedEditRow?.id],
    selected: selectedEditRow,
    onSuccess: (data: FertilizationPlan) => {
      formik.setValues(data);
      // Update in array without refetch
      queryClient.setQueryData<FertilizationPlan[]>(
        ["fertilizationPlans", selectedABPartition?.id, selectedABAccount?.id],
        (oldData) => updateItemOfList(oldData, data, "id")
      );
    },
    enabled: !!selectedEditRow?.id,
    onError,
  });

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      {isSiexActive && selectedEditRow && (
        <SiexUploadStatusBanner
          entityClassId={selectedEditRow.id || siexRegister.entityClassId}
          status={selectedEditRow.siexStatus}
          timestamp={selectedEditRow.siexSentOn}
          errorSiexRegister={siexRegister}
        />
      )}
      <DefinitionForm {...props} />
      <FertilizationPlanNeedsForm {...props} />
      <FertilizationPlanSectorsForm {...props} />
      <AdvisorForm {...props} />
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("fertiPlans.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </>
  );
};

const DefinitionForm = (props: FertilizationPlanFormProps) => {
  const { formik, forceExpanded, isSiexActive, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: cropProducts, isFetching: isFetchingProducts } = useFetch<
    CropProduct[]
  >({
    queryKey: ["cropProducts", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    onError,
  });

  return (
    <BrioCard
      title={i18n.t("fertiPlans.definitionForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="name"
            label={i18n.t("fertiPlans.definitionForm.name")}
            required
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="precedingProduct"
            label={i18n.t("fertiPlans.definitionForm.precedingProduct")}
            placeholder={i18n.t(
              "fertiPlans.definitionForm.precedingProductPlaceholder"
            )}
            options={cropProducts || []}
            optionLabelFieldName="name"
            startIcon={Search}
            loading={isFetchingProducts}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          className="form-control"
          required={isSiexActive}
        >
          <DatePicker
            className="form-input"
            format="DD/MM/YYYY"
            label={`${i18n.t("fertiPlans.definitionForm.generationDate")}${
              isSiexActive ? "*" : ""
            }`}
            value={
              formik.values?.generationDate
                ? moment(formik.values.generationDate, "YYYY-MM-DD")
                : null
            }
            onChange={(date) =>
              formik.setFieldValue("generationDate", date?.format("YYYY-MM-DD"))
            }
          />
          {formik.touched.generationDate && formik.errors.generationDate && (
            <FormLabel className="form-label">
              {i18n.t("formErrors.requiredField")}
            </FormLabel>
          )}
        </FormControl>
        <FormControl className="form-control">
          <FormikListItemSwitch
            formik={formik}
            name="toolUsed"
            primaryText={i18n.t("fertiPlans.definitionForm.toolUsed")}
            secondaryText={i18n.t("fertiPlans.definitionForm.toolUsedSubtitle")}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const FertilizationPlanNeedsForm = (props: FertilizationPlanFormProps) => {
  const { formik, forceExpanded } = props;

  return (
    <BrioCard
      title={i18n.t("fertiPlans.fertiNeedsForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            formik={formik}
            className="form-input-row-32"
            name="nitrogenNeed"
            label={i18n.t("fertiPlans.fertiNeedsForm.nitrogenNeed")}
            type="number"
            valueUnit="kg/ha"
          />
          <FormikTextField
            formik={formik}
            className="form-input-row-32"
            name="phosphorusNeed"
            label={i18n.t("fertiPlans.fertiNeedsForm.phosphorusNeed")}
            type="number"
            valueUnit="kg/ha"
          />
          <FormikTextField
            formik={formik}
            className="form-input-row-32"
            name="potassiumNeed"
            label={i18n.t("fertiPlans.fertiNeedsForm.potassiumNeed")}
            type="number"
            valueUnit="kg/ha"
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="productionTarget"
            label={i18n.t("fertiPlans.fertiNeedsForm.productionTarget")}
            type="number"
            valueUnit="kg/ha"
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const FertilizationPlanSectorsForm = (props: FertilizationPlanFormProps) => {
  const fertilizationPlanSectorColumns: Column[] = [
    {
      id: "sector.name",
      label: i18n.t("fertiPlans.sectorsForm.column.name"),
      numeric: false,
    },
    {
      id: "fertilizedArea",
      label: i18n.t("fertiPlans.sectorsForm.column.area"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "ha",
    },
  ];

  const { formik, isSiexActive } = props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedFertilizationPlanSector, setSelectedFertilizationPlanSector] =
    useState<FertilizationPlanSector | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedFertilizationPlanSector(null);
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };

  // Only dialog to create or edit treated sector
  const handleConfirmDialog = (selected: FertilizationPlanSector) => {
    const updatedFertilizationPlanSectors =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.fertilizedSectors,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.fertilizedSectors, selected, "idx");
    formik.setFieldValue("fertilizedSectors", updatedFertilizationPlanSectors);
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.fertilizedSectors?.find(
      (fs: FertilizationPlanSector) => fs.idx === idx
    );
    if (selected) {
      setSelectedFertilizationPlanSector(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    const updatedFertilizationPlanSectors = deleteItemsOfListByField(
      formik.values.fertilizedSectors,
      idxs,
      "idx"
    );
    formik.setFieldValue("fertilizedSectors", updatedFertilizationPlanSectors);
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        idx="idx"
        title={i18n.t("fertiPlans.sectorsForm.tableTitle")}
        headerText={i18n.t("fertiPlans.sectorsForm.headerText")}
        btnText={i18n.t("fertiPlans.sectorsForm.tableBtnText")}
        colums={fertilizationPlanSectorColumns}
        rows={formik.values.fertilizedSectors}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        optional={!isSiexActive}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClikEdit}
        onClickDelete={handleClickDelete}
      />
      <DialogFertilizationPlanSector
        open={isOpenDialog}
        selected={selectedFertilizationPlanSector}
        fertilizedSectors={formik.values.fertilizedSectors}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};

const AdvisorForm = (props: FertilizationPlanFormProps) => {
  const { formik, forceExpanded, onError } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] =
    useState<boolean>(false);

  const { data: advisors, isFetching: isFetchingAdvisors } = useFetch<
    AgroBusinessPhytosanitaryInvolvedEntity[]
  >({
    queryKey: [
      "fertilizerAdvisorInvolvedEntities",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError,
  });

  const openRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(true);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);
  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  const handleClickNewAdvisor = () => openRedirectAlertDialog();

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_INVOLVED_ENTITIES}`);
  };

  return (
    <BrioCard
      title={i18n.t("fertiPlans.advisorForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
    >
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t("fertiPlans.redirectAlertDialogTitle")}
        contentText={i18n.t("fertiPlans.redirectAlertDialogDescription")}
        open={!!isOpenRedirectAlertDialog}
        onCancel={cancelRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
      <FormGroup className="form-group">
        <FormControl
          variant="outlined"
          className="form-control"
          sx={{ mb: "0px !important" }}
        >
          {!isFetchingAdvisors && advisors?.length === 0 && (
            <AlertEmptyList onClick={handleClickNewAdvisor}>
              {i18n.t("fertiPlans.advisorForm.emptyList")}
            </AlertEmptyList>
          )}
          <FormikAutocomplete
            formik={formik}
            name="advisor"
            label={i18n.t("fertiPlans.advisorForm.advisor")}
            optionLabelFieldName="entity.nameAndSurname"
            options={advisors || []}
            loading={isFetchingAdvisors}
            addNewOption
            onClickNewOption={handleClickNewAdvisor}
          />
        </FormControl>
        <Collapse className="collapse-container" in={!!formik.values?.advisor}>
          <div className="grow-container">
            <FormControl
              variant="outlined"
              className="form-control"
              sx={{ marginTop: "20px" }}
            >
              <DatePicker
                className="form-input"
                format="DD/MM/YYYY"
                label={i18n.t("fertiPlans.advisorForm.adviceDate")}
                value={
                  formik.values?.adviceDate
                    ? moment(formik.values.adviceDate, "YYYY-MM-DD")
                    : null
                }
                onChange={(date) =>
                  formik.setFieldValue("adviceDate", date?.format("YYYY-MM-DD"))
                }
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};
