import AgroBusinessPartition from "../../agrobusiness/AgroBusinessPartition";
import AgroBusinessPhytosanitaryInvolvedEntity from "../../agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import CropProduct from "../../catalogue/CropProduct";
import FertilizationPlanSector from "../../fertiliz/FertilizationPlanSector";

import { FBESiexRequestStatus } from "../../../constants/enums";

class FBFertilizationPlan {
  id?: number;
  agroBusinessPartition?: AgroBusinessPartition;
  name?: string;
  nitrogenNeed?: number;
  phosphorusNeed?: number;
  potassiumNeed?: number;
  productionTarget?: number;
  precedingProduct?: CropProduct;
  toolUsed?: boolean;
  advisor?: AgroBusinessPhytosanitaryInvolvedEntity;
  adviceDate?: string;
  generationDate?: string;
  fertilizedSectors?: FertilizationPlanSector[];
  enabledDate?: string;
  disabledDate?: string;
  siexId?: number;
  siexStatus?: FBESiexRequestStatus;
  siexSentOn?: number;
  siexBookId?: number;
  siexRequestCode?: number;
  siexRequestId?: number;

  constructor(
    id?: number,
    agroBusinessPartition?: AgroBusinessPartition,
    name?: string,
    nitrogenNeed?: number,
    phosphorusNeed?: number,
    potassiumNeed?: number,
    productionTarget?: number,
    precedingProduct?: CropProduct,
    toolUsed?: boolean,
    advisor?: AgroBusinessPhytosanitaryInvolvedEntity,
    adviceDate?: string,
    generationDate?: string,
    fertilizedSectors?: FertilizationPlanSector[],
    enabledDate?: string,
    disabledDate?: string
  ) {
    this.id = id;
    this.agroBusinessPartition = agroBusinessPartition;
    this.name = name;
    this.nitrogenNeed = nitrogenNeed;
    this.phosphorusNeed = phosphorusNeed;
    this.potassiumNeed = potassiumNeed;
    this.productionTarget = productionTarget;
    this.precedingProduct = precedingProduct;
    this.toolUsed = toolUsed;
    this.advisor = advisor;
    this.adviceDate = adviceDate;
    this.generationDate = generationDate;
    this.fertilizedSectors = fertilizedSectors;
    this.enabledDate = enabledDate;
    this.disabledDate = disabledDate;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.agroBusinessPartition = new AgroBusinessPartition().mapToClass(
      obj?.agroBusinessPartition
    );
    this.name = obj.name;
    this.nitrogenNeed = obj.nitrogenNeed;
    this.phosphorusNeed = obj.phosphorusNeed;
    this.potassiumNeed = obj.potassiumNeed;
    this.productionTarget = obj.productionTarget;
    this.precedingProduct = new CropProduct().mapToClass(obj?.precedingProduct);
    this.toolUsed = obj.toolUsed;
    this.advisor = new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(
      obj?.advisor
    );
    this.adviceDate = obj.adviceDate;
    this.generationDate = obj.generationDate;
    this.fertilizedSectors =
      obj?.fertilizedSectors
        ?.map((fertilizedSector: any) =>
          new FertilizationPlanSector().mapToClass(fertilizedSector)
        )
        ?.filter(
          (fertilizedSector: FertilizationPlanSector) =>
            !fertilizedSector?.sector?.disabledDate
        ) || [];
    this.enabledDate = obj.enabledDate;
    this.disabledDate = obj.disabledDate;
    this.siexId = obj?.siexId;
    this.siexStatus = obj?.siexStatus;
    this.siexSentOn = obj?.siexSentOn;
    this.siexBookId = obj?.siexBookId;
    this.siexRequestCode = obj?.siexRequestCode;
    this.siexRequestId = obj?.siexRequestId;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.agroBusinessPartition = this.agroBusinessPartition?.mapToApiModel();
    obj.precedingProduct = this.precedingProduct?.mapToApiModel();
    obj.advisor = this.advisor?.mapToApiModel();
    obj.fertilizedSectors = this.fertilizedSectors?.map(
      (fertilizedSector: FertilizationPlanSector) =>
        fertilizedSector?.mapToApiModel()
    );
    return obj;
  }
}

export default FBFertilizationPlan;
