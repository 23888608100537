export enum EntityType {
  PHYSICAL = "PHYSICAL",
  LEGAL = "LEGAL",
}

export enum EntitySex {
  MALE = "MALE",
  FEMALE = "FEMALE",
  INTERSEX = "OTHER",
}

export enum LeaderType {
  OWNER = "OWNER",
  COOWNER = "COOWNER",
  OWNERREPRESENTATIVE = "OWNERREPRESENTATIVE",
  MANAGER = "MANAGER",
}

export enum PhytosanitaryTrainingLevel {
  BASIC = "BASIC",
  SKILLED = "SKILLED",
  FUMIGATOR = "FUMIGATOR",
  PILOT = "PILOT",
}

export enum ToolInspectionResult {
  OK = "OK",
  OK_WARNINGS = "OK_WARNINGS",
  NO_OK = "NO_OK",
}

export enum WaterSourceType {
  RIVER = "RIVER",
  WELL = "WELL",
  SPRING = "SPRING",
  FOUNTAIN = "FOUNTAIN",
  RESERVOIR = "RESERVOIR",
}

export enum CropProductCategory {
  Horticultural = "HORTICULTURAL",
  Energetic = "ENERGETIC",
  Fruity = "FRUITY",
  Aromatic = "AROMATIC",
}

export enum ExploitationSystemType {
  Rainfed = "S",
  Irrigated = "R",
}

export enum PluviometrySource {
  BRIOAGRO_STATION = "BRIOAGRO_STATION",
  BRIOAGRO_METEO = "BRIOAGRO_METEO",
  MANUAL = "MANUAL",
}

export enum FertilizerStockMovementType {
  PURCHASE = "PURCHASE",
  ELABORATION = "ELABORATION",
  STOCK_ADJUST = "STOCK_ADJUST",
  FERTILIZATION = "FERTILIZATION",
}

export enum FertilizerSubstanceType {
  MACRONUTRIENT = "MACRONUTRIENT",
  MICRONUTRIENT = "MICRONUTRIENT",
  HEAVYMETAL = "HEAVYMETAL",
}

export enum PhytosanitaryStockMovementType {
  PURCHASE = "PURCHASE",
  STOCK_ADJUST = "STOCK_ADJUST",
  TREATMENT = "TREATMENT",
}

export enum PhytosanitaryTreatmentType {
  CROP = "CROP",
  POST_HARVEST = "POST_HARVEST",
  FACILITY = "FACILITY",
}

export enum CropPlantationType {
  SOWING = "SOWING",
  PLANTATION = "PLANTATION",
}

export enum FormMode {
  CREATE = "CREATE",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

export enum FertilizationOriginType {
  PRODUCT = "PRODUCT",
  MIXTURE = "MIXTURE",
}

export enum FBPersonRole {
  NONE = "NONE",
  READER = "READER",
  WRITER = "WRITER",
  ADMIN = "ADMIN",
}

export enum AuthProviders {
  EMAIL = "EMAIL",
  GOOGLE = "GOOGLE",
  APPLE = "APPLE",
  MICROSOFT = "MICROSOFT",
}

export enum EnergyTypeEnum {
  NONE = "NONE",
  RENEWABLE = "RENEWABLE",
  NON_RENEWABLE = "NON_RENEWABLE",
  MIXED = "MIXED",
}

export enum StockFertilizerTab {
  INVENTORY = "inventory",
  MOVEMENTS = "movements",
}

export enum StockPhytosanitaryTab {
  INVENTORY = "inventory",
  MOVEMENTS = "movements",
}

export enum PhytoRecipeStatus {
  DRAFT = "DRAFT",
  PRESCRIBED = "PRESCRIBED",
  ACCEPTED = "ACCEPTED",
  REFUSED = "REFUSED",
  APPLIED = "APPLIED",
}

export enum PhytoRecipeType {
  CROP = "CROP",
  POSTHARVEST = "POSTHARVEST",
  FACILITY = "FACILITY",
}

export enum PlantProblemType {
  PLAGUE = "PLAGUE",
  DISEASE = "DISEASE",
  WEED = "WEED",
  REGULATORS = "REGULATORS",
}

export enum FBENotificationType {
  INFO = "INFO",
  WARNING = "WARNING",
  ALERT = "ALERT",
}

export enum ExportDataFormat {
  DOCX = "DOCX",
  PDF = "PDF",
}

export enum FBESiexRequestStatus {
  ADVISOR_PENDING = "ADVISOR_PENDING",
  PENDING = "PENDING",
  SENT_PENDING = "SENT_PENDING",
  OK = "OK",
  ERROR = "ERROR",
  NOSYNC = "NOSYNC",
}

export enum FBESiexOperationType {
  INSERT = "INSERT",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export enum FBESiexOperationResult {
  PENDING = "PENDING",
  OK = "OK",
  ERROR = "ERROR",
}

export enum FBEntitiesTypes {
  ANALYSYS = "FBAnalysis",
  CROP_IRRIGATION = "FBCropIrrigation",
  FERTILIZATION = "FBFertilization",
  FERTILIZATION_PLAN = "FBFertilizationPlan",
  CROP_PHYTO_TREATMENT = "FBCropPhytosanitaryTreatment",
  POSTHARVEST_PHYTO_TREATMENT = "FBPostHarvestPhytosanitaryTreatment",
  FACILITY_PHYTO_TREATMENT = "FBFacilityPhytosanitaryTreatment",
  TREATED_SEED = "FBTreatedSeed",
}
