import { useState, useMemo } from "react";
import { FormikProps } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "../../config/configI18n";

import { FormGroup, FormControl, Collapse } from "@mui/material";
import { Search } from "@mui/icons-material";

import { BrioCard, TableBrioCard } from "../BrioCard";
import DialogFertilizerProductMacronutrient from "../dialogs/DialogFertilizerProductMacronutrient";
import DialogFertilizerProductMicronutrient from "../dialogs/DialogFertilizerProductMicronutrient";
import DialogFertilizerProductHeavyMetal from "../dialogs/DialogFertilizerProductHevayMetal";
import FormikAutocomplete from "../elements/FormikAutocomplete";
import FormikTextField from "../elements/FormikTextField";
import FormikSelect from "../elements/FormikSelect";
import FilesForm from "../../components/forms/FilesForm";
import LoadingWithDelay from "../elements/LoadingWithDelay";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import { Column } from "../../models/Generic";
import FertilizerProduct, {
  FertilizerProductType,
  MEASUREMENT_UNIT_CODES,
} from "../../models/fertiliz/FertilizerProduct";
import RegisteredFertilizerProduct from "../../models/vademecumFertiliz/RegisteredFertilizerProduct";
import FertilizerType from "../../models/catalogue/FertilizerType";
import FertilizerProductMacronutrient from "../../models/fertiliz/FertilizerProductMacronutrient";
import FertilizerProductMicronutrient from "../../models/fertiliz/FertilizerProductMicronutrient";
import FertilizerProductHeavyMetal from "../../models/fertiliz/FertilizerProductHeavyMetal";
import MeasurementUnit from "../../models/catalogue/MeasurementUnit";
import Document from "../../models/files/Document";

import { FertilizerStockMovementType, FormMode } from "../../constants/enums";
import { SnackbarInterface } from "../../constants/interfaces";
import { SEARCH_LIKE_TIMEOUT_DELAY_MS } from "../../constants/constants";

import {
  addItemToListIfNotExistsByField,
  updateItemOfList,
  deleteItemsOfListByField,
} from "../../helpers/utils";
import { FERTILIZER_PRODUCT_TYPES } from "../../providers/YupProvider";

interface FertilizerProductDataFormProps {
  movementType: FertilizerStockMovementType;
  formik: FormikProps<FertilizerProduct>;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: FertilizerProduct;
  onCloseFilesForm?: () => void;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const FertilizerProductForm = (props: FertilizerProductDataFormProps) => {
  const {
    formik,
    selectedEditRow,
    isOpenFilesForm = false,
    onCloseFilesForm,
    onError,
  } = props;

  const { selectedABAccount } = useSession();
  const queryClient = useQueryClient();

  const onFetchFertilizerProductSuccess = (
    fertilizerProduct: FertilizerProduct
  ) => {
    formik.setValues(fertilizerProduct);
    // Update fertilization in array without refetch
    queryClient.setQueryData<FertilizerProduct[]>(
      ["fertilizerProducts", selectedABAccount?.id],
      (oldData) => updateItemOfList(oldData, fertilizerProduct, "id")
    );
  };

  const { isFetching: isUpdating } = useFetch<FertilizerProduct>({
    queryKey: ["fertilizerProduct", selectedEditRow?.id],
    enabled: !!selectedEditRow?.id,
    selected: selectedEditRow,
    onSuccess: onFetchFertilizerProductSuccess,
    onError,
  });

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      <FertilizerProductDataForm {...props} onError={onError} />
      <FertilizerProductMacronutrients {...props} />
      <FertilizerProductMicronutrients {...props} />
      <FertilizerProductHeavyMetals {...props} />
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t(
          "components.fertilizerProductForm.filesFormSubtitle"
        )}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
    </>
  );
};

export default FertilizerProductForm;

let searchWordTimeout: NodeJS.Timeout | null;
const FertilizerProductDataForm = (props: FertilizerProductDataFormProps) => {
  const { formik, forceExpanded, selectedEditRow, onError } = props;

  const { selectedABAccount } = useSession();

  const [searchWord, setSearchWord] = useState<string>("");

  const noOptionsText = useMemo(
    () =>
      searchWord.length === 0
        ? i18n.t("components.fertilizerProductForm.searchProductPlaceholder")
        : i18n.t("formErrors.notFoundResults"),
    [searchWord]
  );

  const {
    data: vadeFertilizProducts,
    isFetching: isFetchingRegisteredFertilizerProducts,
  } = useFetch<RegisteredFertilizerProduct[]>({
    queryKey: [
      "vadeFertilizProducts",
      selectedABAccount?.context?.id,
      searchWord,
    ],
    selected: { productSearch: { searchWord } },
    enabled: !!selectedABAccount && searchWord.length > 0,
    onError,
  });

  const { data: customFertilizProducts } = useFetch<
    RegisteredFertilizerProduct[]
  >({
    queryKey: ["customFertilizProducts", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    onError,
  });

  const measurementUnitsFilter = (data: MeasurementUnit[]) => {
    return data.filter((mUnit) =>
      MEASUREMENT_UNIT_CODES.includes(mUnit?.code || "0")
    );
  };

  const { data: measurementUnits, isFetching: isFetchingMeasurementUnits } =
    useFetch<MeasurementUnit[]>({
      queryKey: ["measurementUnits", selectedABAccount?.context?.id],
      filter: measurementUnitsFilter,
      enabled: !!selectedABAccount?.context?.id,
      onError,
    });

  const { data: fertilizerTypes, isFetching: isFetchingFertilizerTypes } =
    useFetch<FertilizerType[]>({
      queryKey: ["fertilizerTypes", selectedABAccount?.context?.id],
      enabled: !!selectedABAccount?.context?.id,
      onError,
    });

  const customProductFertilizerTypes = useMemo(
    () =>
      fertilizerTypes?.filter((type) => type.isOrganic() || type.isInorganic()),
    [fertilizerTypes]
  );

  const handleChangeFertilizerProductSearchWord = (
    event: any,
    value: string
  ) => {
    if (searchWordTimeout) {
      clearTimeout(searchWordTimeout);
      searchWordTimeout = null;
    }
    searchWordTimeout = setTimeout(() => {
      const searchWord = encodeURIComponent(value.trim());
      setSearchWord(searchWord);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  const handleChangeType = (type: FertilizerProductType) => {
    formik.setFieldValue("productType", type);
    formik.setFieldValue(
      "registeredFertilizerProduct",
      new RegisteredFertilizerProduct()
    );
    // If is registered
    if (type.code === "1") {
      formik.resetForm();
      if (selectedEditRow) formik.setFieldValue("id", selectedEditRow.id);
    }
  };

  const handleChangeFertilizerType = (type: FertilizerType) => {
    const registeredFertilizerProduct = customFertilizProducts?.find(
      (product) =>
        type.isOrganic() ? product.isOrganic() : product.isInorganic()
    );
    formik.setFieldValue(
      "registeredFertilizerProduct",
      registeredFertilizerProduct
    );
  };

  return (
    <BrioCard
      title={i18n.t("components.fertilizerProductForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="productType"
            label={i18n.t("components.fertilizerProductForm.typeLabel")}
            required
            options={FERTILIZER_PRODUCT_TYPES}
            optionLabelFieldName="name"
            onChange={handleChangeType}
          />
        </FormControl>
        <FormControl className="form-control">
          {formik.values.isRegistered() ? (
            <FormikAutocomplete
              formik={formik}
              name="registeredFertilizerProduct"
              label={i18n.t(
                "components.fertilizerProductForm.registeredProduct"
              )}
              placeholder={i18n.t(
                "components.fertilizerProductForm.registeredProductPlaceholder"
              )}
              noOptionsText={noOptionsText}
              options={vadeFertilizProducts || []}
              optionLabelFieldName="name"
              startIcon={Search}
              loading={isFetchingRegisteredFertilizerProducts}
              onInputChange={handleChangeFertilizerProductSearchWord}
            />
          ) : (
            <FormikTextField
              formik={formik}
              name="name"
              label={i18n.t("components.fertilizerProductForm.name")}
              required
            />
          )}
        </FormControl>

        <Collapse
          in={
            !formik.values.isRegistered() ||
            !!formik.values?.registeredFertilizerProduct
          }
        >
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikAutocomplete
                formik={formik}
                name="registeredFertilizerProduct.fertilizerType"
                label={i18n.t("components.fertilizerProductForm.type")}
                required
                optionLabelFieldName="name"
                options={
                  (formik.values.isRegistered()
                    ? fertilizerTypes
                    : customProductFertilizerTypes) || []
                }
                onChange={handleChangeFertilizerType}
                loading={isFetchingFertilizerTypes}
                disabled={formik.values.isRegistered()}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name={
                  formik.values.isRegistered()
                    ? "registeredFertilizerProduct.manufacturer"
                    : "owner"
                }
                label={i18n.t("components.fertilizerProductForm.owner")}
                disabled={formik.values.isRegistered()}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control-row">
              {formik.values.isRegistered() ? (
                <FormikTextField
                  className="form-input"
                  formik={formik}
                  name="registeredFertilizerProduct.code"
                  label={i18n.t("components.fertilizerProductForm.code")}
                  disabled
                />
              ) : (
                <FormikTextField
                  className="form-input"
                  formik={formik}
                  name="registerId"
                  label={i18n.t("components.fertilizerProductForm.registerId")}
                />
              )}
              <FormikSelect
                formik={formik}
                type="row"
                style={{ minWidth: "200px" }}
                name="measurementUnit"
                label={i18n.t(
                  "components.fertilizerProductForm.measurementUnit"
                )}
                required
                optionLabelFieldName="name"
                options={measurementUnits || []}
                isLoading={isFetchingMeasurementUnits}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};

const FertilizerProductMacronutrients = (
  props: FertilizerProductDataFormProps
) => {
  const { formik } = props;

  const fertilizerProductSustanceColumns: Column[] = useMemo(
    () => [
      {
        id: "macronutrient.name",
        label: i18n.t(
          "components.fertilizerProductMacronutrientForm.column.name"
        ),
        numeric: false,
      },
      {
        id: "percentage",
        label: i18n.t(
          "components.fertilizerProductMacronutrientForm.column.percentage"
        ),
        numeric: true,
        valueLabel: "unitValue",
        unitValue: formik.values.measurementUnit
          ? `%/${formik.values.measurementUnit.name}`
          : "%",
      },
    ],
    [formik.values]
  );

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedMacronutrient, setSelectedMacronutrient] =
    useState<FertilizerProductMacronutrient | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedMacronutrient(null);
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };

  const handleConfirmDialog = (selected: FertilizerProductMacronutrient) => {
    const updatedMacronutrients =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.macronutrients,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.macronutrients, selected, "idx");
    formik.setFieldValue("macronutrients", updatedMacronutrients);
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.macronutrients?.find(
      (fs: FertilizerProductMacronutrient) => fs.idx === idx
    );
    if (selected) {
      setSelectedMacronutrient(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    formik.setFieldValue(
      "macronutrients",
      deleteItemsOfListByField(formik.values.macronutrients, idxs, "idx")
    );
    closeDialog();
  };

  return (
    <Collapse in={!formik.values.isRegistered()}>
      <div className="grow-container">
        <TableBrioCard
          idx="idx"
          title={i18n.t(
            "components.fertilizerProductMacronutrientForm.tableTitle"
          )}
          headerText={i18n.t(
            "components.fertilizerProductMacronutrientForm.headerText"
          )}
          colums={fertilizerProductSustanceColumns}
          rows={formik.values?.macronutrients || []}
          selectedRows={selectedRowIds}
          emptyTableCard={false}
          optional
          onChangeSelectedRows={setSelectedRowIds}
          onClickAdd={handleClickAdd}
          onClickEdit={handleClikEdit}
          onClickDelete={handleClickDelete}
        />
        <DialogFertilizerProductMacronutrient
          open={isOpenDialog}
          selected={selectedMacronutrient}
          macronutrients={formik.values.macronutrients}
          productUnit={formik.values.measurementUnit}
          onConfirm={handleConfirmDialog}
          onClose={closeDialog}
        />
      </div>
    </Collapse>
  );
};

const FertilizerProductMicronutrients = (
  props: FertilizerProductDataFormProps
) => {
  const { formik } = props;

  const fertilizerProductSustanceColumns: Column[] = useMemo(
    () => [
      {
        id: "micronutrient.name",
        label: i18n.t(
          "components.fertilizerProductMicronutrientForm.column.name"
        ),
        numeric: false,
      },
      {
        id: "percentage",
        label: i18n.t(
          "components.fertilizerProductMicronutrientForm.column.percentage"
        ),
        numeric: true,
        valueLabel: "unitValue",
        unitValue: formik.values.measurementUnit
          ? `%/${formik.values.measurementUnit.name}`
          : "%",
      },
    ],
    [formik.values]
  );

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedMicronutrient, setSelectedMicronutrient] =
    useState<FertilizerProductMicronutrient | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedMicronutrient(null);
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };

  const handleConfirmDialog = (selected: FertilizerProductMicronutrient) => {
    const updatedMicronutrients =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.micronutrients,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.micronutrients, selected, "idx");
    formik.setFieldValue("micronutrients", updatedMicronutrients);
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.micronutrients?.find(
      (fs: FertilizerProductMicronutrient) => fs.idx === idx
    );
    if (selected) {
      setSelectedMicronutrient(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    formik.setFieldValue(
      "micronutrients",
      deleteItemsOfListByField(formik.values.micronutrients, idxs, "idx")
    );
    closeDialog();
  };

  return (
    <Collapse in={!formik.values.isRegistered()}>
      <div className="grow-container">
        <TableBrioCard
          idx="idx"
          title={i18n.t(
            "components.fertilizerProductMicronutrientForm.tableTitle"
          )}
          headerText={i18n.t(
            "components.fertilizerProductMicronutrientForm.headerText"
          )}
          colums={fertilizerProductSustanceColumns}
          rows={formik.values?.micronutrients || []}
          selectedRows={selectedRowIds}
          emptyTableCard={false}
          optional
          onChangeSelectedRows={setSelectedRowIds}
          onClickAdd={handleClickAdd}
          onClickEdit={handleClikEdit}
          onClickDelete={handleClickDelete}
        />
        <DialogFertilizerProductMicronutrient
          open={isOpenDialog}
          selected={selectedMicronutrient}
          micronutrients={formik.values.micronutrients}
          productUnit={formik.values.measurementUnit}
          onConfirm={handleConfirmDialog}
          onClose={closeDialog}
        />
      </div>
    </Collapse>
  );
};

const FertilizerProductHeavyMetals = (
  props: FertilizerProductDataFormProps
) => {
  const { formik } = props;

  const fertilizerProductSustanceColumns: Column[] = useMemo(
    () => [
      {
        id: "heavyMetal.name",
        label: i18n.t("components.fertilizerProductHeavyMetalForm.column.name"),
        numeric: false,
      },
      {
        id: "percentage",
        label: i18n.t(
          "components.fertilizerProductHeavyMetalForm.column.percentage"
        ),
        numeric: true,
        valueLabel: "unitValue",
        unitValue: formik.values.measurementUnit
          ? `%/${formik.values.measurementUnit.name}`
          : "%",
      },
    ],
    [formik.values]
  );

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedHeavyMetal, setSelectedHeavyMetal] =
    useState<FertilizerProductHeavyMetal | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedHeavyMetal(null);
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };

  const handleConfirmDialog = (selected: FertilizerProductHeavyMetal) => {
    const updatedHeavyMetals =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.heavyMetals,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.heavyMetals, selected, "idx");
    formik.setFieldValue("heavyMetals", updatedHeavyMetals);
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.heavyMetals?.find(
      (fs: FertilizerProductHeavyMetal) => fs.idx === idx
    );
    if (selected) {
      setSelectedHeavyMetal(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    formik.setFieldValue(
      "heavyMetals",
      deleteItemsOfListByField(formik.values.heavyMetals, idxs, "idx")
    );
    closeDialog();
  };

  return (
    <Collapse in={!formik.values.isRegistered()}>
      <div className="grow-container">
        <TableBrioCard
          idx="idx"
          title={i18n.t(
            "components.fertilizerProductHeavyMetalForm.tableTitle"
          )}
          headerText={i18n.t(
            "components.fertilizerProductHeavyMetalForm.headerText"
          )}
          colums={fertilizerProductSustanceColumns}
          rows={formik.values?.heavyMetals || []}
          selectedRows={selectedRowIds}
          emptyTableCard={false}
          optional
          onChangeSelectedRows={setSelectedRowIds}
          onClickAdd={handleClickAdd}
          onClickEdit={handleClikEdit}
          onClickDelete={handleClickDelete}
        />
        <DialogFertilizerProductHeavyMetal
          open={isOpenDialog}
          selected={selectedHeavyMetal}
          heavyMetals={formik.values.heavyMetals}
          productUnit={formik.values.measurementUnit}
          onConfirm={handleConfirmDialog}
          onClose={closeDialog}
        />
      </div>
    </Collapse>
  );
};
