import FertilizerType from "../../catalogue/FertilizerType";

class FBRegisteredFertilizerProduct {
  id?: number;
  name?: string;
  code?: string;
  shortName?: string;
  compositionName?: string;
  manufacturer?: string;
  organic?: boolean;
  mineral?: boolean;
  type?: string;
  fertilizerType?: FertilizerType;
  simple?: boolean;
  complexType?: string;
  nPercentage?: number;
  pPercentage?: number;
  kPercentage?: number;
  p2o5Percentage?: number;
  k2oPercentage?: number;
  nOrganicPercentage?: number;
  nNitricPercentage?: number;
  nAmmoniacalPercentage?: number;
  nUreicPercentage?: number;
  p2o5WaterSolublePercentage?: number;
  p2o5AmmoniumCitrateSolublePercentage?: number;
  k2oWaterSolublePercentage?: number;
  cOrganicPercentage?: number;
  organicMateriaPercentage?: number;
  caoPercentage?: number;
  mgoPercentage?: number;
  soPercentage?: number;
  cdPercentage?: number;
  cuPercentage?: number;
  pbPercentage?: number;
  niPercentage?: number;
  znPercentage?: number;
  hgPercentage?: number;
  crPercentage?: number;
  bPercentage?: number;
  coPercentage?: number;
  mnPercentage?: number;
  moPercentage?: number;
  fePercentage?: number;
  nitrificationInhibitor?: boolean;
  ureaseInhibitor?: boolean;
  inhibitorName?: string;
  aggregationState?: string;
  cover?: boolean;
  groupName?: string;
  groupRanking?: number;
  humidityPercentage?: number;
  observations?: string;
  percentageYear0?: number;
  percentageYear1?: number;
  percentageYear2?: number;

  constructor(
    id?: number,
    name?: string,
    code?: string,
    shortName?: string,
    compositionName?: string,
    manufacturer?: string,
    organic?: boolean,
    mineral?: boolean,
    type?: string,
    fertilizerType?: FertilizerType,
    simple?: boolean,
    complexType?: string,
    nPercentage?: number,
    pPercentage?: number,
    kPercentage?: number,
    p2o5Percentage?: number,
    k2oPercentage?: number,
    nOrganicPercentage?: number,
    nNitricPercentage?: number,
    nAmmoniacalPercentage?: number,
    nUreicPercentage?: number,
    p2o5WaterSolublePercentage?: number,
    p2o5AmmoniumCitrateSolublePercentage?: number,
    k2oWaterSolublePercentage?: number,
    cOrganicPercentage?: number,
    organicMateriaPercentage?: number,
    caoPercentage?: number,
    mgoPercentage?: number,
    soPercentage?: number,
    cdPercentage?: number,
    cuPercentage?: number,
    pbPercentage?: number,
    niPercentage?: number,
    znPercentage?: number,
    hgPercentage?: number,
    crPercentage?: number,
    bPercentage?: number,
    coPercentage?: number,
    mnPercentage?: number,
    moPercentage?: number,
    fePercentage?: number,
    nitrificationInhibitor?: boolean,
    ureaseInhibitor?: boolean,
    inhibitorName?: string,
    aggregationState?: string,
    cover?: boolean,
    groupName?: string,
    groupRanking?: number,
    humidityPercentage?: number,
    observations?: string,
    percentageYear0?: number,
    percentageYear1?: number,
    percentageYear2?: number
  ) {
    this.id = id;
    this.name = name;
    this.code = code;
    this.shortName = shortName;
    this.compositionName = compositionName;
    this.manufacturer = manufacturer;
    this.organic = organic;
    this.mineral = mineral;
    this.type = type;
    this.fertilizerType = fertilizerType;
    this.simple = simple;
    this.complexType = complexType;
    this.nPercentage = nPercentage;
    this.pPercentage = pPercentage;
    this.kPercentage = kPercentage;
    this.p2o5Percentage = p2o5Percentage;
    this.k2oPercentage = k2oPercentage;
    this.nOrganicPercentage = nOrganicPercentage;
    this.nNitricPercentage = nNitricPercentage;
    this.nAmmoniacalPercentage = nAmmoniacalPercentage;
    this.nUreicPercentage = nUreicPercentage;
    this.p2o5WaterSolublePercentage = p2o5WaterSolublePercentage;
    this.p2o5AmmoniumCitrateSolublePercentage =
      p2o5AmmoniumCitrateSolublePercentage;
    this.k2oWaterSolublePercentage = k2oWaterSolublePercentage;
    this.cOrganicPercentage = cOrganicPercentage;
    this.organicMateriaPercentage = organicMateriaPercentage;
    this.caoPercentage = caoPercentage;
    this.mgoPercentage = mgoPercentage;
    this.soPercentage = soPercentage;
    this.cdPercentage = cdPercentage;
    this.cuPercentage = cuPercentage;
    this.pbPercentage = pbPercentage;
    this.niPercentage = niPercentage;
    this.znPercentage = znPercentage;
    this.hgPercentage = hgPercentage;
    this.crPercentage = crPercentage;
    this.bPercentage = bPercentage;
    this.coPercentage = coPercentage;
    this.mnPercentage = mnPercentage;
    this.moPercentage = moPercentage;
    this.fePercentage = fePercentage;
    this.nitrificationInhibitor = nitrificationInhibitor;
    this.ureaseInhibitor = ureaseInhibitor;
    this.inhibitorName = inhibitorName;
    this.aggregationState = aggregationState;
    this.cover = cover;
    this.groupName = groupName;
    this.groupRanking = groupRanking;
    this.humidityPercentage = humidityPercentage;
    this.observations = observations;
    this.percentageYear0 = percentageYear0;
    this.percentageYear1 = percentageYear1;
    this.percentageYear2 = percentageYear2;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    this.shortName = obj?.shortName;
    this.compositionName = obj?.compositionName;
    this.manufacturer = obj?.manufacturer;
    this.organic = obj?.organic;
    this.mineral = obj?.mineral;
    this.type = obj?.type;
    this.fertilizerType = new FertilizerType().mapToClass(obj?.fertilizerType);
    this.simple = obj?.simple;
    this.complexType = obj?.complexType;
    this.nPercentage = obj?.nPercentage;
    this.pPercentage = obj?.pPercentage;
    this.kPercentage = obj?.kPercentage;
    this.p2o5Percentage = obj?.p2o5Percentage;
    this.k2oPercentage = obj?.k2oPercentage;
    this.nOrganicPercentage = obj?.nOrganicPercentage;
    this.nNitricPercentage = obj?.nNitricPercentage;
    this.nAmmoniacalPercentage = obj?.nAmmoniacalPercentage;
    this.nUreicPercentage = obj?.nUreicPercentage;
    this.p2o5WaterSolublePercentage = obj?.p2o5WaterSolublePercentage;
    this.p2o5AmmoniumCitrateSolublePercentage =
      obj?.p2o5AmmoniumCitrateSolublePercentage;
    this.k2oWaterSolublePercentage = obj?.k2oWaterSolublePercentage;
    this.cOrganicPercentage = obj?.cOrganicPercentage;
    this.organicMateriaPercentage = obj?.organicMateriaPercentage;
    this.caoPercentage = obj?.caoPercentage;
    this.mgoPercentage = obj?.mgoPercentage;
    this.soPercentage = obj?.soPercentage;
    this.cdPercentage = obj?.cdPercentage;
    this.cuPercentage = obj?.cuPercentage;
    this.pbPercentage = obj?.pbPercentage;
    this.niPercentage = obj?.niPercentage;
    this.znPercentage = obj?.znPercentage;
    this.hgPercentage = obj?.hgPercentage;
    this.crPercentage = obj?.crPercentage;
    this.bPercentage = obj?.bPercentage;
    this.coPercentage = obj?.coPercentage;
    this.mnPercentage = obj?.mnPercentage;
    this.moPercentage = obj?.moPercentage;
    this.fePercentage = obj?.fePercentage;
    this.nitrificationInhibitor = obj?.nitrificationInhibitor;
    this.ureaseInhibitor = obj?.ureaseInhibitor;
    this.inhibitorName = obj?.inhibitorName;
    this.aggregationState = obj?.aggregationState;
    this.cover = obj?.cover;
    this.groupName = obj?.groupName;
    this.groupRanking = obj?.groupRanking;
    this.humidityPercentage = obj?.humidityPercentage;
    this.observations = obj?.observations;
    this.percentageYear0 = obj?.percentageYear0;
    this.percentageYear1 = obj?.percentageYear1;
    this.percentageYear2 = obj?.percentageYear2;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.fertilizerType = this.fertilizerType?.mapToApiModel();
    return obj;
  }
}

export default FBRegisteredFertilizerProduct;
