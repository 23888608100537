import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";

import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Toaster } from "react-hot-toast";

import AppRouter from "./routes/AppRouter";

import YupProvider from "./providers/YupProvider";

// Config theme
import theme from "./config/configStyles";

// Config styles
import "./styles/styles.scss";

// Config Sentry
import "./config/configSentry";

// Config Firebase
import "./config/configFirebase";

// Config Hotjar
import "./config/configHotjar";

// Prepare moment
import "moment/locale/es";
import "moment/locale/ca";
import "moment/locale/gl";
import "moment/locale/eu";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/pt";
import "moment/locale/de";

// Config i18n
import i18n from "./config/configI18n";

import ErrorScreen from "./screens/ErrorScreen";

// Create a client, neccesarly for the react-query
const queryClient = new QueryClient();

// For google recaptcha enterprise
declare global {
  interface Window {
    grecaptcha: {
      enterprise: {
        ready: (callback: () => void) => void;
        execute: (
          siteKey: string,
          options: { action: string }
        ) => Promise<string>;
      };
    };
  }
}

function App() {
  useEffect(() => {
    try {
      // Solve the 100vh problem in mobile devices (100vh ignore mobile browser navigation bottom bars)
      const documentHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
      };
      window.addEventListener("resize", documentHeight);
      documentHeight();

      // Set tab title
      document.title = i18n.t("browserTabTitle");
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
      <ThemeProvider theme={theme}>
        <Toaster />
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <YupProvider>
              <AppRouter />
            </YupProvider>
          </LocalizationProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
