import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, useFormik, FormikTouched } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "../../config/configI18n";
import moment from "moment";

import { Tabs, Tab, FormGroup, FormControl, Collapse } from "@mui/material";
import { Search, Hive } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";
import FormikListItemSwitch from "../../components/elements/FormikListItemSwitch";
import TestBanner from "../../components/banners/TestBanner";
import SectionTutorial from "../../components/elements/SectionTutorial";
import AlertEmptyList from "../../components/AlertEmptyList";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";
import DialogFertilizationTreatedSector from "../../components/dialogs/DialogFertilizationTreatedSector";
import SiexUploadStatusBanner from "../../components/banners/SiexUploadStatusBanner";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import { Column } from "../../models/Generic";
import FertilizerProduct from "../../models/fertiliz/FertilizerProduct";
import Fertilization from "../../models/fertiliz/Fertilization";
import FertilizationGoodPracticeType from "../../models/catalogue/FertilizationGoodPracticeType";
import FertilizationType from "../../models/catalogue/FertilizationType";
import FertilizerApplicationMethod from "../../models/catalogue/FertilizerApplicationMethod";
import Crop from "../../models/crop/Crop";
import IrrigationSystemType from "../../models/catalogue/IrrigationSystemType";
import IrrigationWaterSourceType from "../../models/catalogue/IrrigationWaterSourceType";
import IrrigationGoodPractice from "../../models/catalogue/IrrigationGoodPractice";
import ManureTreatment from "../../models/catalogue/ManureTreatment";
import {
  IRRIGATION_MEASUREMENT_UNIT_LIST,
  IRRIGATION_ELEMENTS_MEASUREMENT_UNIT_LIST,
} from "../../models/fertiliz/Fertilization";
import Document from "../../models/files/Document";
import FertilizationSector from "../../models/fertiliz/FertilizationSector";
import AgroBusinessPhytosanitaryInvolvedEntity from "../../models/agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import Tool from "../../models/agrobusiness/Tool";
import SiexRequestItem from "../../models/siex/SiexRequestItem";

import {
  FertilizerStockMovementType,
  StockFertilizerTab,
} from "../../constants/enums";
import {
  SnackbarInterface,
  SectionTutorialInterface,
} from "../../constants/interfaces";
import { FBEntitiesTypes, FormMode } from "../../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";
import { PROTECTED_ROUTES } from "../../routes/routeNames";

import {
  addItemToListIfNotExistsByField,
  updateItemOfList,
  deleteItemsOfListByField,
} from "../../helpers/utils";

const calculateTotalTreatedArea = (fertilizedSectors?: FertilizationSector[]) =>
  fertilizedSectors?.reduce(
    (acc: number, fertilizedSector: FertilizationSector) =>
      acc + (fertilizedSector?.fertilizedArea || 0),
    0
  ) || 0;

const ExploitationFertilizationScreen = () => {
  const tutorialSteps: SectionTutorialInterface[] = [
    {
      label: i18n.t("fertilization.tutorial.createCrops.label"),
      description: i18n.t("fertilization.tutorial.createCrops.description"),
      nextBtnText: i18n.t("fertilization.tutorial.createCrops.nextBtnText"),
    },
    {
      label: i18n.t("fertilization.tutorial.createFertilizerProducts.label"),
      description: i18n.t(
        "fertilization.tutorial.createFertilizerProducts.description"
      ),
      nextBtnText: i18n.t(
        "fertilization.tutorial.createFertilizerProducts.nextBtnText"
      ),
    },
    {
      label: i18n.t("fertilization.tutorial.createWaterMixs.label"),
      description: i18n.t("fertilization.tutorial.createWaterMixs.description"),
      nextBtnText: i18n.t("fertilization.tutorial.createWaterMixs.nextBtnText"),
    },
    {
      label: i18n.t("fertilization.tutorial.createFertilizerApplicator.label"),
      description: i18n.t(
        "fertilization.tutorial.createFertilizerApplicator.description"
      ),
      nextBtnText: i18n.t(
        "fertilization.tutorial.createFertilizerApplicator.nextBtnText"
      ),
    },
  ];

  const fertilizationColumns: Column[] = [
    {
      id: "dateFormatted",
      label: i18n.t("fertilization.column.dateFormatted"),
      numeric: false,
    },
    {
      id: "crop.productNameWithVarieties",
      label: i18n.t("fertilization.column.crop"),
      numeric: false,
    },
    {
      id: "applicationMethod.name",
      label: i18n.t("fertilization.column.method"),
      numeric: false,
    },
    {
      id: "fertilizerProduct.name",
      label: i18n.t("fertilization.column.product"),
      numeric: false,
      valueLabel: "or",
      id2: "fertigationWaterMix.name",
    },
    {
      id: "dose",
      label: i18n.t("fertilization.column.dose"),
      numeric: true,
      valueLabel: "unit",
      unitFieldName: "doseUnit.name",
    },
    {
      id: "type.name",
      label: i18n.t("fertilization.column.type"),
      numeric: false,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [isLoadingTutorialData, setIsLoadingTutorialData] =
    useState<boolean>(true);
  const [isTutorialFinished, setIsTutorialFinished] = useState<boolean>(true);
  const [tutorialStep, setTutorialStep] = useState<number>(0);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const { data: cropsCount, isFetching: isFetchingCrops } = useFetch<number>({
    queryKey: ["cropsCount", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError: setSnackbarMsg,
  });

  const { data: fertilizerProductsCount, isFetching: isFetchingProducts } =
    useFetch<number>({
      queryKey: [
        "fertilizerProductsCount",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      enabled: !!selectedABAccount,
      onError: setSnackbarMsg,
    });

  const { data: fertilizationWaterMixCount, isFetching: isFetchingWaterMixs } =
    useFetch<number>({
      queryKey: [
        "fertilizationWaterMixCount",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      enabled: !!selectedABAccount,
      onError: setSnackbarMsg,
    });

  const {
    data: fertilizerApplicatorsCount,
    isFetching: isFetchingFertilizerApplicators,
  } = useFetch<number>({
    queryKey: [
      "fertilizerApplicatorsCount",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError: setSnackbarMsg,
  });

  const { data: fertilizations, isFetching } = useFetch<Fertilization[]>({
    queryKey: [
      "fertilizations",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError: setSnackbarMsg,
  });

  const selected = useMemo(
    () =>
      formMode === FormMode.EDIT && selectedRowIds.length === 1
        ? fertilizations?.find((f: Fertilization) => f.id === selectedRowIds[0])
        : undefined,
    [selectedRowIds, formMode, fertilizations]
  );

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  useEffect(() => {
    setIsLoadingTutorialData(
      isFetchingCrops ||
        isFetchingProducts ||
        isFetchingWaterMixs ||
        isFetchingFertilizerApplicators
    );
    return () => {
      setIsLoadingTutorialData(false);
    };
  }, [
    isFetchingCrops,
    isFetchingProducts,
    isFetchingWaterMixs,
    isFetchingFertilizerApplicators,
  ]);

  useEffect(() => {
    if (!isLoadingTutorialData && !isFetching) {
      let step = 0;
      tutorialSteps[0].completed = (cropsCount || 0) > 0;
      tutorialSteps[1].completed = (fertilizerProductsCount || 0) > 0;
      tutorialSteps[2].completed = (fertilizationWaterMixCount || 0) > 0;
      tutorialSteps[3].completed = (fertilizerApplicatorsCount || 0) > 0;

      if (!tutorialSteps[0].completed) step = 0;
      else if (!tutorialSteps[1].completed) step = 1;
      else if (!tutorialSteps[2].completed) step = 2;
      else if (!tutorialSteps[3].completed) step = 3;
      setIsTutorialFinished(
        (tutorialSteps[0].completed &&
          tutorialSteps[1].completed &&
          tutorialSteps[2].completed &&
          tutorialSteps[3].completed) ||
          false
      );
      setTutorialStep(step);
    }
  }, [isLoadingTutorialData, isFetching]);

  const validateForm = (values: Fertilization) => {
    const errors: any = {};
    if (!values.crop) errors.crop = i18n.t("formErrors.requiredField");
    if (!values.fertilizerProduct)
      errors.fertilizerProduct = i18n.t("formErrors.requiredField");
    if (!values.startDate)
      errors.startDate = i18n.t("formErrors.requiredField");
    if (!values.dose) errors.dose = i18n.t("formErrors.requiredField");
    if (!values.type) errors.type = i18n.t("formErrors.requiredField");
    if (!values.applicationMethod)
      errors.applicationMethod = i18n.t("formErrors.requiredField");
    if (isSiexActive) {
      if (!values.fertilizerProviderName)
        errors.fertilizerProviderName = i18n.t("formErrors.requiredField");
      if (!values.fertilizerProviderId)
        errors.fertilizerProviderId = i18n.t("formErrors.requiredField");
      if (!values.irrigationSystem)
        errors.irrigationSystem = i18n.t("formErrors.requiredField");
      if (!values.waterQuantity || values.waterQuantity <= 0)
        errors.waterQuantity = i18n.t("formErrors.requiredField");
      if (!values.waterQuantityUnit)
        errors.waterQuantityUnit = i18n.t("formErrors.requiredField");
      if (!values.fertilizedSectors || values.fertilizedSectors.length === 0)
        errors.fertilizedSectors = i18n.t("formErrors.requiredField");
    }
    return errors;
  };

  const handleSubmit = (values: Fertilization) => {
    switch (formMode) {
      case FormMode.CREATE:
        fertilizationCreateMutation.mutate({});
        break;
      case FormMode.EDIT:
        fertilizationEditMutation.mutate({});
        break;
      default:
        break;
    }
  };

  const formik = useFormik<Fertilization>({
    initialValues: new Fertilization(),
    validate: validateForm,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("fertilization.drawerTitleCreate")
      : i18n.t("fertilization.drawerTitleEdit");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  // Open drawer if is redirected from error siex register
  useEffect(() => {
    if (
      siexRegister &&
      siexRegister.entityClassName === FBEntitiesTypes.FERTILIZATION &&
      siexRegister.entityClassId
    ) {
      setSelectedRowIds([siexRegister.entityClassId]);
      openDrawer(FormMode.EDIT);
    }
  }, [siexRegister]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`, { state: { siexRegister } });
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const fertilizationCreateMutation = useCrud<Fertilization>({
    key: "postFertilization",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const fertilizationEditMutation = useCrud<Fertilization>({
    key: "putFertilization",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const fertilizationDeleteMutation = useCrud({
    key: "deleteFertilizations",
    values: fertilizations?.filter((f: Fertilization) =>
      selectedRowIds.includes(f?.id || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      // If there are errors, set respective tab
      setTabValue(
        errors.crop ||
          errors.type ||
          (Object.keys(errors).length === 1 && errors.fertilizedSectors)
          ? 0
          : 1
      ); //TODO: Generalize
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message:
          Object.keys(errors).length === 1 && errors.fertilizedSectors
            ? i18n.t("formErrors.addAtLeastOneSector")
            : i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    fertilizationDeleteMutation.mutate({});
  };

  const handleChangeTabValue = (tabValue: number) => {
    setTabValue(tabValue);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  const handleClickTutorialGo = () => {
    switch (tutorialStep) {
      case 0:
        return navigate(`/${PROTECTED_ROUTES.EXPLOITATION_CROPS}`);
      case 1:
        return navigate(
          `/${PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER}/${StockFertilizerTab.INVENTORY}`
        );
      case 2:
        return navigate(
          `/${PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER}/${StockFertilizerTab.MOVEMENTS}/${FertilizerStockMovementType.ELABORATION}`
        );
      case 3:
        return navigate(`/${PROTECTED_ROUTES.EXPLOITATION_INVOLVED_ENTITIES}`);
      default:
        break;
    }
  };

  const handleClickTutorialContinue = () => {
    if (tutorialStep < tutorialSteps.length - 1)
      setTutorialStep(tutorialStep + 1);
    else setIsTutorialFinished(true);
  };

  const handleClickTutorialBack = () => {
    if (tutorialStep > 0) setTutorialStep(tutorialStep - 1);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isLoadingTutorialData || isFetching} />
      <TestBanner />
      {!isTutorialFinished && (
        <SectionTutorial
          steps={tutorialSteps}
          activeStep={tutorialStep}
          onGo={handleClickTutorialGo}
          onNext={handleClickTutorialContinue}
          onBack={handleClickTutorialBack}
        />
      )}
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("fertilization.backAlertTitle")}
        contentText={i18n.t("fertilization.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("fertilization.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t(
          "fertilization.confirmDeleteAlertDialogDescription"
        )}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={fertilizationDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("fertilization.tableTitle")}
        headerText={i18n.t("fertilization.headerText")}
        colums={fertilizationColumns}
        rows={fertilizations}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Hive sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("fertilization.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("fertilization.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("fertilization.emptyTableBtnText")}
        addBtnVariant="contained"
        siexRows
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          fertilizationCreateMutation.isLoading ||
          fertilizationEditMutation.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <FertilizationForm
          formik={formik}
          tabValue={tabValue}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          selectedEditRow={selected}
          isSiexActive={isSiexActive}
          onChangeTabValue={handleChangeTabValue}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

interface FertilizationFormProps {
  formik: FormikProps<Fertilization>;
  tabValue: number;
  isSiexActive: boolean;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: Fertilization;
  onCloseFilesForm?: () => void;
  onSuccess?: (msg: string) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  onChangeTabValue: (tabValue: number) => void;
}
const FertilizationForm = (props: FertilizationFormProps) => {
  const {
    tabValue,
    formik,
    selectedEditRow,
    isSiexActive,
    isOpenFilesForm = false,
    onCloseFilesForm,
    onChangeTabValue,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const queryClient = useQueryClient();
  const location = useLocation();

  const siexRegister = location.state?.siexRegister as SiexRequestItem;

  const onFetchFertilizationSuccess = (fertilization: Fertilization) => {
    formik.setValues(fertilization);
    // Update fertilization in array without refetch
    queryClient.setQueryData<Fertilization[]>(
      ["fertilizations", selectedABAccount?.id, selectedABPartition?.id],
      (oldData) => updateItemOfList(oldData, fertilization, "id")
    );
  };

  const { isFetching: isUpdating } = useFetch<Fertilization>({
    queryKey: ["fertilization", selectedEditRow?.id],
    enabled: !!selectedEditRow?.id,
    selected: selectedEditRow,
    onSuccess: onFetchFertilizationSuccess,
    onError,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onChangeTabValue(newValue);
  };

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  const renderTab = () => {
    switch (tabValue) {
      case 0:
        return <TypologyTab {...props} />;
      case 1:
        return <MaterialTab {...props} />;
      default:
        return <div></div>;
    }
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      {isSiexActive && selectedEditRow && (
        <SiexUploadStatusBanner
          entityClassId={selectedEditRow.id || siexRegister.entityClassId}
          status={selectedEditRow.siexStatus}
          timestamp={selectedEditRow.siexSentOn}
          errorSiexRegister={siexRegister}
        />
      )}
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab
          className="tab"
          wrapped
          label={i18n.t("fertilization.tab.typology")}
        />
        <Tab
          className="tab"
          wrapped
          label={i18n.t("fertilization.tab.matherials")}
        />
      </Tabs>
      {renderTab()}
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("fertilization.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
    </>
  );
};

const TypologyTab = (props: FertilizationFormProps) => {
  return (
    <>
      <FertilizationCropForm {...props} />
      <TreatmentSectorsForm {...props} />
      <FertilizationTypeForm {...props} />
      <FertilizationGoodPracticesForm {...props} />
    </>
  );
};

const FertilizationCropForm = (props: FertilizationFormProps) => {
  const { formik, forceExpanded, onError } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const { data: crops, isFetching } = useFetch<Crop[]>({
    queryKey: ["crops", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError,
  });

  const handleClickNewOption = () =>
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_CROPS}`);

  const handleChangeCrop = (crop: Crop) => {
    formik.setFieldValue("crop", crop);
    formik.setFieldValue("fertilizedSectors", []);
  };

  return (
    <BrioCard
      title={i18n.t("fertilization.cropForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      {!isFetching && crops?.length === 0 && (
        <AlertEmptyList onClick={handleClickNewOption}>
          {i18n.t("fertilization.cropForm.emptyList")}
        </AlertEmptyList>
      )}
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="crop"
            label={i18n.t("fertilization.cropForm.crop")}
            placeholder={i18n.t("fertilization.cropForm.cropPlaceholder")}
            helperText={i18n.t("fertilization.cropForm.cropHelperText")}
            options={crops || []}
            optionLabelFieldName="productNameWithVarieties"
            required
            startIcon={Search}
            loading={isFetching}
            addNewOption
            onClickNewOption={handleClickNewOption}
            onChange={handleChangeCrop}
            disabled={formik.status === FormMode.EDIT}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="applicationArea"
            type="number"
            label={i18n.t("fertilization.cropForm.applicationArea")}
            valueUnit="ha"
            helperText={i18n.t(
              "fertilization.cropForm.applicationAreaHelperText"
            )}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const TreatmentSectorsForm = (props: FertilizationFormProps) => {
  const cropTreatedSectorColumns: Column[] = [
    {
      id: "sector.name",
      label: i18n.t("fertilization.treatedSectorForm.column.name"),
      numeric: false,
    },
    {
      id: "fertilizedArea",
      label: i18n.t("fertilization.treatedSectorForm.column.fertilizedArea"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "ha",
    },
  ];

  const { formik, isSiexActive, onError } = props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedFertilizerSector, setSelectedFertilizerSector] =
    useState<FertilizationSector | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedFertilizerSector(null);
  };

  const handleClickAdd = () => {
    if (!formik.values.crop && onError) {
      onError({
        severity: "info",
        message: i18n.t("formErrors.selectOneOption"),
      } as SnackbarInterface);
    } else {
      setFormMode(FormMode.CREATE);
      openDialog();
    }
  };

  // Only dialog to create or edit treated sector
  const handleConfirmDialog = (selected: FertilizationSector) => {
    const updatedTreatedSectors =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.fertilizedSectors,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.fertilizedSectors, selected, "idx");
    formik.setFieldValue("fertilizedSectors", updatedTreatedSectors);
    formik.setFieldValue(
      "applicationArea",
      calculateTotalTreatedArea(updatedTreatedSectors)
    );
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.fertilizedSectors?.find(
      (fs: FertilizationSector) => fs.idx === idx
    );
    if (selected) {
      setSelectedFertilizerSector(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    const updatedTreatedSectors = deleteItemsOfListByField(
      formik.values.fertilizedSectors,
      idxs,
      "idx"
    );
    formik.setFieldValue("fertilizedSectors", updatedTreatedSectors);
    formik.setFieldValue(
      "applicationArea",
      calculateTotalTreatedArea(updatedTreatedSectors)
    );
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        idx="idx"
        title={i18n.t("fertilization.treatedSectorForm.tableTitle")}
        headerText={i18n.t("fertilization.treatedSectorForm.headerText")}
        btnText={i18n.t("fertilization.treatedSectorForm.tableBtnText")}
        colums={cropTreatedSectorColumns}
        rows={formik.values.fertilizedSectors}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        optional={!isSiexActive}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClikEdit}
        onClickDelete={handleClickDelete}
      />
      <DialogFertilizationTreatedSector
        open={isOpenDialog}
        crop={formik.values.crop}
        selected={selectedFertilizerSector}
        fertilizerSectors={formik.values.fertilizedSectors}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};

const FertilizationTypeForm = (props: FertilizationFormProps) => {
  const { formik, forceExpanded, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: fertilizationTypes, isFetching: isFetchingFertilizationTypes } =
    useFetch<FertilizationType[]>({
      queryKey: ["fertilizationTypes", selectedABAccount?.context?.id],
      enabled: !!formik.values.crop,
      onError,
    });

  return (
    <BrioCard
      title={i18n.t("fertilization.typeForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="type"
            label={i18n.t("fertilization.typeForm.type")}
            optionLabelFieldName="name"
            options={fertilizationTypes || []}
            isLoading={isFetchingFertilizationTypes}
            required
            disabled={!formik.values.crop}
          />
        </FormControl>
        <FormControl className="form-control" sx={{ mb: "10px !important" }}>
          <FormikListItemSwitch
            formik={formik}
            name="sewageSludgeApplication"
            primaryText={i18n.t(
              "fertilization.typeForm.sewageSludgeApplication"
            )}
            secondaryText={i18n.t(
              "fertilization.typeForm.sewageSludgeApplicationSubtitle"
            )}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="wasteAuthorizationNumber"
            label={i18n.t("fertilization.typeForm.wasteAuthorizationNumber")}
            helperText={i18n.t(
              "fertilization.typeForm.wasteAuthorizationNumberHelperText"
            )}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const FertilizationGoodPracticesForm = (props: FertilizationFormProps) => {
  const { formik, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: goodPractices, isFetching } = useFetch<
    FertilizationGoodPracticeType[]
  >({
    queryKey: ["fertilizationGoodPractices", selectedABAccount?.context?.id],
    enabled: !!formik.values.crop,
    onError,
  });

  return (
    <BrioCard title={i18n.t("fertilization.goodPracticesForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="goodPractices"
            label={i18n.t("fertilization.goodPracticesForm.goodPractices")}
            multiple
            options={goodPractices || []}
            optionLabelFieldName="name"
            isLoading={isFetching}
            disabled={!formik.values.crop}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const MaterialTab = (props: FertilizationFormProps) => {
  const { isSiexActive } = props;

  return (
    <>
      <ApplicationMethodForm {...props} />
      <Collapse in={isSiexActive}>
        <div className="grow-container">
          <FertirrigationForm {...props} />
        </div>
      </Collapse>
      <MaterialForm {...props} />
    </>
  );
};

const ApplicationMethodForm = (props: FertilizationFormProps) => {
  const { formik, forceExpanded, isSiexActive, onError } = props;

  const { selectedABAccount } = useSession();
  const navigate = useNavigate();

  const fertirrigationFilter = (data: FertilizerApplicationMethod[]) => {
    return data.filter(
      (applicationMethod) =>
        applicationMethod.code &&
        (isSiexActive
          ? ["5", "6"].includes(applicationMethod.code)
          : !["5", "6"].includes(applicationMethod.code))
    );
  };

  const {
    data: fertilizerApplicationMethods,
    isFetching: isFetchingFertilizerApplicationMethods,
  } = useFetch<FertilizerApplicationMethod[]>({
    queryKey: ["fertilizerApplicationMethods", selectedABAccount?.context?.id],
    filter: fertirrigationFilter,
    enabled: !!selectedABAccount,
    onError,
  });

  const { data: fertilizerProducts, isFetching: isFetchingFertilizerProducts } =
    useFetch<FertilizerProduct[]>({
      queryKey: ["fertilizerProducts", selectedABAccount?.id],
      enabled: !!selectedABAccount,
      onError,
    });

  const { data: manureTreatments, isFetching: isFetchingManureTreatments } =
    useFetch<ManureTreatment[]>({
      queryKey: ["manureTreatments", selectedABAccount?.id],
      enabled: !!selectedABAccount,
      onError,
    });

  /*   const { data: fertilizerProductStock, isFetching: isFetchingStock } =
    useFetch<any>({
      queryKey: [
        "fertilizerProductStock",
        formik.values?.fertilizerProduct?.id,
      ],
      enabled: !!formik.values?.fertilizerProduct?.id,
      selected: formik.values?.fertilizerProduct,
      onError,
    }); */

  /*   const { data: fertilizationWaterMixs, isFetching: isFetchingWaterMixs } =
    useFetch<FertigationWaterMix[]>({
      queryKey: [
        "fertilizationWaterMixs",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      enabled:
        formik.values.applicationOrigin?.code ===
        FertilizationOriginType.MIXTURE,
      onError,
    }); */

  // Only positive numbers
  const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
    const quantity = Number(event.target.value);
    formik.setFieldValue("dose", Math.abs(quantity || 0));
  };

  const handleChangeProduct = (option: FertilizerProduct) => {
    if (option) {
      formik.setFieldValue("fertilizerProduct", option);
      formik.setFieldValue("dose", 0);
      formik.setFieldValue("beforeDose", 0);
      if (option.owner)
        formik.setFieldValue("fertilizerProviderName", option.owner);
    }
  };

  // If editing, ignore saved quantity to calculate the stock
  /*   const resultStock =
    Math.round(
      ((fertilizerProductStock?.stock || 0) -
        (formik.values?.dose || 0) +
        (formik.status === FormMode.EDIT
          ? formik.values?.beforeDose || 0
          : 0)) *
        100
    ) / 100; */

  const handleClickNewProduct = () =>
    navigate(
      `/${PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER}/${StockFertilizerTab.INVENTORY}/edit`
    );

  /*   const handleClickNewWaterMix = () =>
    navigate(
      `/${PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER}/${StockFertilizerTab.MOVEMENTS}/${FertilizerStockMovementType.ELABORATION}`
    ); */

  const handleChangeStartDate = (date: moment.Moment | null) => {
    const dateStr = date?.format("YYYY-MM-DD");
    formik.setFieldValue("startDate", dateStr);
    formik.setFieldValue("endDate", dateStr);
  };
  /*   const isProduct =
    formik.values.applicationOrigin?.code === FertilizationOriginType.PRODUCT;
  const isMixture =
    formik.values.applicationOrigin?.code === FertilizationOriginType.MIXTURE;
  const disableDose =
    (isProduct && !formik.values.fertilizerProduct) ||
    (isMixture && !formik.values.fertigationWaterMix); */
  return (
    <BrioCard
      title={i18n.t("fertilization.applicationMethodForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        {/*         <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="applicationOrigin"
            label={i18n.t(
              "fertilization.applicationMethodForm.applicationOrigin"
            )}
            options={APPLICATION_ORIGIN_LIST}
            optionLabelFieldName="name"
            required
          />
        </FormControl> */}
        <FormControl variant="outlined" className="form-control">
          {!isFetchingFertilizerProducts &&
            fertilizerProducts?.length === 0 && (
              <AlertEmptyList onClick={handleClickNewProduct}>
                {i18n.t("fertilization.applicationMethodForm.emptyListProduct")}
              </AlertEmptyList>
            )}
          <FormikAutocomplete
            formik={formik}
            name="fertilizerProduct"
            label={i18n.t(
              "fertilization.applicationMethodForm.fertilizerProduct"
            )}
            placeholder={i18n.t(
              "fertilization.applicationMethodForm.fertilizerProductPlaceholder"
            )}
            options={fertilizerProducts || []}
            optionLabelFieldName="name"
            required
            startIcon={Search}
            loading={isFetchingFertilizerProducts}
            addNewOption
            onClickNewOption={handleClickNewProduct}
            onChange={handleChangeProduct}
          />
        </FormControl>
        {/*             {isMixture && (
              <FormControl variant="outlined" className="form-control">
                {!isFetchingWaterMixs &&
                  fertilizationWaterMixs?.length === 0 && (
                    <AlertEmptyList onClick={handleClickNewWaterMix}>
                      {i18n.t(
                        "fertilization.applicationMethodForm.emptyListWaterMix"
                      )}
                    </AlertEmptyList>
                  )}
                <FormikAutocomplete
                  formik={formik}
                  name="fertigationWaterMix"
                  label={i18n.t(
                    "fertilization.applicationMethodForm.fertigationWaterMix"
                  )}
                  placeholder={i18n.t(
                    "fertilization.applicationMethodForm.fertigationWaterMixPlaceholder"
                  )}
                  options={fertilizationWaterMixs || []}
                  optionLabelFieldName="name"
                  required
                  startIcon={Search}
                  loading={isFetchingWaterMixs}
                  addNewOption
                  onClickNewOption={handleClickNewWaterMix}
                />
              </FormControl>
            )} */}
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            //className={`form-input${isProduct ? "-row-40" : ""}`}
            className="form-input"
            formik={formik}
            name="dose"
            label={i18n.t("fertilization.applicationMethodForm.dose")}
            type="number"
            required
            valueUnit={
              formik.values.fertilizerProduct
                ? `${formik.values.fertilizerProduct?.measurementUnit?.name}/ha`
                : ""
            }
            disabled={!formik.values.fertilizerProduct}
            onChange={handleChangeQuantity}
          />
          {/*           <TextField
            id="resultStock"
            name="resultStock"
            disabled
            className="form-input-row-60"
            variant="outlined"
            type="number"
            label={i18n.t("fertilization.applicationMethodForm.resultStock")}
            InputProps={{
              endAdornment: isFetchingStock ? (
                <CircularProgress size={20} />
              ) : (
                <InputAdornment position="end">
                  {fertilizerProductStock?.unit || ""}
                </InputAdornment>
              ),
            }}
            value={resultStock}
          /> */}
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <DatePicker
            className="form-input-row"
            format="DD/MM/YYYY"
            label={`${i18n.t(
              "fertilization.applicationMethodForm.startDate"
            )}*`}
            value={
              formik.values?.startDate
                ? moment(formik.values.startDate, "YYYY-MM-DD")
                : null
            }
            onChange={handleChangeStartDate}
          />
          <DatePicker
            className="form-input-row"
            format="DD/MM/YYYY"
            label={i18n.t("fertilization.applicationMethodForm.endDate")}
            value={
              formik.values?.endDate
                ? moment(formik.values.endDate, "YYYY-MM-DD")
                : null
            }
            onChange={(date) =>
              formik.setFieldValue("endDate", date?.format("YYYY-MM-DD"))
            }
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="applicationMethod"
            label={i18n.t(
              "fertilization.applicationMethodForm.applicationMethod"
            )}
            options={fertilizerApplicationMethods || []}
            optionLabelFieldName="name"
            required
            isLoading={isFetchingFertilizerApplicationMethods}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="manureTreatment"
            label={i18n.t(
              "fertilization.applicationMethodForm.manureTreatment"
            )}
            options={manureTreatments || []}
            optionLabelFieldName="name"
            isLoading={isFetchingManureTreatments}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            className="form-input-row-60"
            formik={formik}
            name="fertilizerProviderName"
            label={i18n.t("fertilization.applicationMethodForm.providerName")}
            placeholder={i18n.t(
              "fertilization.applicationMethodForm.providerNamePlaceholder"
            )}
            required={isSiexActive}
          />
          <FormikTextField
            className="form-input-row-40"
            formik={formik}
            name="fertilizerProviderId"
            label={i18n.t("fertilization.applicationMethodForm.providerId")}
            placeholder={i18n.t(
              "fertilization.applicationMethodForm.providerIdPlaceholder"
            )}
            required={isSiexActive}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const MaterialForm = (props: FertilizationFormProps) => {
  const { formik, onError } = props;

  const { selectedABAccount, selectedABPartition } = useSession();
  const navigate = useNavigate();

  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] = useState<
    "applicator" | "tool" | false
  >(false);

  const { data: applicators, isFetching: isFetchingApplicators } = useFetch<
    AgroBusinessPhytosanitaryInvolvedEntity[]
  >({
    queryKey: [
      "fertilizerApplicatorInvolvedEntities",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
    onError,
  });

  const { data: tools, isFetching: isFetchingTools } = useFetch<Tool[]>({
    queryKey: ["tools", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError,
  });

  const openRedirectAlertDialog = (type: "applicator" | "tool") =>
    setIsOpenRedirectAlertDialog(type);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);

  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  const handleClickNewApplicator = () => openRedirectAlertDialog("applicator");
  const handleClickNewTool = () => openRedirectAlertDialog("tool");

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(
      `/${
        isOpenRedirectAlertDialog === "applicator"
          ? PROTECTED_ROUTES.EXPLOITATION_INVOLVED_ENTITIES
          : PROTECTED_ROUTES.EXPLOITATION_TOOLS
      }`
    );
  };

  return (
    <BrioCard
      title={i18n.t("fertilization.materialForm.title")}
      defaultExpanded
    >
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t("fertilization.redirectAlertDialogTitle")}
        contentText={
          isOpenRedirectAlertDialog === "applicator"
            ? i18n.t("fertilization.redirectToApplicators")
            : i18n.t("fertilization.redirectToTools")
        }
        open={!!isOpenRedirectAlertDialog}
        onCancel={cancelRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          {!isFetchingApplicators && applicators?.length === 0 && (
            <AlertEmptyList onClick={handleClickNewApplicator}>
              {i18n.t("fertilization.materialForm.emptyListApplicator")}
            </AlertEmptyList>
          )}
          <FormikAutocomplete
            formik={formik}
            name="applicator"
            label={i18n.t("fertilization.materialForm.applicator")}
            optionLabelFieldName="entity.nameAndSurname"
            options={applicators || []}
            loading={isFetchingApplicators}
            addNewOption
            onClickNewOption={handleClickNewApplicator}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          {!isFetchingTools && tools?.length === 0 && (
            <AlertEmptyList onClick={handleClickNewTool}>
              {i18n.t("fertilization.materialForm.emptyListTool")}
            </AlertEmptyList>
          )}
          <FormikAutocomplete
            formik={formik}
            name="tool"
            label={i18n.t("fertilization.materialForm.tool")}
            optionLabelFieldName="name"
            options={tools || []}
            loading={isFetchingTools}
            addNewOption
            onClickNewOption={handleClickNewTool}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const FertirrigationForm = (props: FertilizationFormProps) => {
  const { formik, isSiexActive, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: irrigationTypes, isFetching: isFetchingIWT } = useFetch<
    IrrigationSystemType[]
  >({
    queryKey: ["irrigationSystemTypes", selectedABAccount?.context?.id],
    enabled: isSiexActive,
    onError,
  });

  const { data: irrigationWaterSourceTypes, isFetching: isFetchingIWST } =
    useFetch<IrrigationWaterSourceType[]>({
      queryKey: ["irrigationWaterSourceTypes", selectedABAccount?.context?.id],
      enabled: isSiexActive,
      onError,
    });

  const { data: irrigationGoodPractices, isFetching: isFetchingIGPS } =
    useFetch<IrrigationGoodPractice[]>({
      queryKey: ["irrigationGoodPractices", selectedABAccount?.context?.id],
      enabled: isSiexActive,
      onError,
    });

  return (
    <BrioCard
      title={i18n.t("fertilization.irrigationForm.title")}
      defaultExpanded
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="irrigationSystem"
            label={i18n.t("fertilization.irrigationForm.irrigationSystem")}
            options={irrigationTypes || []}
            optionLabelFieldName="name"
            isLoading={isFetchingIWT}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            className="form-input-row-70"
            formik={formik}
            name="waterQuantity"
            type="number"
            label={i18n.t("fertilization.irrigationForm.waterQuantity")}
          />
          <FormikAutocomplete
            className="form-input-row-30"
            formik={formik}
            name="waterQuantityUnit"
            label={i18n.t("fertilization.irrigationForm.waterQuantityUnit")}
            options={IRRIGATION_MEASUREMENT_UNIT_LIST}
            optionLabelFieldName="name"
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            multiple
            formik={formik}
            name="waterSourceTypes"
            label={i18n.t(
              "fertilization.irrigationWaterSourceForm.waterSourceTypes"
            )}
            optionLabelFieldName="name"
            options={irrigationWaterSourceTypes || []}
            isLoading={isFetchingIWST}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            className="form-input"
            formik={formik}
            name="irrigationCounterId"
            type="text"
            label={i18n.t("fertilization.irrigationForm.irrigationCounterId")}
          />
        </FormControl>
        <FormControl className="form-control" sx={{ mb: "10px !important" }}>
          <FormikListItemSwitch
            formik={formik}
            name="irrigationGoodPracticesDeclared"
            primaryText={i18n.t(
              "fertilization.irrigationGoodPracticesForm.irrigationGoodPracticesDeclared"
            )}
            secondaryText={i18n.t(
              "fertilization.irrigationGoodPracticesForm.irrigationGoodPracticesDeclaredSubtitle"
            )}
          />
        </FormControl>
        <Collapse
          in={formik.values.irrigationGoodPracticesDeclared}
          sx={{ maxWidth: "100%" }}
        >
          <div className="grow-container">
            <FormControl
              variant="outlined"
              className="form-control"
              sx={{ mb: "20px !important" }}
            >
              <FormikSelect
                multiple
                formik={formik}
                name="irrigationGoodPractices"
                label={i18n.t(
                  "fertilization.irrigationGoodPracticesForm.goodPractices"
                )}
                optionLabelFieldName="description"
                options={irrigationGoodPractices || []}
                isLoading={isFetchingIGPS}
              />
            </FormControl>
          </div>
        </Collapse>
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            className="form-input-row-70"
            formik={formik}
            name="fertigationNitrogenDose"
            type="number"
            label={i18n.t(
              "fertilization.irrigationForm.fertigationNitrogenDose"
            )}
          />
          <FormikAutocomplete
            className="form-input-row-30"
            formik={formik}
            name="fertigationNitrogenDoseUnit"
            label={i18n.t(
              "fertilization.irrigationForm.fertigationNitrogenDoseUnit"
            )}
            options={IRRIGATION_ELEMENTS_MEASUREMENT_UNIT_LIST}
            optionLabelFieldName="name"
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control-row">
          <FormikTextField
            className="form-input-row-70"
            formik={formik}
            name="fertigationPhosphorusDose"
            type="number"
            label={i18n.t(
              "fertilization.irrigationForm.fertigationPhosphorusDose"
            )}
          />
          <FormikAutocomplete
            className="form-input-row-30"
            formik={formik}
            name="fertigationPhosphorusDoseUnit"
            label={i18n.t(
              "fertilization.irrigationForm.fertigationPhosphorusDoseUnit"
            )}
            options={IRRIGATION_ELEMENTS_MEASUREMENT_UNIT_LIST}
            optionLabelFieldName="name"
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

export default ExploitationFertilizationScreen;
