import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import i18n from "../config/configI18n";

import {
  Drawer,
  List,
  ListItem,
  Collapse,
  IconButton,
  Toolbar,
  useTheme,
  useMediaQuery,
  Tooltip,
  Fade,
} from "@mui/material";
import {
  MenuOpen,
  ListAlt,
  ExpandLess,
  ExpandMore,
  CorporateFare,
  Work,
  Business,
  Build,
  Water,
  Dashboard,
  Grass,
  PestControl,
  Cloud,
  Troubleshoot,
  Inventory,
  GasMeter,
  Science,
  Hive,
  Opacity,
  AccountTree,
  Engineering,
  InsertDriveFile,
  AutoFixHigh,
  ManageAccounts,
  TrackChanges,
  Grade,
  MenuBook,
  Link,
  Receipt,
  CircleNotifications,
  FileDownload,
} from "@mui/icons-material";

import AdvisorAccountLabel from "./elements/AdvisorAccountLabel";

import { useAuth } from "../hooks/useAuth";
import { useSession } from "../hooks/useSession";
import useFetch from "../hooks/useFetch";

import AgroBusinessAccount from "../models/account/AgroBusinessAccount";

import { PROTECTED_ROUTES } from "../routes/routeNames";
import { MenuOption } from "../constants/interfaces";
import { FBPersonRole } from "../constants/enums";
import { REFRESH_PHYTO_RECIPES_NOTIFICATION_INTERVAL } from "../constants/constants";

const getSidebarOptions = (selectedABAccount: AgroBusinessAccount | null) => {
  const isSiexSection = selectedABAccount?.siexEnabled || false;

  return [
    {
      id: "exploit-info",
      label: i18n.t("components.sidebar.exploitInfo"),
      icon: <CorporateFare />,
      isSiexSection,
      children: [
        {
          id: "exploit-data",
          label: i18n.t("components.sidebar.exploitData"),
          icon: <ListAlt />,
          route: PROTECTED_ROUTES.EXPLOITATION_DATA,
          isSiexSection,
        },
        {
          id: "exploit-leadership",
          label: i18n.t("components.sidebar.exploitLeadership"),
          icon: <Work />,
          route: PROTECTED_ROUTES.EXPLOITATION_LEADERSHIP,
          isSiexSection,
        },
        {
          id: "exploit-facility",
          label: i18n.t("components.sidebar.exploitFacility"),
          icon: <Business />,
          route: PROTECTED_ROUTES.EXPLOITATION_FACILITIES,
          isSiexSection,
        },
        {
          id: "exploit-tools",
          label: i18n.t("components.sidebar.exploitTools"),
          icon: <Build />,
          route: PROTECTED_ROUTES.EXPLOITATION_TOOLS,
          isSiexSection,
        },
        {
          id: "exploit-water-sources",
          label: i18n.t("components.sidebar.exploitWaterSources"),
          icon: <Water />,
          route: PROTECTED_ROUTES.EXPLOITATION_WATER_SOURCES,
          isSiexSection,
        },
        {
          id: "exploit-sectors",
          label: i18n.t("components.sidebar.exploitSectors"),
          icon: <Dashboard />,
          route: PROTECTED_ROUTES.EXPLOITATION_SECTORS,
          isSiexSection,
        },
        {
          id: "exploit-crops",
          label: i18n.t("components.sidebar.exploitCrops"),
          icon: <Grass />,
          route: PROTECTED_ROUTES.EXPLOITATION_CROPS,
          isSiexSection,
        },
        {
          id: "exploit-partitions",
          label: i18n.t("components.sidebar.exploitPartitions"),
          icon: <AccountTree />,
          route: PROTECTED_ROUTES.EXPLOITATION_PARTITIONS,
        },
      ],
    },
    {
      id: "exploit-permissions",
      label: i18n.t("components.sidebar.exploitPermissions"),
      icon: <ManageAccounts />,
      route: PROTECTED_ROUTES.EXPLOITATIONS_PERMISSIONS,
      hidden: true,
    },
    {
      id: "exploit-irrigation-pluviometry",
      label: i18n.t("components.sidebar.exploitIrrigationPluviometry"),
      icon: <Opacity />,
      children: [
        {
          id: "exploit-irrigation",
          label: i18n.t("components.sidebar.exploitIrrigation"),
          icon: <Opacity />,
          route: PROTECTED_ROUTES.EXPLOITATION_IRRIGATIONS,
        },
        {
          id: "exploit-pluviometry",
          label: i18n.t("components.sidebar.exploitPluviometry"),
          icon: <Cloud />,
          route: PROTECTED_ROUTES.EXPLOITATION_PLUVIOMETRY,
        },
      ],
    },
    {
      id: "exploit-involved-entities",
      label: i18n.t("components.sidebar.exploitInvolvedEntities"),
      icon: <Engineering />,
      route: PROTECTED_ROUTES.EXPLOITATION_INVOLVED_ENTITIES,
    },
    {
      id: "exploit-fertilization",
      label: i18n.t("components.sidebar.exploitFertilization"),
      icon: <Hive />,
      children: [
        {
          id: "exploit-fertilizations",
          label: i18n.t("components.sidebar.exploitFertilizations"),
          icon: <Hive />,
          route: PROTECTED_ROUTES.EXPLOITATION_FERTILIZATION,
        },
        {
          id: "exploit-fertilization-plans",
          label: i18n.t("components.sidebar.exploitFertilizationPlans"),
          icon: <TrackChanges />,
          route: PROTECTED_ROUTES.EXPLOITATION_FERTILIZATION_PLANS,
        },
      ],
    },
    {
      id: "exploit-treatments-phytoentities",
      label: i18n.t("components.sidebar.exploitTreatmentsPhytoentities"),
      icon: <PestControl />,
      route: PROTECTED_ROUTES.EXPLOITATION_TREATMENTS,
    },
    {
      id: "exploit-stock",
      label: i18n.t("components.sidebar.exploitStock"),
      icon: <Inventory />,
      children: [
        {
          id: "exploit-stock-fertilizer",
          label: i18n.t("components.sidebar.exploitStockFertilizer"),
          icon: <GasMeter />,
          route: PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER,
        },
        {
          id: "exploit-stock-phytosanitary",
          label: i18n.t("components.sidebar.exploitStockPhytosanitary"),
          icon: <Science />,
          route: PROTECTED_ROUTES.EXPLOITATION_STOCK_PHYTOSANITARY,
        },
      ],
    },
    {
      id: "exploit-analysis",
      label: i18n.t("components.sidebar.exploitAnalysis"),
      icon: <Troubleshoot />,
      route: PROTECTED_ROUTES.EXPLOITATION_ANALYSIS,
    },
    {
      id: "exploit-files",
      label: i18n.t("components.sidebar.exploitFiles"),
      icon: <InsertDriveFile />,
      route: PROTECTED_ROUTES.EXPLOITATION_FILES,
    },
    {
      id: "exploit-offers",
      label: i18n.t("components.sidebar.exploitOffers"),
      icon: <Grade />,
      route: PROTECTED_ROUTES.EXPLOITATION_OFFERS,
    },
    {
      id: "exploit-recipes",
      label: i18n.t("components.sidebar.exploitRecipes"),
      icon: <Receipt />,
      route: PROTECTED_ROUTES.EXPLOITATION_RECIPES,
    },
    {
      id: "vademecum",
      label: i18n.t("components.sidebar.vademecum"),
      icon: <MenuBook />,
      children: [
        {
          id: "vademecum-phytosanitary",
          label: i18n.t("components.sidebar.vademecumPhytosanitary"),
          icon: <Science />,
          route: PROTECTED_ROUTES.VADEMECUM_PHYTO,
        },
        {
          id: "vademecum-fertilizer",
          label: i18n.t("components.sidebar.vademecumFertilizer"),
          icon: <GasMeter />,
          route: PROTECTED_ROUTES.VADEMECUM_FERTILIZER,
        },
      ],
    },
    {
      id: "export",
      label: i18n.t("components.sidebar.export"),
      icon: <FileDownload />,
      route: PROTECTED_ROUTES.EXPLOITATION_EXPORT_DATA,
    },
  ];
};

interface Props {
  isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  options?: MenuOption[];
  onClose?(): any;
}

const Sidebar = ({ isOpen = false, setIsOpen, onClose }: Props) => {
  const { user } = useAuth();
  const { selectedABAccount } = useSession();

  const [options, setOptions] = useState<MenuOption[]>([]);

  const { data: unreadNotifications } = useFetch<Notification[]>({
    queryKey: ["notifications", selectedABAccount?.id],
    refetchInterval: REFRESH_PHYTO_RECIPES_NOTIFICATION_INTERVAL,
    enabled: !!selectedABAccount,
  });

  // Show permissions option only for admins
  useEffect(() => {
    const menuOptions = getSidebarOptions(selectedABAccount);
    setOptions(
      menuOptions.map((option) => {
        switch (option.id) {
          case "exploit-permissions":
            return { ...option, hidden: user?.role !== FBPersonRole.ADMIN };
          case "exploit-recipes":
            return {
              ...option,
              nNotifications: unreadNotifications?.length || 0,
            };

          default:
            return option;
        }
      })
    );
  }, [user, selectedABAccount, unreadNotifications]);

  return (
    <>
      <Drawer
        className="sidebar-temp-none"
        variant="temporary"
        anchor="left"
        ModalProps={{ keepMounted: true }}
        open={isOpen}
        onClose={onClose}
        PaperProps={{ className: "sidebar-temp" }}
      >
        <DrawerContent
          isOpen={isOpen}
          options={options}
          setIsOpen={setIsOpen}
          onClose={onClose}
        />
      </Drawer>

      <div className="sidebar-perm">
        <Drawer
          variant="permanent"
          anchor="left"
          open
          PaperProps={{ className: "sidebar-perm" }}
        >
          <DrawerContent isOpen options={options} />
        </Drawer>
      </div>
    </>
  );
};

export default Sidebar;

const DrawerContent = ({
  isOpen = true,
  setIsOpen,
  onClose,
  options,
}: Props) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("large"));
  const location = useLocation();
  const { user } = useAuth();
  const { selectedABAccount } = useSession();
  const navigate = useNavigate();

  const vinculationNeeded = useMemo(
    () =>
      !user?.person?.email ||
      user.person.email.length === 0 ||
      !user?.person?.whatsappNumber,
    [user]
  );

  const sidebarOptions = getSidebarOptions(selectedABAccount);

  const [isExpanded, setIsExpanded] = useState<string[]>([]);

  // Expand sidebar options when the user navigates to a child route
  useEffect(() => {
    const pathId = sidebarOptions.find((option) => {
      return option?.children?.find((child) =>
        location.pathname.includes(child?.route || "-1")
      );
    })?.id;
    if (pathId && !isExpanded.includes(pathId))
      setIsExpanded((prevExpanded) => [...prevExpanded, pathId]);
  }, [location.pathname]);

  useEffect(() => {
    if (setIsOpen) {
      if (isDesktop && !isOpen) setIsOpen(true);
      else if (!isDesktop && isOpen) setIsOpen(false);
    }
  }, [isDesktop]);

  const handleOnCollapse = (id: string) => {
    if (isExpanded.includes(id)) {
      setIsExpanded(isExpanded.filter((item) => item !== id));
    } else setIsExpanded([...isExpanded, id]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    option: MenuOption
  ) => {
    if (option.children && option.children.length > 0) {
      event.preventDefault();
      handleOnCollapse(option.id);
      if (option.children.length === 1 && option.children[0].route)
        navigate(option.children[0].route);
    } else if (setIsOpen) setIsOpen(false);
  };

  return (
    <div className="drawer-content">
      <List component="nav">
        {!isDesktop && (
          <ListItem disablePadding>
            <IconButton onClick={onClose}>
              <MenuOpen />
            </IconButton>
          </ListItem>
        )}

        {isDesktop && <Toolbar />}

        <AdvisorAccountLabel
          isVisible={!isDesktop}
          style={{ marginBottom: "8px" }}
        />

        {options?.map((option) => (
          <div
            key={option.id}
            className={
              option.id === "exploit-info" && option.isSiexSection
                ? "siex-section"
                : ""
            }
            style={{ display: option.hidden ? "none" : "block" }}
          >
            <NavLink
              key={option.id}
              to={option.route || ""}
              className={({ isActive }) =>
                `list-item-container${
                  isActive && !option.children ? "-active" : ""
                }`
              }
              onClick={(event) => handleClick(event, option)}
            >
              <div className="item-container">
                <div className="left">
                  <div className="icon">{option.icon}</div>
                  <span>{option.label}</span>
                </div>
                <div className="right">
                  {option.children && option.children.length > 0 && (
                    <div className="icon">
                      {isExpanded.includes(option.id) ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </div>
                  )}
                  <Fade
                    in={
                      !!option.nNotifications && !!(option.nNotifications > 0)
                    }
                  >
                    <CircleNotifications color="info" />
                  </Fade>
                </div>
              </div>
            </NavLink>

            {option.children && option.children.length > 0 && (
              <Collapse
                in={isExpanded.includes(option.id)}
                timeout="auto"
                unmountOnExit
              >
                <List
                  className="collapsable-list"
                  component="div"
                  disablePadding
                >
                  {option.children.map((child) => (
                    <NavLink
                      key={child.id}
                      to={child.route || ""}
                      onClick={() => setIsOpen && setIsOpen(false)}
                      className={({ isActive }) =>
                        `list-subitem-container${
                          isActive
                            ? "-active"
                            : child.disabled
                            ? "-disabled"
                            : ""
                        }`
                      }
                    >
                      <div className="item-container">
                        <div className="left">
                          <div className="icon">{child.icon}</div>
                          <span>{child.label}</span>
                        </div>
                        <div className="right">
                          {child.isSiexSection && (
                            <Tooltip title={i18n.t("tooltips.siex")}>
                              <div className="icon">
                                <AutoFixHigh fontSize="small" />
                              </div>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </NavLink>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}

        {vinculationNeeded && (
          <NavLink
            to={PROTECTED_ROUTES.ACCOUNT_SYNC}
            type="button"
            className={({ isActive }) =>
              `account-sync-item-container${isActive ? "-active" : ""}`
            }
          >
            <div className="item-container">
              <div className="left">
                <div className="icon">
                  <Link />
                </div>
                <span>
                  {user?.person?.whatsappNumber
                    ? i18n.t("components.sidebar.accountSyncEmail")
                    : i18n.t("components.sidebar.accountSyncWhatsapp")}
                </span>
              </div>
            </div>
          </NavLink>
        )}
      </List>
    </div>
  );
};
