import { useEffect, useState, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import i18n from "../../config/configI18n";

import {
  Button,
  CircularProgress,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { Email } from "@mui/icons-material";

import { BrioCard } from "../../components/BrioCard";
import ScreenContentLayout from "../../components/ScreenContentLayout";
import TestBanner from "../../components/banners/TestBanner";
import AlertSnackbar from "../../components/elements/AlertSnackbar";

import { useAuth } from "../../hooks/useAuth";
import useCrud from "../../hooks/useCrud";

import { isValidEmail } from "../../helpers/validators";
import {
  SendLinkCodeInterface,
  SnackbarInterface,
} from "../../constants/interfaces";
import { PUBLIC_ROUTES } from "../../routes/routeNames";
import { helperTextStyle } from "../../components/elements/FormikTextField";
import { RESEND_CODE_BTN_INTERVAL } from "../../constants/constants";

const AccountSyncScreen = () => {
  const { user } = useAuth();

  const whatsAppChecked = useMemo(() => !!user?.person?.whatsappNumber, [user]);
  const emailChecked = useMemo(
    () => !!user?.person?.email && user.person.email.length > 0,
    [user]
  );

  const [activeStep, setActiveStep] = useState<number>(0);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );

  if (whatsAppChecked && emailChecked)
    return <Navigate to={PUBLIC_ROUTES.HOME} replace />;

  return (
    <ScreenContentLayout>
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <BrioCard
        title={
          whatsAppChecked
            ? i18n.t("accountSync.emailVerificationTitle")
            : i18n.t("accountSync.whatsappVerificationTitle")
        }
        headerText={
          whatsAppChecked
            ? i18n.t("accountSync.emailVerificationDescription")
            : i18n.t("accountSync.whatsappVerificationDescription_v2")
        }
        defaultExpanded
        disableAccordion
      >
        <div className="account-sync">
          <Stepper activeStep={activeStep}>
            <Step>
              <StepLabel>
                {whatsAppChecked
                  ? i18n.t("accountSync.step1EmailLabel")
                  : i18n.t("accountSync.step1WhatsappLabel")}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel>
                {whatsAppChecked
                  ? i18n.t("accountSync.step2EmailLabel")
                  : i18n.t("accountSync.step2WhatsappLabel")}
              </StepLabel>
            </Step>
          </Stepper>

          {whatsAppChecked ? (
            <EmailVerification
              activeStep={activeStep}
              onChangeStep={setActiveStep}
              onMessage={setSnackbarMsg}
              onError={setSnackbarMsg}
            />
          ) : (
            <WhatsappVerification
              activeStep={activeStep}
              onChangeStep={setActiveStep}
              onMessage={setSnackbarMsg}
              onError={setSnackbarMsg}
            />
          )}
        </div>
      </BrioCard>
    </ScreenContentLayout>
  );
};

export default AccountSyncScreen;

interface VerificationProps {
  activeStep: number;
  onChangeStep: (step: number) => void;
  onMessage?: (message: SnackbarInterface) => void;
  onError?: (error: SnackbarInterface) => void;
}
const WhatsappVerification = (props: VerificationProps) => {
  const { activeStep, onChangeStep, onMessage, onError } = props;

  const { user } = useAuth();
  const navigate = useNavigate();

  const [whatsappNumber, setWhatsappNumber] = useState<string>("");
  const [whatsappError, setWhatsappError] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [linkToken, setLinkToken] = useState<string>("");
  const [resendCodeBtnEnabled, setResendCodeBtnEnabled] =
    useState<boolean>(true);

  useEffect(() => {
    setWhatsappError(
      whatsappNumber.length > 0 && !matchIsValidTel(whatsappNumber)
    );
  }, [whatsappNumber]);

  const sendLinkCodeMutation = useCrud<SendLinkCodeInterface>({
    key: "sendLinkCode",
    onSuccess: (data) => {
      setLinkToken(data?.token || "");
      onChangeStep(1);
      onMessage &&
        onMessage({
          severity: "success",
          message: i18n.t("apiResponses.sendWhatsappCodeSuccess"),
        });
    },
    onError,
  });

  const attachEmailPhoneMutation = useCrud<any>({
    key: "attachEmailPhone",
    onSuccess: () => {
      onMessage &&
        onMessage({
          severity: "success",
          message: i18n.t("apiResponses.whatsappVerifiedSuccess"),
        });
      setTimeout(() => {
        navigate(0);
      }, 1000);
    },
    onError,
  });

  const handleWhatsappVerification = () => {
    if (!matchIsValidTel(whatsappNumber)) {
      setWhatsappError(true);
      return;
    }

    sendLinkCodeMutation.mutate({
      email: user?.person?.email,
      phone: whatsappNumber,
    } as SendLinkCodeInterface);
  };

  const handleResendCode = () => {
    handleWhatsappVerification();
    // Disable resend code for a while
    setResendCodeBtnEnabled(false);
    setTimeout(() => {
      setResendCodeBtnEnabled(true);
    }, RESEND_CODE_BTN_INTERVAL);
  };

  const handleChangePhoneNumber = (phone: string) => {
    setWhatsappNumber(phone);
  };

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setVerificationCode(value);
  };

  const handleCodeVerification = () => {
    if (verificationCode.length === 0) {
      onError &&
        onError({
          severity: "error",
          message: i18n.t("formErrors.requiredFields"),
        } as SnackbarInterface);
      return;
    }
    attachEmailPhoneMutation.mutate({
      token: linkToken,
      code: verificationCode,
    });
  };

  if (activeStep === 0)
    return (
      <div className="content">
        <MuiTelInput
          id="whatsappNumber"
          required
          error={whatsappError}
          className="form-input"
          FormHelperTextProps={{
            style: {
              ...helperTextStyle,
              color: whatsappError ? "#c62828" : "",
            },
          }}
          helperText={
            whatsappError
              ? i18n.t("formErrors.invalidPhoneNumber")
              : i18n.t("accountSync.whatsappHelperText")
          }
          label={i18n.t("accountSync.whatsappLabel")}
          defaultCountry="ES"
          langOfCountryName="ES"
          value={whatsappNumber}
          onChange={handleChangePhoneNumber}
        />
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
          endIcon={
            sendLinkCodeMutation.isLoading && <CircularProgress size={20} />
          }
          disabled={sendLinkCodeMutation.isLoading}
          onClick={handleWhatsappVerification}
        >
          {i18n.t("accountSync.whatsappVerificationBtnLabel")}
        </Button>
      </div>
    );

  return (
    <div className="content">
      <TextField
        fullWidth
        type="number"
        label={i18n.t("accountSync.whatsappVerificationCodeLabel")}
        helperText={`${i18n.t(
          "accountSync.whatsappVerificationCodeDescription"
        )} ${whatsappNumber}`}
        FormHelperTextProps={{
          style: { ...helperTextStyle, color: undefined },
        }}
        value={verificationCode}
        onChange={handleChangeCode}
      />
      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        endIcon={
          attachEmailPhoneMutation.isLoading && <CircularProgress size={20} />
        }
        disabled={attachEmailPhoneMutation.isLoading}
        onClick={handleCodeVerification}
      >
        {i18n.t("accountSync.whatsappVerificationCodeBtnLabel")}
      </Button>

      <div className="resend-code-container">
        <span>{i18n.t("whatsappCode.resendCodeLabel")}</span>
        <Button
          variant="text"
          disabled={!resendCodeBtnEnabled}
          onClick={handleResendCode}
        >
          {i18n.t("whatsappCode.resendCodeBtn")}
        </Button>
      </div>
    </div>
  );
};

const EmailVerification = (props: VerificationProps) => {
  const { activeStep, onChangeStep, onMessage, onError } = props;

  const { user } = useAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [linkToken, setLinkToken] = useState<string>("");
  const [resendCodeBtnEnabled, setResendCodeBtnEnabled] =
    useState<boolean>(true);

  useEffect(() => {
    setEmailError(email.length > 0 && !isValidEmail(email));
  }, [email]);

  const sendLinkCodeMutation = useCrud<SendLinkCodeInterface>({
    key: "sendLinkCode",
    onSuccess: (data) => {
      setLinkToken(data?.token || "");
      onChangeStep(1);
      onMessage &&
        onMessage({
          severity: "success",
          message: i18n.t("apiResponses.sendEmailCodeSuccess"),
        });
    },
    onError,
  });

  const attachEmailPhoneMutation = useCrud<any>({
    key: "attachEmailPhone",
    onSuccess: () => {
      onMessage &&
        onMessage({
          severity: "success",
          message: i18n.t("apiResponses.emailVerifiedSuccess"),
        });
      setTimeout(() => {
        navigate(0);
      }, 1000);
    },
    onError,
  });

  const handleEmailVerification = () => {
    if (!isValidEmail(email)) {
      setEmailError(true);
      return;
    }

    sendLinkCodeMutation.mutate({
      email,
      phone: user?.person?.whatsappNumber,
    } as SendLinkCodeInterface);
  };

  const handleResendCode = () => {
    handleEmailVerification();
    // Disable resend code for a while
    setResendCodeBtnEnabled(false);
    setTimeout(() => {
      setResendCodeBtnEnabled(true);
    }, RESEND_CODE_BTN_INTERVAL);
  };

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
  };

  const handleChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setVerificationCode(value);
  };

  const handleCodeVerification = () => {
    if (verificationCode.length === 0) {
      onError &&
        onError({
          severity: "error",
          message: i18n.t("formErrors.requiredFields"),
        } as SnackbarInterface);
      return;
    }
    attachEmailPhoneMutation.mutate({
      token: linkToken,
      code: verificationCode,
    });
  };

  if (activeStep === 0)
    return (
      <div className="content">
        <TextField
          fullWidth
          type="email"
          label={i18n.t("accountSync.emailLabel")}
          helperText={
            emailError
              ? i18n.t("formErrors.invalidEmail")
              : i18n.t("accountSync.emailHelperText")
          }
          FormHelperTextProps={{
            style: { ...helperTextStyle, color: emailError ? "#c62828" : "" },
          }}
          InputProps={{ endAdornment: <Email color="secondary" /> }}
          value={email}
          error={emailError}
          onChange={handleChangeEmail}
        />
        <Button
          variant="contained"
          fullWidth
          sx={{ mt: 2 }}
          endIcon={
            sendLinkCodeMutation.isLoading && <CircularProgress size={20} />
          }
          disabled={sendLinkCodeMutation.isLoading}
          onClick={handleEmailVerification}
        >
          {i18n.t("accountSync.emailVerificationBtnLabel")}
        </Button>
      </div>
    );

  return (
    <div className="content">
      <TextField
        fullWidth
        type="number"
        required
        label={i18n.t("accountSync.emailVerificationCodeLabel")}
        helperText={`${i18n.t(
          "accountSync.emailVerificationCodeDescription"
        )}${email}`}
        FormHelperTextProps={{
          style: { ...helperTextStyle, color: undefined },
        }}
        value={verificationCode}
        onChange={handleChangeCode}
      />
      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 2 }}
        endIcon={
          attachEmailPhoneMutation.isLoading && <CircularProgress size={20} />
        }
        disabled={attachEmailPhoneMutation.isLoading}
        onClick={handleCodeVerification}
      >
        {i18n.t("accountSync.emailVerificationCodeBtnLabel")}
      </Button>

      <div className="resend-code-container">
        <span>{i18n.t("emailCode.resendCodeLabel")}</span>
        <Button
          variant="text"
          disabled={!resendCodeBtnEnabled}
          onClick={handleResendCode}
        >
          {i18n.t("emailCode.resendCodeBtn")}
        </Button>
      </div>
    </div>
  );
};
