import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import i18n from "../../config/configI18n";
import moment from "moment";

import {
  FormControl,
  FormGroup,
  Collapse,
  Tabs,
  Tab,
  AppBar,
} from "@mui/material";
import { Build, FactCheck } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import FormikListItemSwitch from "../../components/elements/FormikListItemSwitch";
import TestBanner from "../../components/banners/TestBanner";
import SiexBanner from "../../components/banners/SiexBanner";
import PartitionForm from "../../components/forms/PartitionForm";
import DialogToolInspection from "../../components/dialogs/DialogToolInspection";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";
import SiexDownloadStatusBanner from "../../components/banners/SiexDownloadStatusBanner";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import { Column } from "../../models/Generic";
import Tool from "../../models/agrobusiness/Tool";
import ToolInspection from "../../models/agrobusiness/ToolInspection";
import Document from "../../models/files/Document";

import {
  addItemToListIfNotExistsByField,
  updateItemOfList,
  deleteItemsOfListByField,
} from "../../helpers/utils";

import { SnackbarInterface } from "../../constants/interfaces";
import { FormMode } from "../../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";

const ExploitationToolsScreen = () => {
  const ToolValidator = Yup.object().shape({
    name: Yup.string().required(),
  });
  const toolColumns: Column[] = [
    { id: "name", label: i18n.t("tools.column.name"), numeric: false },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const { selectedABAccount, selectedABPartition } = useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const { data: tools, isFetching } = useFetch<Tool[]>({
    queryKey: ["tools", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
  });

  const selected = useMemo(
    () =>
      formMode === FormMode.EDIT && selectedRowIds.length === 1
        ? tools?.find((t: Tool) => t.id === selectedRowIds[0])
        : undefined,
    [selectedRowIds, formMode, tools]
  );

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );
  const isSelectedSiexSync = useMemo(
    () => selected?.siexEntity === true,
    [selected]
  );

  const handleSubmit = (values: Tool) => {
    switch (formMode) {
      case FormMode.CREATE:
        toolCreateMutation.mutate(values);
        break;
      case FormMode.EDIT:
        toolEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new Tool(),
    validationSchema: ToolValidator,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formik.status === FormMode.CREATE
      ? i18n.t("tools.drawerTitleCreate")
      : i18n.t("tools.drawerTitleEdit");
  const drawerBtnText =
    formik.status === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  const clearForm = () => {
    setTabValue(0);
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const toolCreateMutation = useCrud<Tool>({
    key: "postTool",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const toolEditMutation = useCrud<Tool>({
    key: "putTool",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const toolDeleteMutation = useCrud({
    key: "deleteTools",
    values: tools?.filter((t: Tool) => selectedRowIds.includes(t?.id || 0)),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      // If there are errors, set tab to the first tab with errors
      setTabValue(0);
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    toolDeleteMutation.mutate(selectedRowIds);
  };

  const handleChangeTabValue = (tabValue: number) => {
    setTabValue(tabValue);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <SiexBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("tools.backAlertTitle")}
        contentText={i18n.t("tools.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("tools.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("tools.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={toolDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("tools.tableTitle")}
        headerText={i18n.t("tools.headerText")}
        colums={toolColumns}
        rows={tools}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Build sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("tools.emptyTableTitle")}
        emptyTableSubtitle={
          selectedABAccount?.agroBusiness?.siexEntity
            ? i18n.t("tools.emptyTableSubtitleSIEX")
            : i18n.t("tools.emptyTableSubtitle")
        }
        emptyTableBtnText={
          !selectedABAccount?.agroBusiness?.siexEntity
            ? i18n.t("tools.emptyTableBtnText")
            : undefined
        }
        addBtnVariant="contained"
        siexRows
        hideBtn={selectedABAccount?.agroBusiness?.siexEntity}
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={toolCreateMutation.isLoading || toolEditMutation.isLoading}
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <ToolsForm
          formik={formik}
          tabValue={tabValue}
          isOpenFilesForm={isOpenFilesForm}
          selectedEditRow={selected}
          isSiexActive={isSiexActive}
          isSelectedSiexSync={isSelectedSiexSync}
          forceExpanded={expandCards}
          onChangeTabValue={handleChangeTabValue}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationToolsScreen;

interface ToolsFormProps {
  formik: FormikProps<Tool>;
  tabValue: number;
  isSiexActive: boolean;
  isSelectedSiexSync: boolean;
  isOpenFilesForm?: boolean;
  selectedEditRow?: Tool;
  forceExpanded?: boolean;
  onCloseFilesForm?: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
  onChangeTabValue: (tabValue: number) => void;
}
const ToolsForm = (props: ToolsFormProps) => {
  const {
    formik,
    tabValue,
    selectedEditRow,
    isOpenFilesForm = false,
    isSiexActive,
    isSelectedSiexSync,
    onCloseFilesForm,
    onError,
    onChangeTabValue,
  } = props;

  const { selectedABAccount, selectedABPartition, agroBusinessPartitions } =
    useSession();
  const queryClient = useQueryClient();

  const { isFetching: isUpdating } = useFetch<Tool>({
    queryKey: ["tool", selectedEditRow?.id],
    selected: selectedEditRow,
    enabled: !!selectedEditRow?.id,
    onSuccess: (data: Tool) => {
      formik.setValues(data);
      // Update without refetch
      queryClient.setQueryData<Tool[]>(
        ["tools", selectedABAccount?.id, selectedABPartition?.id],
        (oldData) => updateItemOfList(oldData, data, "id")
      );
    },
    onError,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onChangeTabValue(newValue);
  };

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      {isSiexActive ? (
        <SiexDownloadStatusBanner
          isSync={isSelectedSiexSync}
          timestamp={selectedEditRow?.siexUpdatedOn}
        />
      ) : (
        <SiexBanner adviceText />
      )}
      <AppBar
        className="tabbar"
        component="nav"
        position="sticky"
        elevation={0}
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab
            className="tab"
            wrapped
            label={i18n.t("tools.tab.data")}
            icon={<Build />}
          />
          <Tab
            className="tab"
            wrapped
            label={i18n.t("tools.tab.inspections")}
            icon={<FactCheck />}
          />
        </Tabs>
      </AppBar>
      {tabValue === 1 ? (
        <ToolInspectionsTab {...props} />
      ) : (
        <ToolTab {...props} />
      )}
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("tools.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </>
  );
};

const ToolTab = (props: ToolsFormProps) => {
  return (
    <>
      <DefinitionForm {...props} />
      <IdsForm {...props} />
      <TenureForm {...props} />
    </>
  );
};

const DefinitionForm = (props: ToolsFormProps) => {
  const { formik, forceExpanded } = props;

  return (
    <BrioCard
      title={i18n.t("tools.definitionForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="name"
            label={i18n.t("tools.definitionForm.name")}
            required
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="description"
            label={i18n.t("tools.definitionForm.description")}
            multiline
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const IdsForm = (props: ToolsFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  return (
    <BrioCard title={i18n.t("tools.idsForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl className="form-control">
          <FormikListItemSwitch
            formik={formik}
            name="idRegistered"
            primaryText={i18n.t("tools.idsForm.idRegistered")}
            secondaryText={i18n.t("tools.idsForm.idRegisteredDescription")}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <Collapse in={formik.values?.idRegistered}>
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name="registerId"
                label={i18n.t("tools.idsForm.registerId")}
                placeholder={i18n.t("tools.idsForm.registerIdPlaceholder")}
                helperText={i18n.t("tools.idsForm.registerIdHelperText")}
                disabled={isSelectedSiexSync}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name="aeroRegisterId"
                label={i18n.t("tools.idsForm.aeroRegisterId")}
                placeholder={i18n.t("tools.idsForm.aeroRegisterIdPlaceholder")}
                helperText={i18n.t("tools.idsForm.aeroRegisterIdHelperText")}
                disabled={isSelectedSiexSync}
              />
            </FormControl>
          </div>
        </Collapse>

        <Collapse in={!formik.values?.idRegistered}>
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name="referenceId"
                label={i18n.t("tools.idsForm.referenceId")}
                placeholder={i18n.t("tools.idsForm.referenceIdPlaceholder")}
                helperText={i18n.t("tools.idsForm.referenceIdHelperText")}
                disabled={isSelectedSiexSync}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};

const TenureForm = (props: ToolsFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  return (
    <BrioCard title={i18n.t("tools.tenureForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl className="form-control">
          <FormikListItemSwitch
            formik={formik}
            name="ownership"
            primaryText={i18n.t("tools.tenureForm.ownership")}
            secondaryText={i18n.t("tools.tenureForm.ownershipDescription")}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
        <Collapse className="collapse-container" in={formik.values?.ownership}>
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <DatePicker
                className="form-input"
                format="DD/MM/YYYY"
                label={i18n.t("tools.tenureForm.purchaseDate")}
                value={
                  formik.values.purchaseDate
                    ? moment(formik.values.purchaseDate, "YYYY-MM-DD")
                    : null
                }
                onChange={(date) =>
                  formik.setFieldValue(
                    "purchaseDate",
                    date?.format("YYYY-MM-DD")
                  )
                }
                disabled={isSelectedSiexSync}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};

const ToolInspectionsTab = (props: ToolsFormProps) => {
  return <InspectionsForm {...props} />;
};

const InspectionsForm = (props: ToolsFormProps) => {
  const inspectionColumns: Column[] = [
    {
      id: "date",
      label: i18n.t("tools.inspectionsForm.column.date"),
      numeric: false,
    },
    {
      id: "resultType.name",
      label: i18n.t("tools.inspectionsForm.column.result"),
      numeric: false,
    },
  ];

  const { formik } = props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedToolInspection, setSelectedToolInspection] =
    useState<ToolInspection | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedToolInspection(null);
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };

  // Only dialog to create or edit inspection
  const handleConfirmDialog = (selected: ToolInspection) => {
    const updatedInspections =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.inspections,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.inspections, selected, "idx");
    formik.setFieldValue("inspections", updatedInspections);
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.inspections?.find(
      (i: ToolInspection) => i.idx === idx
    );
    if (selected) {
      setSelectedToolInspection(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    formik.setFieldValue(
      "inspections",
      deleteItemsOfListByField(formik.values.inspections, idxs, "idx")
    );
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        idx="idx"
        title={i18n.t("tools.inspectionsForm.tableTitle")}
        headerText={i18n.t("tools.inspectionsForm.headerText")}
        btnText={i18n.t("tools.inspectionsForm.tableBtnText")}
        colums={inspectionColumns}
        rows={formik.values.inspections || []}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        optional
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClikEdit}
        onClickDelete={handleClickDelete}
      />

      <DialogToolInspection
        open={isOpenDialog}
        selected={selectedToolInspection}
        inspections={formik.values.inspections}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};
