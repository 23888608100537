import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import i18n from "../../config/configI18n";
import * as Yup from "yup";

import { FormGroup, FormControl } from "@mui/material";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import TestBanner from "../../components/banners/TestBanner";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";

import { useSession } from "../../hooks/useSession";
import { useAuth } from "../../hooks/useAuth";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import PersonWithRole from "../../models/account/PersonWithRole";
import { Column } from "../../models/Generic";

import {
  PersonPermissionRoleInterface,
  SnackbarInterface,
} from "../../constants/interfaces";
import { FBPersonRole, FormMode } from "../../constants/enums";
import { PERSON_PERMISSION_ROLES_LIST } from "../../providers/YupProvider";

import ErrorScreen from "../ErrorScreen";

const ExploitationPermissionsScreen = () => {
  const agroBusinessAccountPermissionsColumns: Column[] = [
    {
      id: "person.email",
      label: i18n.t("explPermissions.column.email"),
      numeric: false,
    },
    {
      id: "personRole.name",
      label: i18n.t("explPermissions.column.personRole"),
      numeric: false,
    },
  ];

  const PersonPermissionValidator = Yup.object().shape({
    person: Yup.object().shape({
      email: Yup.string().email().required(),
    }),
    personRole: Yup.object().required(),
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { agroBusinessAccountPermissions, isFetchingPermissions } =
    useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [ownerAccount, setOwnerAccount] = useState<PersonWithRole | undefined>(
    undefined
  );

  useEffect(() => {
    setOwnerAccount(
      agroBusinessAccountPermissions?.find(
        (abap) => abap?.person?.email === user?.person?.email
      )
    );
  }, [agroBusinessAccountPermissions, user]);

  const handleSubmit = (values: PersonWithRole) => {
    switch (formMode) {
      case FormMode.CREATE:
      case FormMode.EDIT:
        agroBusinessAccountSetRoleMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new PersonWithRole(),
    validationSchema: PersonPermissionValidator,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("explPermissions.drawerTitleCreate")
      : i18n.t("explPermissions.drawerTitleEdit");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer if url contains /edit
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
  }, [location.pathname]);

  const clearForm = () => {
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
  };
  const openDrawer = () => {
    if (formMode === FormMode.CREATE) clearForm();
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const agroBusinessAccountSetRoleMutation = useCrud<PersonWithRole>({
    key: "setAgroBusinessAccountPermission",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: setSnackbarMsg,
  });

  const agroBusinessAccountDeleteRoleMutation = useCrud({
    key: "deleteAgroBusinessAccountRolePermissions",
    values: agroBusinessAccountPermissions?.filter(
      (personWithRole: PersonWithRole) =>
        selectedRowIds.includes(personWithRole?.idx || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer();
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    const permission = agroBusinessAccountPermissions?.find(
      (agba) => agba.idx === id
    );
    if (permission) formik.setValues(permission);
    openDrawer();
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    agroBusinessAccountDeleteRoleMutation.mutate(selectedRowIds);
  };

  if (user?.role && user.role !== FBPersonRole.ADMIN)
    return <ErrorScreen errorStatus={401} />;

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetchingPermissions || !user?.role} />
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("explPermissions.backAlertTitle")}
        contentText={i18n.t("explPermissions.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("explPermissions.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t(
          "explPermissions.confirmDeleteAlertDialogDescription"
        )}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={agroBusinessAccountDeleteRoleMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />

      <TableBrioCard
        idx="idx"
        title={i18n.t("explPermissions.tableTitle")}
        headerText={i18n.t("explPermissions.headerText")}
        colums={agroBusinessAccountPermissionsColumns}
        rows={agroBusinessAccountPermissions}
        selectedRows={selectedRowIds}
        disableRows={ownerAccount?.idx ? [ownerAccount.idx] : []}
        addBtnVariant="contained"
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />

      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={agroBusinessAccountSetRoleMutation.isLoading}
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
      >
        <AgroBusinessAccountPermissionForm
          formik={formik}
          forceExpanded={expandCards}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationPermissionsScreen;

interface AgroBusinessAccountPermissionFormProps {
  formik: FormikProps<PersonWithRole>;
  forceExpanded?: boolean;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const AgroBusinessAccountPermissionForm = (
  props: AgroBusinessAccountPermissionFormProps
) => {
  const { formik, forceExpanded } = props;

  const getHelperText = (permission?: PersonPermissionRoleInterface) => {
    switch (permission?.role) {
      case FBPersonRole.ADMIN:
        return i18n.t("explPermissions.adminPermissionHelperText");
      case FBPersonRole.WRITER:
        return i18n.t("explPermissions.writerPermissionHelperText");
      case FBPersonRole.READER:
        return i18n.t("explPermissions.readerPermissionHelperText");
      default:
        return i18n.t("explPermissions.defaultPermissionHelperText");
    }
  };

  return (
    <BrioCard
      title={i18n.t("explPermissions.form.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="person.email"
            label={i18n.t("explPermissions.form.email")}
            type="email"
            required
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="personRole"
            label={i18n.t("explPermissions.form.personRole")}
            helperText={getHelperText(formik.values.personRole)}
            required
            options={PERSON_PERMISSION_ROLES_LIST}
            optionLabelFieldName="name"
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
