import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import i18n from "../../config/configI18n";
import * as Yup from "yup";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormGroup,
  FormControl,
  createFilterOptions,
  TextField,
  InputAdornment,
  Alert,
} from "@mui/material";

import FormikAutocomplete from "../elements/FormikAutocomplete";
import FormikTextField from "../elements/FormikTextField";
import FormAlertDialog from "../dialogs/FormAlertDialog";
import AlertEmptyList from "../AlertEmptyList";

import Sector from "../../models/agrobusiness/Sector";
import FertilizationPlanSector from "../../models/fertiliz/FertilizationPlanSector";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import { PROTECTED_ROUTES } from "../../routes/routeNames";
import { SnackbarInterface } from "../../constants/interfaces";
import { getMaxValueOfList } from "../../helpers/utils";

interface DialogFertilizationPlanSectorProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (fertilizedSector: FertilizationPlanSector) => void;
  selected?: FertilizationPlanSector | null;
  fertilizedSectors?: FertilizationPlanSector[];
  onError?: (snackbarError: SnackbarInterface) => void;
}

const filter = createFilterOptions();
const DialogFertilizationPlanSector = (
  props: DialogFertilizationPlanSectorProps
) => {
  const YupValidatorSchema = Yup.object().shape({
    sector: Yup.object().required(),
    fertilizedArea: Yup.number().required(),
  });

  const {
    open,
    onClose,
    onConfirm,
    selected,
    fertilizedSectors = [],
    onError,
  } = props;

  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();

  const [selectedSectorArea, setSelectedSectorArea] = useState<number>(0);
  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] =
    useState<boolean>(false);

  const { data: sectors, isFetching } = useFetch<Sector[]>({
    queryKey: ["sectors", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount,
    onError,
  });

  const closeDialog = () => {
    onClose();
    formik.resetForm();
    formik.setErrors({});
  };

  const handleSubmit = (values: FertilizationPlanSector) => {
    // Find the max idx
    const maxIdx = getMaxValueOfList(fertilizedSectors, "idx") + 1;
    if (!values.idx) values.idx = maxIdx; // Needs id to be unique in the table
    onConfirm(values);
    closeDialog();
  };

  const formik = useFormik({
    initialValues: selected || new FertilizationPlanSector(),
    validationSchema: YupValidatorSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (selected) {
      const selectedSector = sectors?.find((s) => s.id === selected.sector?.id);
      const treatedArea = selectedSector?.area || selected?.fertilizedArea || 0;
      setSelectedSectorArea(treatedArea);
      formik.setValues(selected);
    }
  }, [selected, sectors]);

  const openRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(true);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);

  const cancelRedirectAlertDialog = () => closeRedirectAlertDialog();

  const handleClickNewOption = () => openRedirectAlertDialog();

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_CROPS}`);
  };

  const handleConfirm = () => {
    formik.submitForm();
  };

  const handleChangeSector = (sector: Sector) => {
    const treatedArea = sector?.area || 0;
    setSelectedSectorArea(treatedArea);
    formik.setFieldValue("fertilizedArea", treatedArea);
    formik.setFieldValue("sector", sector);
  };

  const handleChangeTreatedArea = (event: any) => {
    const treatedArea = event.target.value || 0;
    if (treatedArea > selectedSectorArea)
      event.target.value = selectedSectorArea;
  };

  // Filter selected sectors
  const sectorsFilter = (options: Sector[], params: any) => {
    const filtered = filter(options, params);
    const filterSelected = filtered.filter(
      (cs: any) => !fertilizedSectors.find((fs) => fs.sector?.id === cs?.id)
    );
    if (selected) filterSelected.unshift(selected.sector as Sector);
    return filterSelected;
  };

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
      className="dialog"
    >
      <DialogTitle className="title">
        {i18n.t("components.dialogFertilizerPlanAreaSector.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText className="text">
          {i18n.t("components.dialogFertilizerPlanAreaSector.description")}
        </DialogContentText>
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            {!isFetching && sectors?.length === 0 && (
              <AlertEmptyList
                severity="info"
                btnText={i18n.t(
                  "components.dialogFertilizerPlanAreaSector.attachSector"
                )}
                onClick={handleClickNewOption}
              >
                {i18n.t(
                  "components.dialogFertilizerPlanAreaSector.emptyListSectors"
                )}
              </AlertEmptyList>
            )}
            {!isFetching &&
              !selected &&
              sectors?.length !== 0 &&
              sectors?.length === fertilizedSectors?.length && (
                <Alert severity="info" sx={{ mb: 2 }}>
                  {i18n.t(
                    "components.dialogFertilizerPlanAreaSector.noMoreSectors"
                  )}
                </Alert>
              )}
            <FormikAutocomplete
              formik={formik}
              name="sector"
              label={i18n.t("components.dialogFertilizerPlanAreaSector.sector")}
              optionLabelFieldName="sector.name"
              required
              filterOptions={sectorsFilter}
              options={sectors || []}
              disabled={!!selected}
              onChange={handleChangeSector}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control-row">
            <FormikTextField
              formik={formik}
              className="form-input-row-60"
              name="fertilizedArea"
              label={i18n.t(
                "components.dialogFertilizerPlanAreaSector.fertilizedArea"
              )}
              type="number"
              valueUnit="ha"
              inputProps={{ min: 0, max: selectedSectorArea }}
              onInput={handleChangeTreatedArea}
            />
            <TextField
              name="totalArea"
              disabled
              className="form-input-row-40"
              variant="outlined"
              type="number"
              label={i18n.t(
                "components.dialogFertilizerPlanAreaSector.totalArea"
              )}
              helperText={i18n.t(
                "components.dialogFertilizerPlanAreaSector.totalAreaHelperText"
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">ha</InputAdornment>
                ),
              }}
              value={selectedSectorArea}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {selected ? i18n.t("words.update") : i18n.t("words.add")}
        </Button>
      </DialogActions>
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t(
          "components.dialogFertilizerPlanAreaSector.redirectAlertDialogTitle"
        )}
        contentText={i18n.t(
          "components.dialogFertilizerPlanAreaSector.redirectAlertDialogContentText"
        )}
        open={!!isOpenRedirectAlertDialog}
        onCancel={cancelRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
    </Dialog>
  );
};

export default DialogFertilizationPlanSector;
