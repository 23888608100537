import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import LoginScreen from "../screens/LoginScreen";
import EmailCodeScreen from "../screens/EmailCodeScreen";
import WhatsappCodeScreen from "../screens/WhatsappCodeScreen";
import ExploitationPermissionsScreen from "../screens/exploitation/ExploitationPermissionsScreen";
import ExploitationPartitionsScreen from "../screens/exploitation/ExploitationPartitionsScreen";
import DashboardScreen from "../screens/exploitation/DashboardScreen";
import ExploitationDataScreen from "../screens/exploitation/ExploitationDataScreen";
import ExploitationLeadershipScreen from "../screens/exploitation/ExploitationLeadershipScreen";
import ExploitationFacilityScreen from "../screens/exploitation/ExploitationFacilityScreen";
import ExploitationToolsScreen from "../screens/exploitation/ExploitationToolsScreen";
import ExploitationWaterSourcesScreen from "../screens/exploitation/ExploitationWaterSourcesScreen";
import ExploitationSectorsScreen from "../screens/exploitation/ExploitationSectorsScreen";
import ExploitationCropsScreen from "../screens/exploitation/ExploitationCropsScreen";
import ExploitationInvolvedEntitiesScreen from "../screens/exploitation/ExploitationInvolvedEntitiesScreen";
import ExploitationTreatmentsScreen from "../screens/exploitation/ExploitationTreatmentsScreen";
import ExploitationPluviometryScreen from "../screens/exploitation/ExploitationPluviometryScreen";
import ExploitationAnalysisScreen from "../screens/exploitation/ExploitationAnalysisScreen";
import ExploitationStockFertilizerScreen from "../screens/exploitation/ExploitationStockFertilizerScreen";
import ExploitationStockPhytosanitaryScreen from "../screens/exploitation/ExploitationStockPhytosanitaryScreen";
import ExploitationFertilizationScreen from "../screens/exploitation/ExploitationFertilizationScreen";
import ExploitationIrrigationScreen from "../screens/exploitation/ExploitationIrrigationScreen";
import ExploitationDocumentsScreen from "../screens/exploitation/ExploitationDocumentsScreen";
import ExploitationFertilizationPlansScreen from "../screens/exploitation/ExploitationFertilizationPlansScreen";
import OnBoardingScreen from "../screens/OnBoardingScreen";
import SettingsScreen from "../screens/SettingsScreen";
import ExploitationOffersScreen from "../screens/exploitation/ExploitationOffersScreen";
import ErrorScreen from "../screens/ErrorScreen";
import PoliciesScreen from "../screens/PoliciesScreen";
import VademecumPhytoScreen from "../screens/VademecumPhytoScreen";
import VademecumFertilizScreen from "../screens/VademecumFertilizScreen";
import AccountSyncScreen from "../screens/exploitation/AccountSyncScreen";
import ExploitationRecipesScreen from "../screens/exploitation/ExploitationRecipesScreen";
import ExploitationExportDataScreen from "../screens/exploitation/ExploitationExportDataScreen";
import SiexErrorsScreen from "../screens/SiexErrorsScreen";

import AuthLayout from "./AuthLayout";
import HomeLayout from "./HomeLayout";
import ProtectedLayout from "./ProtectedLayout";

import { PUBLIC_ROUTES, PROTECTED_ROUTES } from "./routeNames";
import SiexAdvisorRedirect from "./SiexAdvisorRedirect";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />} errorElement={<ErrorScreen />}>
      <Route element={<HomeLayout />}>
        <Route
          path={PUBLIC_ROUTES.HOME}
          element={<Navigate to={PUBLIC_ROUTES.LOGIN} replace />}
        />
        <Route path={PUBLIC_ROUTES.LOGIN} element={<LoginScreen />} />
        <Route
          path={PUBLIC_ROUTES.EMAIL_VERIFICATION}
          element={<EmailCodeScreen />}
        />
        <Route
          path={PUBLIC_ROUTES.WHATSAPP_VERIFICATION}
          element={<WhatsappCodeScreen />}
        />
        <Route path={PUBLIC_ROUTES.POLICIES} element={<PoliciesScreen />} />
      </Route>

      <Route
        path={PROTECTED_ROUTES.SIEX_ADVISOR_REDIRECT}
        element={<SiexAdvisorRedirect />}
      />

      <Route element={<ProtectedLayout />}>
        <Route
          path={PROTECTED_ROUTES.ONBOARDING}
          element={<OnBoardingScreen />}
        />
        <Route
          path={PROTECTED_ROUTES.DASHBOARD}
          element={<DashboardScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_PARTITIONS}/*`}
          element={<ExploitationPartitionsScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATIONS_PERMISSIONS}/*`}
          element={<ExploitationPermissionsScreen />}
        />
        <Route
          path={PROTECTED_ROUTES.EXPLOITATION_DATA}
          element={<ExploitationDataScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_LEADERSHIP}/*`}
          element={<ExploitationLeadershipScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_FACILITIES}/*`}
          element={<ExploitationFacilityScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_TOOLS}/*`}
          element={<ExploitationToolsScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_WATER_SOURCES}/*`}
          element={<ExploitationWaterSourcesScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_SECTORS}/*`}
          element={<ExploitationSectorsScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_CROPS}/*`}
          element={<ExploitationCropsScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_INVOLVED_ENTITIES}/*`}
          element={<ExploitationInvolvedEntitiesScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_TREATMENTS}/*`}
          element={<ExploitationTreatmentsScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_PLUVIOMETRY}/*`}
          element={<ExploitationPluviometryScreen />}
        />
        <Route
          path={PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER}
          element={
            <Navigate
              to={`/${PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER}/inventory`}
              replace
            />
          }
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_STOCK_FERTILIZER}/:tabName/:movementTypeCode?/*`}
          element={<ExploitationStockFertilizerScreen />}
        />
        <Route
          path={PROTECTED_ROUTES.EXPLOITATION_STOCK_PHYTOSANITARY}
          element={
            <Navigate
              to={`/${PROTECTED_ROUTES.EXPLOITATION_STOCK_PHYTOSANITARY}/inventory`}
              replace
            />
          }
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_STOCK_PHYTOSANITARY}/:tabName/:movementTypeCode?/*`}
          element={<ExploitationStockPhytosanitaryScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_FERTILIZATION}/*`}
          element={<ExploitationFertilizationScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_FERTILIZATION_PLANS}/*`}
          element={<ExploitationFertilizationPlansScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_IRRIGATIONS}/*`}
          element={<ExploitationIrrigationScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_ANALYSIS}/*`}
          element={<ExploitationAnalysisScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_FILES}/*`}
          element={<ExploitationDocumentsScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_OFFERS}/*`}
          element={<ExploitationOffersScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_RECIPES}/:recipeId?/:productId?`}
          element={<ExploitationRecipesScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.SETTINGS}/*`}
          element={<SettingsScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.VADEMECUM_PHYTO}/*`}
          element={<VademecumPhytoScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.VADEMECUM_FERTILIZER}/*`}
          element={<VademecumFertilizScreen />}
        />
        <Route
          path={PROTECTED_ROUTES.EXPLOITATION_EXPORT_DATA}
          element={<ExploitationExportDataScreen />}
        />
        <Route
          path={PROTECTED_ROUTES.ACCOUNT_SYNC}
          element={<AccountSyncScreen />}
        />
        <Route
          path={PROTECTED_ROUTES.EXPLOITATION_SIEX_ERRORS}
          element={<SiexErrorsScreen />}
        />
      </Route>
    </Route>
  )
);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
