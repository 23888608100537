import Crop from "../../crop/Crop";
import FertilizerStockMovement from "../../fertiliz/FertilizerStockMovement";
import MeasurementUnit from "../../catalogue/MeasurementUnit";
import FertigationWaterMix from "../../fertiliz/FertigationWaterMix";
import AgroBusinessPhytosanitaryInvolvedEntity from "../../agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import FertilizationType from "../../catalogue/FertilizationType";
import ManureTreatment from "../../catalogue/ManureTreatment";
import FertilizerApplicationMethod from "../../catalogue/FertilizerApplicationMethod";
import FertilizationGoodPracticeType from "../../catalogue/FertilizationGoodPracticeType";
import FertilizationSector from "../../fertiliz/FertilizationSector";
import Tool from "../../agrobusiness/Tool";
import IrrigationSystemType from "../../catalogue/IrrigationSystemType";

import { FBESiexRequestStatus } from "../../../constants/enums";
import IrrigationWaterSourceType from "../../catalogue/IrrigationWaterSourceType";
import IrrigationGoodPractice from "../../catalogue/IrrigationGoodPractice";

class FBFertilization {
  id?: number;
  crop?: Crop; //*
  startDate?: string;
  endDate?: string;
  stockMovements?: FertilizerStockMovement[];
  applicationArea?: number;
  dose?: number;
  doseUnit?: MeasurementUnit; //*
  type?: FertilizationType; //*
  applicationMethod?: FertilizerApplicationMethod; //*
  wasteAuthorizationNumber?: string;
  sewageSludgeApplication?: boolean;
  advisor?: AgroBusinessPhytosanitaryInvolvedEntity;
  adviceDate?: string;
  fertilizerProviderName?: string;
  fertilizerProviderId?: string;
  fertigationWaterMix?: FertigationWaterMix;
  declareGoodPractices?: boolean;
  goodPractices?: FertilizationGoodPracticeType[];
  fertilizedSectors?: FertilizationSector[];
  applicator?: AgroBusinessPhytosanitaryInvolvedEntity;
  tool?: Tool;
  manureTreatment?: ManureTreatment;
  irrigationSystem?: IrrigationSystemType;
  waterQuantity?: number;
  waterQuantityUnit?: MeasurementUnit;
  waterSourceTypes?: IrrigationWaterSourceType[];
  irrigationCounterId?: string;
  irrigationGoodPracticesDeclared?: boolean;
  irrigationGoodPractices?: IrrigationGoodPractice[];
  fertigationNitrogenDose?: number;
  fertigationNitrogenDoseUnit?: MeasurementUnit;
  fertigationPhosphorusDose?: number;
  fertigationPhosphorusDoseUnit?: MeasurementUnit;
  siexId?: number;
  siexStatus?: FBESiexRequestStatus;
  siexSentOn?: number;
  siexBookId?: number;
  siexRequestCode?: number;
  siexRequestId?: number;

  constructor(
    id?: number,
    crop?: Crop,
    startDate?: string,
    endDate?: string,
    stockMovements?: FertilizerStockMovement[],
    applicationArea?: number,
    dose?: number,
    doseUnit?: MeasurementUnit,
    type?: FertilizationType,
    applicationMethod?: FertilizerApplicationMethod,
    wasteAuthorizationNumber?: string,
    sewageSludgeApplication?: boolean,
    advisor?: AgroBusinessPhytosanitaryInvolvedEntity,
    adviceDate?: string,
    fertilizerProviderName?: string,
    fertilizerProviderId?: string,
    fertigationWaterMix?: FertigationWaterMix,
    declareGoodPractices?: boolean,
    goodPractices?: FertilizationGoodPracticeType[],
    fertilizedSectors?: FertilizationSector[],
    applicator?: AgroBusinessPhytosanitaryInvolvedEntity,
    tool?: Tool,
    manureTreatment?: ManureTreatment,
    irrigationSystem?: IrrigationSystemType,
    waterQuantity?: number,
    waterQuantityUnit?: MeasurementUnit,
    waterSourceTypes?: IrrigationWaterSourceType[],
    irrigationCounterId?: string,
    irrigationGoodPracticesDeclared?: boolean,
    irrigationGoodPractices?: IrrigationGoodPractice[],
    fertigationNitrogenDose?: number,
    fertigationNitrogenDoseUnit?: MeasurementUnit,
    fertigationPhosphorusDose?: number,
    fertigationPhosphorusDoseUnit?: MeasurementUnit
  ) {
    this.id = id;
    this.crop = crop;
    this.startDate = startDate;
    this.endDate = endDate;
    this.stockMovements = stockMovements;
    this.applicationArea = applicationArea;
    this.dose = dose;
    this.doseUnit = doseUnit;
    this.type = type;
    this.applicationMethod = applicationMethod;
    this.wasteAuthorizationNumber = wasteAuthorizationNumber;
    this.sewageSludgeApplication = sewageSludgeApplication;
    this.advisor = advisor;
    this.adviceDate = adviceDate;
    this.fertilizerProviderName = fertilizerProviderName;
    this.fertilizerProviderId = fertilizerProviderId;
    this.fertigationWaterMix = fertigationWaterMix;
    this.declareGoodPractices = declareGoodPractices;
    this.goodPractices = goodPractices;
    this.fertilizedSectors = fertilizedSectors;
    this.applicator = applicator;
    this.tool = tool;
    this.manureTreatment = manureTreatment;
    this.irrigationSystem = irrigationSystem;
    this.waterQuantity = waterQuantity;
    this.waterQuantityUnit = waterQuantityUnit;
    this.waterSourceTypes = waterSourceTypes;
    this.irrigationCounterId = irrigationCounterId;
    this.irrigationGoodPracticesDeclared = irrigationGoodPracticesDeclared;
    this.irrigationGoodPractices = irrigationGoodPractices;
    this.fertigationNitrogenDose = fertigationNitrogenDose;
    this.fertigationNitrogenDoseUnit = fertigationNitrogenDoseUnit;
    this.fertigationPhosphorusDose = fertigationPhosphorusDose;
    this.fertigationPhosphorusDoseUnit = fertigationPhosphorusDoseUnit;
  }

  mapToBaseClass(obj: any): void {
    this.id = obj?.id;
    this.crop = new Crop().mapToClass(obj?.crop);
    this.startDate = obj?.startDate;
    this.endDate = obj?.endDate;
    this.stockMovements = obj?.stockMovements.map((stockMovement: any) =>
      new FertilizerStockMovement().mapToClass(stockMovement)
    );
    this.applicationArea = obj?.applicationArea;
    this.dose = obj?.dose;
    this.doseUnit = new MeasurementUnit().mapToClass(obj?.doseUnit);
    this.type = new FertilizationType().mapToClass(obj?.type);
    this.applicationMethod = new FertilizerApplicationMethod().mapToClass(
      obj?.applicationMethod
    );
    this.wasteAuthorizationNumber = obj?.wasteAuthorizationNumber;
    this.sewageSludgeApplication = obj?.sewageSludgeApplication;
    this.advisor = new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(
      obj?.advisor
    );
    this.adviceDate = obj?.adviceDate;
    this.fertilizerProviderName = obj?.fertilizerProviderName;
    this.fertilizerProviderId = obj?.fertilizerProviderId;
    this.fertigationWaterMix = new FertigationWaterMix().mapToClass(
      obj?.fertigationWaterMix
    );
    this.declareGoodPractices = obj?.declareGoodPractices;
    this.goodPractices =
      obj?.goodPractices?.map((goodPractice: any) =>
        new FertilizationGoodPracticeType().mapToClass(goodPractice)
      ) || [];
    this.fertilizedSectors =
      obj?.fertilizedSectors
        ?.map((fertilizedSector: any) =>
          new FertilizationSector().mapToClass(fertilizedSector)
        )
        ?.filter(
          (fertilizedSector: FertilizationSector) =>
            !fertilizedSector?.sector?.disabledDate
        ) || [];
    this.applicator = new AgroBusinessPhytosanitaryInvolvedEntity().mapToClass(
      obj?.applicator
    );
    this.tool = new Tool().mapToClass(obj?.tool);
    this.manureTreatment = new ManureTreatment().mapToClass(
      obj?.manureTreatment
    );
    this.irrigationSystem = new IrrigationSystemType().mapToClass(
      obj?.irrigationSystem
    );
    this.waterQuantity = obj?.waterQuantity;
    this.waterQuantityUnit = new MeasurementUnit().mapToClass(
      obj?.waterQuantityUnit
    );
    this.waterSourceTypes = obj?.waterSourceTypes?.map((waterSourceType: any) =>
      new IrrigationWaterSourceType().mapToClass(waterSourceType)
    );
    this.irrigationCounterId = obj?.irrigationCounterId;
    this.irrigationGoodPracticesDeclared = obj?.irrigationGoodPracticesDeclared;
    this.irrigationGoodPractices = obj?.irrigationGoodPractices?.map(
      (irrigationGoodPractice: any) =>
        new IrrigationGoodPractice().mapToClass(irrigationGoodPractice)
    );
    this.fertigationNitrogenDose = obj?.fertigationNitrogenDose;
    this.fertigationNitrogenDoseUnit = new MeasurementUnit().mapToClass(
      obj?.fertigationNitrogenDoseUnit
    );
    this.fertigationPhosphorusDose = obj?.fertigationPhosphorusDose;
    this.fertigationPhosphorusDoseUnit = new MeasurementUnit().mapToClass(
      obj?.fertigationPhosphorusDoseUnit
    );
    this.siexId = obj?.siexId;
    this.siexStatus = obj?.siexStatus;
    this.siexSentOn = obj?.siexSentOn;
    this.siexBookId = obj?.siexBookId;
    this.siexRequestCode = obj?.siexRequestCode;
    this.siexRequestId = obj?.siexRequestId;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.crop = this.crop?.mapToApiModel();
    obj.stockMovements = this.stockMovements?.map(
      (stockMovement: FertilizerStockMovement) => stockMovement.mapToApiModel()
    );
    obj.doseUnit = this.doseUnit?.mapToApiModel();
    obj.type = this.type?.mapToApiModel();
    obj.applicationMethod = this.applicationMethod?.mapToApiModel();
    obj.advisor = this.advisor?.mapToApiModel();
    obj.fertigationWaterMix = this.fertigationWaterMix?.mapToApiModel();
    obj.goodPractices = this.goodPractices?.map(
      (goodPractice: FertilizationGoodPracticeType) =>
        goodPractice.mapToApiModel()
    );
    obj.fertilizedSectors = this.fertilizedSectors?.map(
      (fertilizedSector: FertilizationSector) =>
        fertilizedSector.mapToApiModel()
    );
    obj.applicator = this.applicator?.mapToApiModel();
    obj.tool = this.tool?.mapToApiModel();
    obj.manureTreatment = this.manureTreatment?.mapToApiModel();
    obj.irrigationSystem = this.irrigationSystem?.mapToApiModel();
    obj.waterQuantityUnit = this.waterQuantityUnit?.mapToApiModel();
    obj.waterSourceTypes = this.waterSourceTypes?.map(
      (waterSourceType: IrrigationWaterSourceType) =>
        waterSourceType.mapToApiModel()
    );
    obj.irrigationGoodPractices = this.irrigationGoodPractices?.map(
      (irrigationGoodPractice: IrrigationGoodPractice) =>
        irrigationGoodPractice.mapToApiModel()
    );
    obj.fertigationNitrogenDoseUnit =
      this.fertigationNitrogenDoseUnit?.mapToApiModel();
    obj.fertigationPhosphorusDoseUnit =
      this.fertigationPhosphorusDoseUnit?.mapToApiModel();
    return obj;
  }

  isFertirrigation(): boolean {
    return ["5", "6"].includes(this.applicationMethod?.code || "");
  }
}

export default FBFertilization;
