import FBRegisteredFertilizerProduct from "../_apiModels/vademecumFertiliz/FBRegisteredFertilizerProduct";

class RegisteredFertilizerProduct extends FBRegisteredFertilizerProduct {
  constructor() {
    super();
  }

  mapToClass(obj: any): RegisteredFertilizerProduct | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    return obj;
  }

  isOrganic(): boolean {
    return this?.code === "500";
  }

  isInorganic(): boolean {
    return this?.code === "0";
  }

  isCustom(): boolean {
    return this.isOrganic() || this.isInorganic();
  }
}

export default RegisteredFertilizerProduct;
