import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, useFormik, FormikTouched } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import * as Yup from "yup";
import i18n from "../../config/configI18n";
import moment from "moment";

import {
  FormGroup,
  FormControl,
  Tabs,
  Tab,
  RadioGroup,
  Radio,
  FormControlLabel,
  Collapse,
  AppBar,
} from "@mui/material";
import { Dashboard, Grass, FactCheck, Search } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import DialogCropSector from "../../components/dialogs/DialogCropSector";
import DialogQualityRegime from "../../components/dialogs/DialogQualityRegime";
import DialogCropHarvestHelper, {
  ReproductionMaterialInterface,
} from "../../components/dialogs/DialogCropHarvestHelper";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";
import FormikListItemSwitch from "../../components/elements/FormikListItemSwitch";
import PartitionForm from "../../components/forms/PartitionForm";
import TestBanner from "../../components/banners/TestBanner";
import SiexBanner from "../../components/banners/SiexBanner";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";
import SectionTutorial from "../../components/elements/SectionTutorial";
import SiexDownloadStatusBanner from "../../components/banners/SiexDownloadStatusBanner";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import Crop from "../../models/crop/Crop";
import CropSector from "../../models/crop/CropSector";
import CropProduct from "../../models/catalogue/CropProduct";
import { Column } from "../../models/Generic";
import Document from "../../models/files/Document";

import CropProductVariety from "../../models/catalogue/CropProductVariety";
import AgroActivity from "../../models/catalogue/AgroActivity";
import AgroActivityDetail from "../../models/catalogue/AgroActivityDetail";
import CropSystem from "../../models/catalogue/CropSystem";
import CropDestination from "../../models/catalogue/CropDestination";
import ReproductiveMaterialType from "../../models/catalogue/ReproductiveMaterialType";
import ReproductiveMaterialDetail from "../../models/catalogue/ReproductiveMaterialDetail";
import SeedProductionCompany from "../../models/catalogue/SeedProductionCompany";
import QualityRegime from "../../models/catalogue/QualityRegime";
import IrrigationWaterSourceType from "../../models/catalogue/IrrigationWaterSourceType";
import IrrigationSystemType from "../../models/catalogue/IrrigationSystemType";
import IrrigationGoodPractice from "../../models/catalogue/IrrigationGoodPractice";
import LandCoverType from "../../models/catalogue/LandCoverType";

import {
  SectionTutorialInterface,
  SnackbarInterface,
} from "../../constants/interfaces";
import { ExploitationSystemType, FormMode } from "../../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";
import { PROTECTED_ROUTES } from "../../routes/routeNames";
import { ENERGY_TYPES } from "../../providers/YupProvider";

import {
  addItemToListIfNotExistsByField,
  updateItemOfList,
  deleteItemsOfListByField,
} from "../../helpers/utils";

const ExploitationCropsScreen = () => {
  const cropColumns: Column[] = [
    {
      id: "description",
      label: i18n.t("crops.column.name"),
      numeric: false,
    },
    {
      id: "exploitationSystem.name",
      label: i18n.t("crops.column.exploitationSystem"),
      numeric: false,
    },
    {
      id: "qualityRegimes",
      label: i18n.t("crops.column.qualityRegimes"),
      numeric: true,
      valueLabel: "count",
    },
    {
      id: "cropSectors",
      label: i18n.t("crops.column.cropSectors"),
      numeric: true,
      valueLabel: "array",
      arrayFieldName: "sector.name",
    },
  ];

  const tutorialSteps: SectionTutorialInterface[] = [
    {
      label: i18n.t("crops.tutorial.createSectors.label"),
      description: i18n.t("crops.tutorial.createSectors.description"),
      nextBtnText: i18n.t("crops.tutorial.createSectors.nextBtnText"),
    },
  ];

  const YupValidatorSchema = Yup.object().shape({
    product: Yup.object({
      code: Yup.number().required(),
    }).required(),
    agroActivityDetail: Yup.object().when("agroActivity", {
      is: (agroActivity: AgroActivity) =>
        agroActivity && agroActivity.code && agroActivity.code !== "5",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const validateCustomFields = (values: Crop) => {
    let errors: any = {};
    const errorMsg = i18n.t("formErrors.requiredField");
    if (
      values.product?.id &&
      (!values.varieties ||
        values.varieties?.length === 0 ||
        !values.varieties[0])
    )
      errors.varieties = errorMsg;

    return errors;
  };

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [isOpenAttachSectorsAlertDialog, setIsOpenAttachSectorsAlertDialog] =
    useState<boolean>(false);
  const [isOpenHarvestTradeHelperDialog, setIsOpenHarvestTradeHelperDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const { data: crops, isFetching } = useFetch<Crop[]>({
    queryKey: ["crops", selectedABAccount?.id, selectedABPartition?.id],
    enabled: !!selectedABAccount?.id,
    onError: setSnackbarMsg,
  });

  const selected = useMemo(
    () =>
      formMode === FormMode.EDIT && selectedRowIds.length === 1
        ? crops?.find((c: Crop) => c.id === selectedRowIds[0])
        : undefined,
    [selectedRowIds, formMode, crops]
  );

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );
  const isSelectedSiexSync = useMemo(
    () => selected?.siexEntity === true,
    [selected]
  );

  const { data: sectorsCount, isFetching: isFetchingSectors } =
    useFetch<number>({
      queryKey: [
        "sectorsCount",
        selectedABAccount?.id,
        selectedABPartition?.id,
      ],
      enabled: !!selectedABAccount?.id,
    });

  const handleSubmit = (values: Crop) => {
    switch (formMode) {
      case FormMode.CREATE:
        if (
          sectorsCount &&
          sectorsCount > 0 &&
          (!values.cropSectors || values.cropSectors.length === 0)
        )
          openAttachSectorsAlertDialog();
        else openHarvestTradeHelperDialog();
        break;
      case FormMode.EDIT:
        cropEditMutation.mutate({});
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new Crop(),
    validationSchema: YupValidatorSchema,
    validate: validateCustomFields,
    onSubmit: handleSubmit,
  });
  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("crops.drawerTitleCreate")
      : i18n.t("crops.drawerTitleEdit");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  const clearForm = () => {
    setTabValue(0);
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    setTabValue(0);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);
  const openAttachSectorsAlertDialog = () =>
    setIsOpenAttachSectorsAlertDialog(true);
  const cancelAttachSectorsAlertDialog = () =>
    setIsOpenAttachSectorsAlertDialog(false);
  const openHarvestTradeHelperDialog = () =>
    setIsOpenHarvestTradeHelperDialog(true);
  const cancelHarvestTradeHelperDialog = () =>
    setIsOpenHarvestTradeHelperDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const cropCreateMutation = useCrud<Crop>({
    key: "postCrop",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const cropEditMutation = useCrud<Crop>({
    key: "putCrop",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const cropDeleteMutation = useCrud({
    key: "deleteCrops",
    values: crops?.filter((c: Crop) => selectedRowIds.includes(c?.id || 0)),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      // If there are errors, set respective tab
      setTabValue(0); //TODO: Generalize
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormSuccess = (msg: string) => {
    setSnackbarMsg({ severity: "success", message: msg });
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    cropDeleteMutation.mutate({});
  };

  const handleConfirmAttachSectorsAlertDialog = () => {
    cancelAttachSectorsAlertDialog();
    openHarvestTradeHelperDialog();
  };

  const handleCancelAttachSectorsAlertDialog = () => {
    cancelAttachSectorsAlertDialog();
    setTabValue(1);
  };

  const handleConfirmCropHarvestHelperDialog = (
    reproductionMaterialValues: ReproductionMaterialInterface
  ) => {
    formik.setFieldValue(
      "reproductiveMaterialType",
      reproductionMaterialValues.reproductiveMaterialType
    );
    formik.setFieldValue(
      "permanentCrop",
      reproductionMaterialValues.permanentCrop
    );
    formik.setFieldValue(
      "sowingPlantationStartDate",
      reproductionMaterialValues.sowingPlantationStartDate
    );
    formik.setFieldValue(
      "sowingPlantationEndDate",
      reproductionMaterialValues.sowingPlantationEndDate
    );
    formik.setFieldValue(
      "plantingYear",
      reproductionMaterialValues.plantingYear
    );
    formik.setFieldValue(
      "sowingVsPlantation",
      reproductionMaterialValues.sowingVsPlantation
    );
    formik.setFieldValue(
      "helpUserMarketHarvest",
      reproductionMaterialValues.helpUserMarketHarvest
    );
    cancelHarvestTradeHelperDialog();
    cropCreateMutation.mutate({});
  };

  const handleChangeTabValue = (tabValue: number) => {
    setTabValue(tabValue);
  };

  const handleClickCreateSector = () => {
    navigate(`/${PROTECTED_ROUTES.EXPLOITATION_SECTORS}`);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching || isFetchingSectors} />
      <TestBanner />
      <SiexBanner />
      {sectorsCount === 0 && (
        <SectionTutorial
          steps={tutorialSteps}
          activeStep={0}
          onGo={handleClickCreateSector}
        />
      )}
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("crops.backAlertTitle")}
        contentText={i18n.t("crops.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("crops.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("crops.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={cropDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <FormAlertDialog
        id="attachSectorsAlertDialog"
        title={i18n.t("crops.attachSectorsAlertDialogTitle")}
        contentText={i18n.t("crops.attachSectorsAlertDialogContentText")}
        open={isOpenAttachSectorsAlertDialog}
        formAction={formMode}
        confirmBtnText="Continuar"
        cancelBtnText="Volver"
        onCancel={handleCancelAttachSectorsAlertDialog}
        onConfirm={handleConfirmAttachSectorsAlertDialog}
      />
      <DialogCropHarvestHelper
        open={isOpenHarvestTradeHelperDialog}
        isLoading={cropCreateMutation.isLoading}
        onCancel={cancelHarvestTradeHelperDialog}
        onConfirm={handleConfirmCropHarvestHelperDialog}
        onError={setSnackbarMsg}
      />
      <TableBrioCard
        title={i18n.t("crops.tableTitle")}
        headerText={i18n.t("crops.headerText")}
        colums={cropColumns}
        rows={crops}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Grass sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("crops.emptyTableTitle")}
        emptyTableSubtitle={
          selectedABAccount?.agroBusiness?.siexEntity
            ? i18n.t("crops.emptyTableSubtitleSIEX")
            : i18n.t("crops.emptyTableSubtitle")
        }
        emptyTableBtnText={
          !selectedABAccount?.agroBusiness?.siexEntity
            ? i18n.t("crops.emptyTableBtnText")
            : undefined
        }
        isLoading={isFetching}
        hideBtn={selectedABAccount?.agroBusiness?.siexEntity}
        addBtnVariant="contained"
        siexRows
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={cropCreateMutation.isLoading || cropEditMutation.isLoading}
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <CropsForm
          formik={formik}
          tabValue={tabValue}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          selectedEditRow={selected}
          isSiexActive={isSiexActive}
          isSelectedSiexSync={isSelectedSiexSync}
          onSuccess={handleOnFormSuccess}
          onChangeTabValue={handleChangeTabValue}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationCropsScreen;

interface CropsFormProps {
  formik: FormikProps<Crop>;
  tabValue: number;
  isSiexActive: boolean;
  isSelectedSiexSync: boolean;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: Crop;
  onChangeTabValue: (tabValue: number) => void;
  onSuccess?: (msg: string) => void;
  onCloseFilesForm?: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const CropsForm = (props: CropsFormProps) => {
  const {
    tabValue,
    formik,
    selectedEditRow,
    isOpenFilesForm = false,
    isSiexActive,
    isSelectedSiexSync,
    onCloseFilesForm,
    onChangeTabValue,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition, agroBusinessPartitions } =
    useSession();
  const queryClient = useQueryClient();

  const onFetchCropSuccess = (crop: Crop) => {
    formik.setValues(crop);
    // Update crop in array without refetch
    queryClient.setQueryData<Crop[]>(
      ["crops", selectedABAccount?.id, selectedABPartition?.id],
      (oldData) => updateItemOfList(oldData, crop, "id")
    );
  };

  const { isFetching: isUpdating } = useFetch<Crop>({
    queryKey: ["crop", selectedEditRow?.id],
    selected: selectedEditRow,
    enabled: !!selectedEditRow?.id,
    onSuccess: onFetchCropSuccess,
    onError,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    onChangeTabValue(newValue);
  };

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  const renderTab = () => {
    switch (tabValue) {
      case 0:
        return <CropTab {...props} />;
      case 1:
        return <CropSectorsTab {...props} />;
      case 2:
        return <QualityRegimeTab {...props} />;
      default:
        return <div></div>;
    }
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      {isSiexActive ? (
        <SiexDownloadStatusBanner
          isSync={isSelectedSiexSync}
          timestamp={selectedEditRow?.siexUpdatedOn}
        />
      ) : (
        <SiexBanner adviceText />
      )}
      <AppBar
        className="tabbar"
        component="nav"
        position="sticky"
        elevation={0}
      >
        <Tabs
          className="tab-container-sticky"
          variant="scrollable"
          scrollButtons="auto"
          value={tabValue}
          onChange={handleTabChange}
        >
          <Tab
            className="tab"
            wrapped
            label={i18n.t("crops.tab.crop")}
            icon={<Grass />}
          />
          <Tab
            className="tab"
            wrapped
            label={i18n.t("crops.tab.sectors")}
            icon={<Dashboard />}
          />
          <Tab
            className="tab"
            wrapped
            label={i18n.t("crops.tab.quality")}
            icon={<FactCheck />}
          />
        </Tabs>
      </AppBar>

      {renderTab()}

      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("crops.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />

      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </>
  );
};

const CropTab = (props: CropsFormProps) => {
  return (
    <>
      <DefinitionForm {...props} />
      <ExploitationSystemTypeForm {...props} />
      <AgroActivityForm {...props} />
      <CropDataForm {...props} />
      <ReproductionMaterialForm {...props} />
    </>
  );
};

const DefinitionForm = (props: CropsFormProps) => {
  const { formik, forceExpanded, isSelectedSiexSync, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: cropProducts, isFetching: isFetchingProducts } = useFetch<
    CropProduct[]
  >({
    queryKey: ["cropProducts", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount,
    onError,
  });

  const { data: varieties, isFetching: isFetchingVarieties } = useFetch<
    CropProductVariety[]
  >({
    queryKey: ["varieties", formik.values.product?.id],
    selected: formik.values?.product?.id,
    enabled: !!formik.values.product?.id,
    onError,
  });

  useEffect(() => {
    if (
      varieties &&
      varieties?.[0]?.productType?.id !==
        formik.values.varieties?.[0]?.productType?.id
    ) {
      formik.setFieldValue(
        "varieties",
        varieties?.length === 1 ? varieties : []
      );
    }
  }, [varieties]);

  const handleChangeVarieties = (
    options: CropProductVariety[] | CropProductVariety
  ) => {
    formik.setFieldValue(
      "varieties",
      options instanceof Array ? options : [options]
    );
  };

  return (
    <BrioCard
      title={i18n.t("crops.definitionForm.title")}
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            <FormikAutocomplete
              formik={formik}
              name="product"
              label={i18n.t("crops.definitionForm.product")}
              placeholder={i18n.t("crops.definitionForm.productPlaceholder")}
              options={cropProducts || []}
              optionLabelFieldName="name"
              required
              startIcon={Search}
              loading={isFetchingProducts}
              disabled={isSelectedSiexSync}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <FormikAutocomplete
              formik={formik}
              name="varieties"
              label={i18n.t("crops.definitionForm.varieties")}
              placeholder={i18n.t("crops.definitionForm.varietiesPlaceholder")}
              helperText={i18n.t("crops.definitionForm.varietiesHelperText")}
              disabled={!formik.values.product || isSelectedSiexSync}
              required
              multiple={formik.values.product?.fruity}
              options={varieties || []}
              optionLabelFieldName="name"
              loading={isFetchingVarieties}
              onChange={handleChangeVarieties}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <FormikTextField
              formik={formik}
              name="name"
              label={i18n.t("crops.definitionForm.name")}
            />
          </FormControl>
        </FormGroup>
      </FormGroup>
    </BrioCard>
  );
};

const ExploitationSystemTypeForm = (props: CropsFormProps) => {
  const { formik, isSelectedSiexSync, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: cropSystemTypes, isFetching: isFetchingCropSystemTypes } =
    useFetch<IrrigationSystemType[]>({
      queryKey: ["cropSystemTypes", selectedABAccount?.context?.id],
      enabled: !!formik.values.product,
      onError,
    });

  const { data: irrigationSystemTypes, isFetching: isFetchingIrrigationTypes } =
    useFetch<IrrigationSystemType[]>({
      queryKey: ["irrigationSystemTypes", selectedABAccount?.context?.id],
      enabled: !!formik.values.product,
      onError,
    });

  const { data: irrigationWaterSourceTypes, isFetching: isFetchingIWST } =
    useFetch<IrrigationWaterSourceType[]>({
      queryKey: ["irrigationWaterSourceTypes", selectedABAccount?.context?.id],
      enabled: !!formik.values.product,
      onError,
    });

  const { data: irrigationGoodPractices, isFetching: isFetchingIGPS } =
    useFetch<IrrigationGoodPractice[]>({
      queryKey: ["irrigationGoodPractices", selectedABAccount?.context?.id],
      enabled: !!formik.values.product,
      onError,
    });

  const handleChangeExploitationSystemType = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const code = event.target.value as ExploitationSystemType;
    const exploitationSystem = cropSystemTypes?.find(
      (est) => est.code === code
    );
    if (exploitationSystem)
      formik.setFieldValue("exploitationSystem", exploitationSystem);
  };

  return (
    <BrioCard title={i18n.t("crops.systemTypeForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl
          variant="outlined"
          className="form-control"
          sx={{
            marginBottom:
              formik.values.exploitationSystem?.code ===
              ExploitationSystemType.Irrigated
                ? "20px"
                : "0px !important",
          }}
        >
          {!isFetchingCropSystemTypes && (
            <RadioGroup
              className="radio-group"
              row
              value={formik.values.exploitationSystem?.code || null}
              onChange={handleChangeExploitationSystemType}
            >
              <FormControlLabel
                value={ExploitationSystemType.Irrigated}
                control={<Radio />}
                label={i18n.t("crops.systemTypeForm.irrigated")}
                disabled={!formik.values.product || isSelectedSiexSync}
              />
              <FormControlLabel
                value={ExploitationSystemType.Rainfed}
                control={<Radio />}
                label={i18n.t("crops.systemTypeForm.rainfed")}
                disabled={!formik.values.product || isSelectedSiexSync}
              />
            </RadioGroup>
          )}
        </FormControl>
        <Collapse
          className="collapse-container"
          in={
            formik.values.exploitationSystem?.code ===
            ExploitationSystemType.Irrigated
          }
        >
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikSelect
                formik={formik}
                name="irrigationType"
                label={i18n.t("crops.systemTypeForm.irrigationType")}
                optionLabelFieldName="name"
                options={irrigationSystemTypes || []}
                isLoading={isFetchingIrrigationTypes}
                disabled={!formik.values.product || isSelectedSiexSync}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikSelect
                multiple
                formik={formik}
                name="irrigationWaterSourceTypes"
                label={i18n.t(
                  "crops.systemTypeForm.irrigationWaterSourceTypes"
                )}
                optionLabelFieldName="name"
                options={irrigationWaterSourceTypes || []}
                isLoading={isFetchingIWST}
                disabled={!formik.values.product || isSelectedSiexSync}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikSelect
                multiple
                formik={formik}
                name="irrigationGoodPractices"
                label={i18n.t("crops.systemTypeForm.irrigationGoodPractices")}
                optionLabelFieldName="description"
                options={irrigationGoodPractices || []}
                isLoading={isFetchingIGPS}
                disabled={!formik.values.product || isSelectedSiexSync}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikSelect
                formik={formik}
                name="irrigationEType"
                label={i18n.t("crops.systemTypeForm.irrigationEType")}
                optionLabelFieldName="name"
                options={ENERGY_TYPES}
                disabled={!formik.values.product || isSelectedSiexSync}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};

const AgroActivityForm = (props: CropsFormProps) => {
  const { formik, isSelectedSiexSync, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: agroActivities, isFetching: isFetchingAgroactivities } =
    useFetch<AgroActivity[]>({
      queryKey: ["agroActivities", selectedABAccount?.context?.id],
      enabled: !!selectedABAccount && !!formik.values.product,
      onError,
    });

  const {
    data: agroActivityDetails,
    isFetching: isFetchingAgroActivityDetails,
  } = useFetch<AgroActivityDetail[]>({
    queryKey: ["agroActivityDetails", formik.values.agroActivity?.code],
    selected: formik.values.agroActivity?.code,
    enabled: !!formik.values.agroActivity?.code,
    onError,
  });

  useEffect(() => {
    if (
      agroActivityDetails &&
      agroActivityDetails?.[0]?.agroActivity?.code !==
        formik.values.agroActivityDetail?.agroActivity?.code
    ) {
      formik.setFieldValue(
        "agroActivityDetail",
        agroActivityDetails.length === 1 ? agroActivityDetails[0] : null
      );
    }
  }, [agroActivityDetails]);

  return (
    <BrioCard title={i18n.t("crops.agroActivityForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl
          variant="outlined"
          className="form-control"
          sx={{
            marginBottom:
              agroActivityDetails &&
              agroActivityDetails.length > 0 &&
              !!agroActivityDetails[0].name
                ? "20px"
                : "0px !important",
          }}
        >
          <FormikSelect
            formik={formik}
            name="agroActivity"
            label={i18n.t("crops.agroActivityForm.agroActivity")}
            optionLabelFieldName="name"
            options={agroActivities || []}
            isLoading={isFetchingAgroactivities}
            disabled={!formik.values.product || isSelectedSiexSync}
          />
        </FormControl>
        <Collapse
          className="collapse-container"
          in={
            agroActivityDetails &&
            agroActivityDetails.length > 0 &&
            !!agroActivityDetails[0].name
          }
        >
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikSelect
                formik={formik}
                name="agroActivityDetail"
                label={i18n.t("crops.agroActivityForm.agroActivityDetail")}
                optionLabelFieldName="name"
                options={agroActivityDetails || []}
                isLoading={isFetchingAgroActivityDetails}
                disabled={isSelectedSiexSync}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};

const CropDataForm = (props: CropsFormProps) => {
  const { formik, isSelectedSiexSync, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: cropSystems, isFetching: isFetchingCropSystems } = useFetch<
    CropSystem[]
  >({
    queryKey: ["cropSystems", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount && !!formik.values.product,
    onError,
  });

  const {
    data: organicCertificationTypes,
    isFetching: isFetchingOrganicCertificationTypes,
  } = useFetch<CropSystem[]>({
    queryKey: ["organicCertificationTypes", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount && !!formik.values.product,
    onError,
  });

  const { data: cropDestinations, isFetching: isFetchingCropDestinations } =
    useFetch<CropDestination[]>({
      queryKey: ["cropDestinations", selectedABAccount?.context?.id],
      enabled: !!selectedABAccount && !!formik.values.product,
      onError,
    });

  const { data: landCoverTypes, isFetching: isFetchingLandCoverTypes } =
    useFetch<LandCoverType[]>({
      queryKey: ["landCoverTypes", selectedABAccount?.context?.id],
      enabled: !!selectedABAccount && !!formik.values.product,
      onError,
    });

  return (
    <BrioCard title={i18n.t("crops.cropDataForm.title")} optional>
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="cropSystem"
            label={i18n.t("crops.cropDataForm.cropSystem")}
            options={cropSystems || []}
            optionLabelFieldName="name"
            loading={isFetchingCropSystems}
            disabled={!formik.values.product || isSelectedSiexSync}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="organicCertification"
            label={i18n.t("crops.cropDataForm.organicCertification")}
            optionLabelFieldName="name"
            options={organicCertificationTypes || []}
            isLoading={isFetchingOrganicCertificationTypes}
            disabled={!formik.values.product || isSelectedSiexSync}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="cropDestination"
            label={i18n.t("crops.cropDataForm.cropDestination")}
            options={cropDestinations || []}
            optionLabelFieldName="name"
            loading={isFetchingCropDestinations}
            disabled={!formik.values.product || isSelectedSiexSync}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="landCoverTypes"
            label={i18n.t("crops.cropDataForm.landCoverTypes")}
            placeholder={i18n.t("crops.cropDataForm.landCoverTypesPlaceholder")}
            multiple
            options={landCoverTypes || []}
            optionLabelFieldName="name"
            loading={isFetchingLandCoverTypes}
            disabled={!formik.values.product || isSelectedSiexSync}
          />
        </FormControl>
        <FormControl className="form-control">
          <FormikListItemSwitch
            formik={formik}
            name="integratedProduction"
            primaryText={i18n.t("crops.cropDataForm.integratedProduction")}
            secondaryText={i18n.t(
              "crops.cropDataForm.integratedProductionSubtitle"
            )}
            disabled={isSelectedSiexSync}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const ReproductionMaterialForm = (props: CropsFormProps) => {
  const { formik, isSelectedSiexSync, onError } = props;

  const { selectedABAccount } = useSession();

  const handleReproductiveMaterials = (
    reproductiveMaterials: ReproductiveMaterialType[]
  ) => {
    if (
      !formik.values.reproductiveMaterialType &&
      reproductiveMaterials.length > 0
    ) {
      const plantType = reproductiveMaterials.find((rm) => rm.code === "2");
      formik.setFieldValue(
        "reproductiveMaterialType",
        plantType || reproductiveMaterials[0]
      );
    }
  };

  const {
    data: reproductiveMaterials,
    isFetching: isFetchingReproductiveMaterials,
  } = useFetch<ReproductiveMaterialType[]>({
    queryKey: ["reproductiveMaterials", selectedABAccount?.context?.id],
    enabled: !!selectedABAccount && !!formik.values.product,
    onSuccess: handleReproductiveMaterials,
    onError,
  });

  const {
    data: reproductiveMaterialDetails,
    isFetching: isFetchingReproductiveMaterialDetails,
  } = useFetch<ReproductiveMaterialDetail[]>({
    queryKey: [
      "reproductiveMaterialDetails",
      formik.values.reproductiveMaterialType?.code,
    ],
    selected: formik.values.reproductiveMaterialType?.code,
    enabled: !!formik.values.reproductiveMaterialType?.code,
  });

  const {
    data: seedProductionCompanies,
    isFetching: isFetchingSeedProductionCompanies,
  } = useFetch<SeedProductionCompany[]>({
    queryKey: [
      "seedProductionCompanies",
      formik.values.reproductiveMaterialType?.code,
    ],
    enabled: formik.values.reproductiveMaterialType?.code === "1",
  });

  const handleChangeReproductiveMaterial = (
    option: ReproductiveMaterialType
  ) => {
    formik.setFieldValue("reproductiveMaterialType", option);
    formik.setFieldValue("reproductiveMaterialDetail", null);
    formik.setFieldValue("sowingVsPlantation", option?.code === "1");
    if (option?.code === "1") formik.setFieldValue("permanentCrop", false);
  };

  const handleChangePermanentCrop = (checked: boolean) => {
    formik.setFieldValue("permanentCrop", checked);
    if (checked) {
      formik.setFieldValue("sowingPlantationStartDate", null);
      formik.setFieldValue("sowingPlantationEndDate", null);
    } else formik.setFieldValue("plantingYear", null);
  };

  return (
    <BrioCard
      title={i18n.t("crops.reproductionMaterialForm.title_v2")}
      optional
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="reproductiveMaterialType"
            label={i18n.t(
              "crops.reproductionMaterialForm.reproductiveMaterialType"
            )}
            optionLabelFieldName="name"
            options={reproductiveMaterials || []}
            isLoading={isFetchingReproductiveMaterials}
            disabled={!formik.values.product || isSelectedSiexSync}
            onChange={handleChangeReproductiveMaterial}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="reproductiveMaterialDetail"
            label={i18n.t(
              "crops.reproductionMaterialForm.reproductiveMaterialDetail"
            )}
            options={reproductiveMaterialDetails || []}
            optionLabelFieldName="name"
            loading={isFetchingReproductiveMaterialDetails}
            disabled={
              !formik.values.reproductiveMaterialType || isSelectedSiexSync
            }
          />
        </FormControl>
        {formik.values?.reproductiveMaterialType?.code === "1" ? (
          <>
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name="seedQuantity"
                label={i18n.t("crops.reproductionMaterialForm.seedQuantity")}
                type="number"
                valueUnit="kg"
                disabled={isSelectedSiexSync}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikAutocomplete
                formik={formik}
                name="seedProductionCompany"
                label={i18n.t(
                  "crops.reproductionMaterialForm.seedProductionCompany"
                )}
                placeholder={i18n.t(
                  "crops.reproductionMaterialForm.seedProductionCompanyPlaceholder"
                )}
                options={seedProductionCompanies || []}
                optionLabelFieldName="name"
                startIcon={Search}
                loading={isFetchingSeedProductionCompanies}
                disabled={isSelectedSiexSync}
              />
            </FormControl>
          </>
        ) : (
          <>
            <FormControl variant="outlined" className="form-control-row">
              <FormikTextField
                formik={formik}
                className="form-input-row"
                name="plantationFrameHorizontal"
                label={i18n.t(
                  "crops.reproductionMaterialForm.plantationFrameHorizontal"
                )}
                placeholder={i18n.t(
                  "crops.reproductionMaterialForm.plantationFrameHorizontalPlaceholder"
                )}
                type="number"
                disabled={isSelectedSiexSync}
              />
              <FormikTextField
                formik={formik}
                className="form-input-row"
                name="plantationFrameVertical"
                label={i18n.t(
                  "crops.reproductionMaterialForm.plantationFrameVertical"
                )}
                placeholder={i18n.t(
                  "crops.reproductionMaterialForm.plantationFrameVerticalPlaceholder"
                )}
                type="number"
                disabled={isSelectedSiexSync}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name="plantDensity"
                label={i18n.t("crops.reproductionMaterialForm.plantDensity")}
                placeholder={i18n.t(
                  "crops.reproductionMaterialForm.plantDensityPlaceholder"
                )}
                type="number"
                valueUnit={i18n.t(
                  "crops.reproductionMaterialForm.plantDensityUnit"
                )}
                disabled={isSelectedSiexSync}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                name="plantNumber"
                label={i18n.t("crops.reproductionMaterialForm.plantNumber")}
                placeholder={i18n.t(
                  "crops.reproductionMaterialForm.plantNumberPlaceholder"
                )}
                type="number"
                disabled={isSelectedSiexSync}
              />
            </FormControl>
          </>
        )}
        <Collapse in={!formik.values.sowingVsPlantation}>
          <div className="grow-container">
            <FormControl
              className="form-control"
              sx={{ mb: "10px !important" }}
            >
              <FormikListItemSwitch
                formik={formik}
                name="permanentCrop"
                primaryText={i18n.t("crops.datesForm.permanentCrop")}
                secondaryText={i18n.t("crops.datesForm.permanentCropSubtitle")}
                disabled={isSelectedSiexSync}
                onChangeSwitch={handleChangePermanentCrop}
              />
            </FormControl>
          </div>
        </Collapse>
        <Collapse
          className="collapse-container"
          in={formik.values.permanentCrop}
        >
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <DatePicker
                className="form-input"
                format="YYYY"
                label={
                  formik.values.sowingVsPlantation
                    ? i18n.t("crops.datesForm.sowingYear")
                    : i18n.t("crops.datesForm.plantingYear")
                }
                view="year"
                views={["year"]}
                disableFuture
                closeOnSelect
                value={
                  formik.values.plantingYear
                    ? moment(formik.values.plantingYear, "YYYY")
                    : null
                }
                disabled={isSelectedSiexSync}
                onChange={(date) =>
                  formik.setFieldValue("plantingYear", date?.year())
                }
              />
            </FormControl>
          </div>
        </Collapse>
        <Collapse
          className="collapse-container"
          in={!formik.values.permanentCrop}
        >
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control-row">
              <DatePicker
                className="form-input-row"
                format="DD/MM/YYYY"
                label={
                  formik.values.sowingVsPlantation
                    ? i18n.t("crops.datesForm.sowingStartDate")
                    : i18n.t("crops.datesForm.plantationStartDate")
                }
                value={
                  formik.values.sowingPlantationStartDate
                    ? moment(
                        formik.values.sowingPlantationStartDate,
                        "YYYY-MM-DD"
                      )
                    : null
                }
                disabled={isSelectedSiexSync}
                onChange={(date) =>
                  formik.setFieldValue(
                    "sowingPlantationStartDate",
                    date?.format("YYYY-MM-DD")
                  )
                }
              />
              <DatePicker
                className="form-input-row"
                format="DD/MM/YYYY"
                label={
                  formik.values.sowingVsPlantation
                    ? i18n.t("crops.datesForm.sowingEndDate")
                    : i18n.t("crops.datesForm.plantationEndDate")
                }
                value={
                  formik.values.sowingPlantationEndDate
                    ? moment(
                        formik.values.sowingPlantationEndDate,
                        "YYYY-MM-DD"
                      )
                    : null
                }
                disabled={isSelectedSiexSync}
                onChange={(date) =>
                  formik.setFieldValue(
                    "sowingPlantationEndDate",
                    date?.format("YYYY-MM-DD")
                  )
                }
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};

const CropSectorsTab = (props: CropsFormProps) => {
  const cropSectorColumns: Column[] = [
    {
      id: "sector.name",
      label: i18n.t("crops.sectorsForm.column.name"),
      numeric: false,
    },
    {
      id: "area",
      label: i18n.t("crops.sectorsForm.column.area"),
      numeric: true,
      valueLabel: "unitValue",
      unitValue: "ha",
    },
    {
      id: "plantNumber",
      label: i18n.t("crops.sectorsForm.column.plantNumber"),
      numeric: true,
    },
  ];

  const { formik, isSelectedSiexSync } = props;

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedCropSector, setSelectedCropSector] =
    useState<CropSector | null>(null);
  const [formMode, setFormMode] = useState<FormMode>(FormMode.CREATE);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => {
    setIsOpenDialog(false);
    setSelectedRowIds([]);
    setSelectedCropSector(null);
  };

  const handleClickAdd = () => {
    setFormMode(FormMode.CREATE);
    openDialog();
  };

  // Only dialog to create or edit crop sector
  const handleConfirmDialog = (selected: CropSector) => {
    const updatedCropSectors =
      formMode === FormMode.CREATE
        ? addItemToListIfNotExistsByField(
            formik.values.cropSectors,
            selected,
            "idx"
          )
        : updateItemOfList(formik.values.cropSectors, selected, "idx");
    formik.setFieldValue("cropSectors", updatedCropSectors);
    closeDialog();
  };

  const handleClikEdit = (idx: number) => {
    setFormMode(FormMode.EDIT);
    const selected = formik.values.cropSectors?.find(
      (cs: CropSector) => cs.idx === idx
    );
    if (selected) {
      setSelectedCropSector(selected);
      openDialog();
    }
  };

  const handleClickDelete = (idxs: number[]) => {
    formik.setFieldValue(
      "cropSectors",
      deleteItemsOfListByField(formik.values.cropSectors, idxs, "idx")
    );
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        idx="idx"
        title={i18n.t("crops.sectorsForm.tableTitle")}
        headerText={i18n.t("crops.sectorsForm.headerText")}
        btnText={i18n.t("crops.sectorsForm.tableBtnText")}
        colums={cropSectorColumns}
        rows={formik.values.cropSectors || []}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        optional
        hideBtn={isSelectedSiexSync}
        disabled={isSelectedSiexSync}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClikEdit}
        onClickDelete={handleClickDelete}
      />
      <DialogCropSector
        open={isOpenDialog}
        selected={selectedCropSector}
        cropSectors={formik.values.cropSectors}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};

const qualityRegimeColumns: Column[] = [
  {
    id: "name",
    label: i18n.t("crops.qualityRegimeForm.column.name"),
    numeric: false,
  },
  {
    id: "category.name",
    label: i18n.t("crops.qualityRegimeForm.column.category"),
    numeric: false,
  },
  {
    id: "igType.name",
    label: i18n.t("crops.qualityRegimeForm.column.igType"),
    numeric: false,
  },
];
const QualityRegimeTab = (props: CropsFormProps) => {
  const { formik, isSelectedSiexSync } = props;

  const btnText = i18n.t("crops.qualityRegimeForm.tableBtnTextAdd");

  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  const openDialog = () => setIsOpenDialog(true);
  const closeDialog = () => setIsOpenDialog(false);

  const handleClickAdd = () => openDialog();

  const handleConfirmDialog = (selected: QualityRegime[]) => {
    formik.setFieldValue("qualityRegimes", selected);
    closeDialog();
  };

  const handleClickDelete = (ids: number[]) => {
    setSelectedRowIds([]);
    const updatedQualityRegimes = formik.values.qualityRegimes?.filter(
      (qr: QualityRegime) => !ids.includes(qr?.id || -1)
    );
    formik.setFieldValue("qualityRegimes", updatedQualityRegimes);
    closeDialog();
  };

  return (
    <>
      <TableBrioCard
        title={i18n.t("crops.qualityRegimeForm.tableTitle")}
        headerText={i18n.t("crops.qualityRegimeForm.headerText")}
        btnText={btnText}
        colums={qualityRegimeColumns}
        rows={formik.values.qualityRegimes || []}
        selectedRows={selectedRowIds}
        emptyTableCard={false}
        optional
        hideBtn={isSelectedSiexSync}
        disabled={isSelectedSiexSync}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickDelete={handleClickDelete}
      />
      <DialogQualityRegime
        open={isOpenDialog}
        selected={formik.values.qualityRegimes}
        onConfirm={handleConfirmDialog}
        onClose={closeDialog}
      />
    </>
  );
};
