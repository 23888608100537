import { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, useFormik, FormikTouched } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "../../config/configI18n";
import moment from "moment";

import { FormGroup, FormControl, Collapse } from "@mui/material";
import { Engineering, Search } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

import ScreenContentLayout from "../../components/ScreenContentLayout";
import { BrioCard, TableBrioCard } from "../../components/BrioCard";
import RightDrawer from "../../components/RightDrawer";
import FormikTextField from "../../components/elements/FormikTextField";
import FormikSelect from "../../components/elements/FormikSelect";
import FormikAutocomplete from "../../components/elements/FormikAutocomplete";
import AlertSnackbar from "../../components/elements/AlertSnackbar";
import FormAlertDialog from "../../components/dialogs/FormAlertDialog";
import FormikListItemSwitch from "../../components/elements/FormikListItemSwitch";
import TestBanner from "../../components/banners/TestBanner";
import PartitionForm from "../../components/forms/PartitionForm";
import FilesForm from "../../components/forms/FilesForm";
import AttachmentDocumentsBadge from "../../components/elements/AttachmentDocumentsBadge";
import LoadingWithDelay from "../../components/elements/LoadingWithDelay";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";
import useCrud from "../../hooks/useCrud";
import useExpandCards from "../../hooks/useExpandCards";

import AgroBusinessPhytosanitaryInvolvedEntity from "../../models/agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import Entity from "../../models/agrobusiness/Entity";
import { Column } from "../../models/Generic";
import Document from "../../models/files/Document";

import { updateItemOfList } from "../../helpers/utils";

import {
  EntityTypeInterface,
  SnackbarInterface,
} from "../../constants/interfaces";
import { FormMode, EntityType } from "../../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../../constants/constants";
import {
  ENTITY_SEX_TYPES,
  ENTITY_TYPES,
  PHYTOSANITARY_TRAINING_LEVELS,
} from "../../providers/YupProvider";
import { PROTECTED_ROUTES } from "../../routes/routeNames";

const ExploitationInvolvedEntitiesScreen = () => {
  const phytoEntitiesColumns: Column[] = [
    {
      id: "entity.nameAndSurname",
      label: i18n.t("involvedEntities.column.name"),
      numeric: false,
    },
    {
      id: "phytosanitaryAdvisor",
      label: i18n.t("involvedEntities.column.phytosanitaryAdvisor"),
      numeric: false,
      valueLabel: "boolean",
    },
    {
      id: "isAdvisorUser",
      label: i18n.t("involvedEntities.column.isAdvisorUser"),
      numeric: false,
      valueLabel: "boolean",
    },
    {
      id: "phytosanitaryApplicator",
      label: i18n.t("involvedEntities.column.phytosanitaryApplicator"),
      numeric: false,
      valueLabel: "boolean",
    },
    {
      id: "fertilizationAdvisor",
      label: i18n.t("involvedEntities.column.fertilizationAdvisor"),
      numeric: false,
      valueLabel: "boolean",
    },
    {
      id: "fertilizationApplicator",
      label: i18n.t("involvedEntities.column.fertilizationApplicator"),
      numeric: false,
      valueLabel: "boolean",
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedABAccount, selectedABPartition } = useSession();
  const [expandCards, forceExpandCards] = useExpandCards();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [isOpenFilesForm, setIsOpenFilesForm] = useState<boolean>(false);

  const { data: phytoInvolvedEntities, isFetching } = useFetch<
    AgroBusinessPhytosanitaryInvolvedEntity[]
  >({
    queryKey: [
      "phytoInvolvedEntities",
      selectedABAccount?.id,
      selectedABPartition?.id,
    ],
    enabled: !!selectedABAccount,
  });

  const selected = useMemo(
    () =>
      formMode === FormMode.EDIT && selectedRowIds.length === 1
        ? phytoInvolvedEntities?.find(
            (pie: AgroBusinessPhytosanitaryInvolvedEntity) =>
              pie.id === selectedRowIds[0]
          )
        : undefined,
    [selectedRowIds, formMode, phytoInvolvedEntities]
  );

  const isSiexActive = useMemo(
    () => selectedABAccount?.agroBusiness?.siexEntity === true,
    [selectedABAccount]
  );

  const validateEntityForm = (values: Entity) => {
    const errors: any = {};
    if (!values?.name || values?.name?.length === 0)
      errors.name = i18n.t("formErrors.requiredField");
    return errors;
  };

  const validateInvolvedEntityForm = async (
    values: AgroBusinessPhytosanitaryInvolvedEntity
  ) => {
    const errors: any = {};
    if (
      isSiexActive &&
      !values.phytosanitaryRegisterId &&
      (values.phytosanitaryAdvisor || values.phytosanitaryApplicator)
    ) {
      errors.phytosanitaryRegisterId = i18n.t("formErrors.requiredField");
    }
    return errors;
  };

  const handleSubmit = async (
    values: AgroBusinessPhytosanitaryInvolvedEntity
  ) => {
    const entityFormErrors = await entityFormik.validateForm();
    if (Object.keys(entityFormErrors).length > 0) {
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      entityFormik.setTouched(entityFormErrors as FormikTouched<any>);
    } else {
      formik.values.entity = entityFormik.values;
      if (formMode === FormMode.CREATE)
        phytoInvolvedEntityCreateMutation.mutate({});
      else if (formMode === FormMode.EDIT)
        phytoInvolvedEntityEditMutation.mutate({});
    }
  };

  const formik = useFormik<AgroBusinessPhytosanitaryInvolvedEntity>({
    initialValues: new AgroBusinessPhytosanitaryInvolvedEntity(),
    validate: validateInvolvedEntityForm,
    onSubmit: handleSubmit,
  });

  const entityFormik = useFormik<Entity>({
    initialValues: new Entity(),
    validate: validateEntityForm,
    onSubmit: () => {},
  });

  const drawerTitle =
    formMode === FormMode.CREATE
      ? i18n.t("involvedEntities.drawerTitleCreate")
      : i18n.t("involvedEntities.drawerTitleEdit");
  const drawerBtnText =
    formMode === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
    setIsOpenFilesForm(location.pathname.includes("/files"));
  }, [location.pathname]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
    entityFormik.resetForm();
    entityFormik.setErrors({});
    entityFormik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) {
      clearForm();
      if (selectedABPartition)
        formik.setFieldValue("agroBusinessPartition", selectedABPartition);
    }
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const phytoInvolvedEntityCreateMutation =
    useCrud<AgroBusinessPhytosanitaryInvolvedEntity>({
      key: "postPhytosanitaryInvolvedEntity",
      values: formik.values,
      onSuccess: () => {
        setSnackbarMsg({
          severity: "success",
          message: i18n.t("apiResponses.createSuccess"),
        });
        closeDialogAndUnselectedRows();
      },
      onError: manageCrudError,
    });

  const phytoInvolvedEntityEditMutation =
    useCrud<AgroBusinessPhytosanitaryInvolvedEntity>({
      key: "putPhytosanitaryInvolvedEntity",
      values: formik.values,
      onSuccess: () => {
        setSnackbarMsg({
          severity: "success",
          message: i18n.t("apiResponses.updateSuccess"),
        });
        closeDialogAndUnselectedRows();
      },
      onError: manageCrudError,
    });

  const phytoInvolvedEntityDeleteMutation = useCrud({
    key: "deletePhytosanitaryInvolvedEntities",
    values: phytoInvolvedEntities?.filter(
      (pie: AgroBusinessPhytosanitaryInvolvedEntity) =>
        selectedRowIds.includes(pie?.id || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    entityFormik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const entityErrors = await entityFormik.validateForm();
    const involvedEntityErrors = await formik.validateForm();
    const errors = { ...entityErrors, ...involvedEntityErrors };
    if (Object.keys(errors).length > 0) {
      forceExpandCards();
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      entityFormik.setTouched(entityErrors as FormikTouched<any>);
      formik.setTouched(involvedEntityErrors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    entityFormik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      entityFormik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    phytoInvolvedEntityDeleteMutation.mutate(selectedRowIds);
  };

  const handleClickAttachment = () => {
    navigate(`${location.pathname}/files`);
  };

  const handleCloseAttachments = () => {
    navigate(-1);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("involvedEntities.backAlertTitle")}
        contentText={i18n.t("involvedEntities.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("involvedEntities.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t(
          "involvedEntities.confirmDeleteAlertDialogDescription"
        )}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={phytoInvolvedEntityDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("involvedEntities.tableTitle")}
        headerText={i18n.t("involvedEntities.headerText")}
        colums={phytoEntitiesColumns}
        rows={phytoInvolvedEntities}
        selectedRows={selectedRowIds}
        emptyTableIcon={
          <Engineering sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={i18n.t("involvedEntities.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("involvedEntities.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("involvedEntities.emptyTableBtnText")}
        addBtnVariant="contained"
        isLoading={isFetching}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          phytoInvolvedEntityCreateMutation.isLoading ||
          phytoInvolvedEntityEditMutation.isLoading
        }
        iconBtn={
          <AttachmentDocumentsBadge
            nDocuments={formik.values.documents?.length}
          />
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
        onClickIconBtn={handleClickAttachment}
      >
        <PhytoEntitiesForm
          formik={formik}
          entityFormik={entityFormik}
          isOpenFilesForm={isOpenFilesForm}
          forceExpanded={expandCards}
          selectedEditRow={selected}
          isSiexActive={isSiexActive}
          onCloseFilesForm={handleCloseAttachments}
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationInvolvedEntitiesScreen;

interface PhytoInvolvedEntityFormProps {
  formik: FormikProps<AgroBusinessPhytosanitaryInvolvedEntity>;
  entityFormik: FormikProps<Entity>;
  isSiexActive: boolean;
  isOpenFilesForm?: boolean;
  forceExpanded?: boolean;
  selectedEditRow?: AgroBusinessPhytosanitaryInvolvedEntity;
  onCloseFilesForm?: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const PhytoEntitiesForm = (props: PhytoInvolvedEntityFormProps) => {
  const {
    formik,
    entityFormik,
    forceExpanded,
    selectedEditRow,
    isOpenFilesForm = false,
    onCloseFilesForm,
    onError,
  } = props;

  const { selectedABAccount, selectedABPartition, agroBusinessPartitions } =
    useSession();
  const queryClient = useQueryClient();

  const { isFetching: isUpdating } =
    useFetch<AgroBusinessPhytosanitaryInvolvedEntity>({
      queryKey: ["phytoInvolvedEntity", selectedEditRow?.id],
      selected: selectedEditRow,
      onSuccess: (data: AgroBusinessPhytosanitaryInvolvedEntity) => {
        formik.setValues(data);
        if (data?.entity) entityFormik.setValues(data.entity);
        // Update in array without refetch
        queryClient.setQueryData<AgroBusinessPhytosanitaryInvolvedEntity[]>(
          [
            "phytoInvolvedEntities",
            selectedABPartition?.id,
            selectedABAccount?.id,
          ],
          (oldData) => updateItemOfList(oldData, data, "id")
        );
      },
      enabled: !!selectedEditRow?.id,
      onError,
    });

  const handleChangeFiles = (files: Document[]) => {
    formik.setFieldValue("documents", files);
  };

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      <EntityForm formik={entityFormik} forceExpanded={forceExpanded} />
      <PhytosanitaryInvolvedEntityForm {...props} />
      <FertilizerInvolvedEntityForm {...props} />
      <FilesForm
        open={isOpenFilesForm}
        files={formik.values.documents || []}
        drawerSubtitle={i18n.t("involvedEntities.filesFormSubtitle")}
        onChangeFiles={handleChangeFiles}
        onClose={onCloseFilesForm}
        onError={onError}
      />
      {agroBusinessPartitions?.length > 0 &&
        formik.status === FormMode.EDIT && (
          <PartitionForm
            name="agroBusinessPartition"
            formik={formik}
            agroBusinessPartitions={agroBusinessPartitions}
          />
        )}
    </>
  );
};

interface EntityFormProps {
  formik: FormikProps<Entity>;
  forceExpanded?: boolean;
  entities?: Entity[];
  isLoading?: boolean;
  onError?: (snackBarError: SnackbarInterface) => void;
}

const EntityForm = (props: EntityFormProps) => {
  const { formik, forceExpanded, onError } = props;

  const { selectedABAccount } = useSession();

  const { data: entities, isFetching } = useFetch<Entity[]>({
    queryKey: ["entities", selectedABAccount?.id],
    enabled: !!selectedABAccount,
    onError,
  });

  return (
    <BrioCard
      title={
        formik.values?.entityType?.code === EntityType.PHYSICAL
          ? i18n.t("involvedEntities.entityForm.titlePhysical")
          : i18n.t("involvedEntities.entityForm.titleLegal")
      }
      defaultExpanded
      forceExpanded={forceExpanded}
      required
    >
      <FormGroup className="form-group">
        <EntityTypeForm formik={formik} />
        {formik.values?.entityType?.code === EntityType.PHYSICAL && (
          <EntityPersonalDataForm
            formik={formik}
            isLoading={isFetching}
            entities={entities}
          />
        )}
        {formik.values?.entityType?.code === EntityType.LEGAL && (
          <EntityLegalDataForm
            formik={formik}
            isLoading={isFetching}
            entities={entities}
          />
        )}
      </FormGroup>
    </BrioCard>
  );
};

const EntityTypeForm = (props: EntityFormProps) => {
  const { formik } = props;

  const handleChangeEntityType = (entityType: EntityTypeInterface) => {
    formik.setFieldValue("entityType", entityType);
    formik.setFieldValue("firstSurname", "");
    formik.setFieldValue("secondSurname", "");
  };

  return (
    <FormControl variant="outlined" className="form-control">
      <FormikSelect
        formik={formik}
        name="entityType"
        label={i18n.t("involvedEntities.entityForm.entityType")}
        options={ENTITY_TYPES}
        optionLabelFieldName="name"
        required
        onChange={handleChangeEntityType}
      />
    </FormControl>
  );
};

const EntityPersonalDataForm = (props: EntityFormProps) => {
  const { formik, entities, isLoading = false } = props;

  const handleChangeEntity = (entity: Entity) => {
    if (entity) {
      formik.setValues(entity);
    } else formik.setValues(new Entity());
  };

  const handleChangeName = (event: any, value: any) => {
    formik.setFieldValue("name", value);
  };

  return (
    <>
      <FormControl variant="outlined" className="form-control">
        <FormikListItemSwitch
          formik={formik}
          name="foreignId"
          primaryText={i18n.t("involvedEntities.personalDataForm.foreignId")}
          secondaryText={i18n.t(
            "involvedEntities.personalDataForm.foreignIdSubtitle"
          )}
        />
      </FormControl>
      <FormControl variant="outlined" className="form-control">
        <FormikAutocomplete
          formik={formik}
          name="name"
          label={i18n.t("involvedEntities.personalDataForm.name")}
          required
          freeSolo
          clearOnBlur={false}
          options={entities || []}
          optionLabelFieldName="nameAndSurname"
          altOptionLabel={formik.values?.name || ""}
          loading={isLoading}
          onChange={handleChangeEntity}
          onInputChange={handleChangeName}
        />
      </FormControl>
      <FormControl variant="outlined" className="form-control-row">
        <FormikTextField
          formik={formik}
          className="form-input-row"
          name="firstSurname"
          label={i18n.t("involvedEntities.personalDataForm.firstSurname")}
        />
        <FormikTextField
          formik={formik}
          className="form-input-row"
          name="secondSurname"
          label={i18n.t("involvedEntities.personalDataForm.secondSurname")}
        />
      </FormControl>
      <FormControl variant="outlined" className="form-control">
        <FormikTextField
          formik={formik}
          name="entityId"
          label={i18n.t("involvedEntities.personalDataForm.entityId")}
        />
      </FormControl>
      <FormControl variant="outlined" className="form-control-row">
        <DatePicker
          className="form-input"
          format="DD/MM/YYYY"
          openTo="year"
          label={i18n.t("involvedEntities.personalDataForm.birthDate")}
          value={
            formik.values?.birthDate
              ? moment(formik.values.birthDate, "YYYY-MM-DD")
              : moment()
          }
          onChange={(date) =>
            formik.setFieldValue("birthDate", date?.format("YYYY-MM-DD"))
          }
        />
        <FormikSelect
          formik={formik}
          type="fullWidth"
          style={{ minWidth: "150px", marginLeft: "8px" }}
          name="sexType"
          label={i18n.t("involvedEntities.personalDataForm.sexType")}
          options={ENTITY_SEX_TYPES}
          optionLabelFieldName="name"
        />
      </FormControl>
    </>
  );
};

const EntityLegalDataForm = (props: EntityFormProps) => {
  const { formik, entities, isLoading = false } = props;

  const handleChangeEntity = (entity: Entity) => {
    if (entity) {
      formik.setValues(entity);
    } else formik.setValues(new Entity());
  };

  const handleChangeName = (event: any, value: any) => {
    formik.setFieldValue("name", value);
  };

  return (
    <>
      <FormControl variant="outlined" className="form-control">
        <FormikAutocomplete
          formik={formik}
          name="name"
          label={i18n.t("involvedEntities.legalDataForm.name")}
          required
          freeSolo
          clearOnBlur={false}
          options={entities || []}
          optionLabelFieldName="name"
          altOptionLabel={formik.values?.name || ""}
          loading={isLoading}
          onChange={handleChangeEntity}
          onInputChange={handleChangeName}
        />
      </FormControl>
      <FormControl variant="outlined" className="form-control">
        <FormikTextField
          formik={formik}
          name="entityId"
          label={i18n.t("involvedEntities.legalDataForm.entityId")}
        />
      </FormControl>
    </>
  );
};

const PhytosanitaryInvolvedEntityForm = (
  props: PhytoInvolvedEntityFormProps
) => {
  const { formik, isSiexActive, onError } = props;

  const { selectedABAccount } = useSession();
  const navigate = useNavigate();

  const { data: persons, isFetching } = useFetch<Entity[]>({
    queryKey: ["writerUserList", selectedABAccount?.id],
    enabled: !!selectedABAccount && formik.values.phytosanitaryAdvisor,
    onError,
  });

  const [isOpenRedirectAlertDialog, setIsOpenRedirectAlertDialog] =
    useState<boolean>(false);

  const openRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(true);
  const closeRedirectAlertDialog = () => setIsOpenRedirectAlertDialog(false);

  const handleClickNewOption = () => openRedirectAlertDialog();

  const handleConfirmRedirectAlertDialog = () => {
    closeRedirectAlertDialog();
    navigate(`/${PROTECTED_ROUTES.EXPLOITATIONS_PERMISSIONS}`);
  };

  return (
    <BrioCard
      title={i18n.t("involvedEntities.phytoInvolvedEntityForm.title")}
      defaultExpanded
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikListItemSwitch
            formik={formik}
            name="phytosanitaryAdvisor"
            primaryText={i18n.t(
              "involvedEntities.phytoInvolvedEntityForm.phytosanitaryAdvisor"
            )}
            secondaryText={i18n.t(
              "involvedEntities.phytoInvolvedEntityForm.phytosanitaryAdvisorSubtitle"
            )}
          />
        </FormControl>
        <Collapse
          className="collapse-container"
          in={formik.values.phytosanitaryAdvisor}
        >
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikAutocomplete
                formik={formik}
                name="advisorUser"
                label={i18n.t(
                  "involvedEntities.phytoInvolvedEntityForm.phytosanitaryAdvisorUserLabel"
                )}
                placeholder={i18n.t(
                  "involvedEntities.phytoInvolvedEntityForm.phytosanitaryAdvisorUserPlaceholder"
                )}
                helperText={i18n.t(
                  "involvedEntities.phytoInvolvedEntityForm.phytosanitaryAdvisorUserHelperText"
                )}
                options={persons || []}
                optionLabelFieldName="email"
                startIcon={Search}
                loading={isFetching}
                addNewOption
                onClickNewOption={handleClickNewOption}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikSelect
                formik={formik}
                name="trainingLevelType"
                label={i18n.t(
                  "involvedEntities.phytoInvolvedEntityForm.trainingLevelType"
                )}
                optionLabelFieldName="name"
                options={PHYTOSANITARY_TRAINING_LEVELS}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                className="form-input"
                name="phytosanitaryRegisterId"
                label={i18n.t(
                  "involvedEntities.phytoInvolvedEntityForm.phytosanitaryRegisterId"
                )}
                helperText={i18n.t(
                  "involvedEntities.phytoInvolvedEntityForm.phytosanitaryRegisterIdHelperText"
                )}
                required={isSiexActive}
              />
            </FormControl>
          </div>
        </Collapse>

        <FormControl
          variant="outlined"
          className="form-control"
          sx={{ marginBottom: "0px !important" }}
        >
          <FormikListItemSwitch
            formik={formik}
            name="phytosanitaryApplicator"
            primaryText={i18n.t(
              "involvedEntities.phytoInvolvedEntityForm.phytosanitaryApplicator"
            )}
            secondaryText={i18n.t(
              "involvedEntities.phytoInvolvedEntityForm.phytosanitaryApplicatorSubtitle"
            )}
          />
        </FormControl>
        <Collapse
          className="collapse-container"
          in={formik.values.phytosanitaryApplicator}
        >
          <div className="grow-container">
            <FormControl variant="outlined" className="form-control">
              <FormikSelect
                formik={formik}
                name="trainingLevelType"
                label={i18n.t(
                  "involvedEntities.phytoInvolvedEntityForm.trainingLevelType"
                )}
                optionLabelFieldName="name"
                options={PHYTOSANITARY_TRAINING_LEVELS}
              />
            </FormControl>
            <FormControl variant="outlined" className="form-control">
              <FormikTextField
                formik={formik}
                className="form-input"
                name="phytosanitaryRegisterId"
                label={i18n.t(
                  "involvedEntities.phytoInvolvedEntityForm.phytosanitaryRegisterId"
                )}
                helperText={i18n.t(
                  "involvedEntities.phytoInvolvedEntityForm.phytosanitaryRegisterIdHelperText"
                )}
                required={isSiexActive}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
      <FormAlertDialog
        id="redirectAlertDialog"
        title={i18n.t(
          "involvedEntities.phytoInvolvedEntityForm.redirectUsersAlertDialogTitle"
        )}
        contentText={i18n.t(
          "involvedEntities.phytoInvolvedEntityForm.redirectUsersAlertDialogContentText"
        )}
        open={isOpenRedirectAlertDialog}
        onCancel={closeRedirectAlertDialog}
        onConfirm={handleConfirmRedirectAlertDialog}
      />
    </BrioCard>
  );
};

const FertilizerInvolvedEntityForm = (props: PhytoInvolvedEntityFormProps) => {
  const { formik } = props;

  return (
    <BrioCard
      title={i18n.t("involvedEntities.fertiInvolvedEntityForm.title")}
      defaultExpanded
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikListItemSwitch
            formik={formik}
            name="fertilizationAdvisor"
            primaryText={i18n.t(
              "involvedEntities.fertiInvolvedEntityForm.fertilizationAdvisor"
            )}
            secondaryText={i18n.t(
              "involvedEntities.fertiInvolvedEntityForm.fertilizationAdvisorSubtitle"
            )}
          />
        </FormControl>
        <FormControl
          variant="outlined"
          className="form-control"
          sx={{ marginBottom: "0px !important" }}
        >
          <FormikListItemSwitch
            formik={formik}
            name="fertilizationApplicator"
            primaryText={i18n.t(
              "involvedEntities.fertiInvolvedEntityForm.fertilizationApplicator"
            )}
            secondaryText={i18n.t(
              "involvedEntities.fertiInvolvedEntityForm.fertilizationApplicatorSubtitle"
            )}
          />
        </FormControl>
        <Collapse
          className="collapse-container"
          in={
            formik.values.fertilizationAdvisor ||
            formik.values.fertilizationApplicator
          }
        >
          <div className="grow-container">
            <FormControl
              variant="outlined"
              className="form-control"
              sx={{ marginTop: "20px" }}
            >
              <FormikTextField
                formik={formik}
                className="form-input"
                name="fertilizationRegisterId"
                label={i18n.t(
                  "involvedEntities.fertiInvolvedEntityForm.fertilizationRegisterId"
                )}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};
